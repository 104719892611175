class UIStateService {
  constructor(localStorageService) {
    'ngInject';

    this.storage = localStorageService;
    this.prefix = 'ui-state.';
  }

  set(key, val) {
    return this.storage.set(this.prefix + key, val);
  }

  get(key) {
    return this.storage.get(this.prefix + key);
  }
}

angular
  .module('uvsApp')
  .service('uiStateService', UIStateService);

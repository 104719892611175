'use strict';

angular.module('uvsApp').factory('User', function($resource, API_URL) {
  var User = $resource(
    API_URL + '/users/:id/:controller',
    {
      id: '@_id'
    },
    {
      update: {
        method: 'PUT'
      },
      changePassword: {
        method: 'PUT',
        params: {
          controller: 'password'
        }
      },
      get: {
        method: 'GET',
        params: {
          id: 'me'
        }
      },
      getWorkers: {
        method: 'GET',
        params: {
          id: 'workers'
        },
        isArray: true
      },
      getManagers: {
        method: 'GET',
        params: {
          id: 'managers'
        },
        isArray: true
      }
    }
  );

  return User;
});

'use strict';

angular.module('uvsApp').config(function($stateProvider) {
  $stateProvider
    .state('root.permissions', {
      abstract: true,
      url: '/permissions',
      views: {
        content: {
          templateUrl: 'layouts/with-left-sidebar.html'
        },
        'sidebar@root.permissions': {
          templateUrl: 'components/sidebar/sidebar-settings.html'
        }
      }
    })
    .state('root.permissions.list', {
      url: '',
      templateUrl: 'app/permissions/permissions.html',
      controller: 'PermissionsCtrl',
      controllerAs: 'ctrl',
      title: 'Leidimai',
      resolve: {
        roles: function(Role) {
          return Role.query().$promise;
        },
        permissions: function(Permission) {
          return Permission.query().$promise;
        }
      }
    })
    .state('root.permissions.new', {
      url: '/new',
      templateUrl: 'app/permissions/partials/form.html',
      controller: 'PermissionsFormCtrl',
      controllerAs: 'ctrl'
    });
});

'use strict';

angular.module('uvsApp')

.controller('TemplateFormCtrl', function ($scope, $rootScope, template, $state, toastr, replaceableFields) {

  $scope.isEditMode = false;
  $scope.template = template;

  if (template.hasOwnProperty('_id')) {
    $scope.isEditMode = true;
  }

  $scope.dynamicValues = replaceableFields
    .map(function(object) {
      return {
        name: '{{' + object.name + '}}',
        description: object.description
      };
    });


  $scope.submit = function (valid, options) {
    $scope.$broadcast('show-errors-check-validity');

    options = options || {};

    var fnSuccess = function () {
          toastr.success(options.content || 'Šablonas sėkmingai užsaugotas', options.title || 'Užsaugota!');
          if(!options.state) {
            options.state = 'root.templates.list';
            if ($scope.isEditMode && $rootScope.previousState && $rootScope.previousState.name) {
                options.state = $rootScope.previousState.name;
            }
          }
          $state.go(options.state);
        },
        fnFail = function () {
          toastr.error('Šablonas neužsaugotas. Bandykite dar kartą', 'Klaida');
        };

    if (!valid) {
      return;
    }

    if ($scope.isEditMode) {
      $scope.template.$update(fnSuccess, fnFail);
    } else {
      $scope.template.$save(fnSuccess, fnFail);
    }
  };
});

'use strict';

angular
  .module('app.deals', [
    'ngMessages',
    'ui.router',
    'ui.bootstrap',
    'components.pipelines',
    'components.pipeline',
    'components.activity',
    'components.companies',
    'app.estimates',
    'app.engagements',
    'app.operations',
    'components.company-selector',
    'components.collapsible-panel',
    'components.sidebar.sales',
    'components.date-time-picker'
  ])
  .config(function($stateProvider) {
    $stateProvider.state('root.deals', {
      abstract: true,
      url: '/deals',
      views: {
        content: {
          templateUrl: 'layouts/with-left-sidebar.html'
        },
        'sidebar@root.deals': 'salesSidebar'
      },
      resolve: {
        pipelines: pipelinesStore => pipelinesStore.fetchPipelines()
      }
    });
  })
  .run(operationsStore => operationsStore.getOperations());

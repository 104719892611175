'use strict';

const deals = {
  templateUrl: 'app/deals/deals/deals.html',
  controller: 'DealsController',
  bindings: {
    deals: '<',
    stage: '<',
    archived: '<',
    customers: '<'
  }
};

angular
  .module('app.deals')
  .component('deals', deals)
  .config($stateProvider => {
    $stateProvider.state('root.deals.list', {
      url: '',
      component: 'deals',
      params: {
        st: null,
        archived: false
      },
      title: 'Sandoriai',
      resolve: {
        stage: $transition$ => $transition$.params().st,
        archived: $transition$ => $transition$.params().archived
      }
    });
  });

'use strict';

class AuditService {
  constructor(auditResource) {
    'ngInject';

    this.auditResource = auditResource;
  }

  getAuditlogs(query) {
    return this.auditResource.query(query).$promise;
  }
}

angular
  .module('components.audit')
  .service('auditService', AuditService);

'use strict';

class LoginCtrl {
  constructor($scope, $location, $window, _, Auth, usersStore) {
    'ngInject';

    this.$scope = $scope;
    this.$location = $location;
    this.$window = $window;
    this._ = _;
    this.Auth = Auth;
    this.usersStore = usersStore;
  }

  $onInit() {
    this.user = {};
    this.errors = {};
  }

  $onDestroy() {
    this.dispose();
  }

  login(form) {
    this.submitted = true;

    if (form.$valid) {
      this.Auth.login({
        email: this.user.email,
        password: this.user.password
      })
        .then(() => {
          this.dispose = mobx.autorun(() => {
            const defaultHomePage = this._.get(
              this.usersStore.currentUser,
              'settings.defaultHomePage'
            );
            if (defaultHomePage) {
              this.$location.path(defaultHomePage);
            }
          });
        })
        .catch(err => {
          this.errors.other = err.message;
        });
    }
  }

  loginOauth(provider) {
    this.$window.location.href = '/auth/' + provider;
  }
}

angular.module('uvsApp').controller('LoginCtrl', LoginCtrl);

class TimeFieldController {
  constructor(_, $scope) {
    'ngInject';

    this._ = _;
    this.$scope = $scope;
  }

  $onChanges(changes) {
    if (changes.hours && changes.hours.isFirstChange()) {
      this.time = this._toTime(changes.hours.currentValue || 1);
    }
  }

  onTimeChange() {
    this.$scope.$broadcast('show-errors-check-validity');

    this.onChange({
      $event: {
        hours: this._toHours(this.time.hours, this.time.minutes)
      }
    });
  }

  _toHours(hours = 0, minutes = 0) {
    return hours + this._.round(minutes / 60, 2);
  }

  _toTime(timeHours = 0) {
    const hours = this._.floor(timeHours);

    return {
      hours,
      minutes: this._.floor((timeHours - hours) * 60)
    };
  }
}

angular.module('uvsApp').controller('TimeFieldController', TimeFieldController);

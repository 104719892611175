'use strict';

const profile = {
  templateUrl: 'app/profile/profile/profile.html',
  controller: 'ProfileController'
};

angular
  .module('components.profile')
  .component('profile', profile)
  .config($stateProvider => {
    $stateProvider.state('root.profile.view', {
      url: '',
      component: 'profile'
    });
  });

const notificationComponent = {
  templateUrl: 'components/notifications/notification/notification.html',
  controller: 'NotificationController',
  bindings: {
    notify: '<'
  },
  require: {
    listCtrl: '^notificationsList'
  }
};

angular.module('uvsApp').component('notification', notificationComponent);

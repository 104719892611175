const notificationSettingsComponent = {
    bindings: {
        settings: '<',
        onChange: '&'
    },
    templateUrl: 'app/profile/notificationSettings/notification-settings.html',
    controller: 'NotificationSettingsController'
};

angular
    .module('components.profile')
    .component('notificationSettings', notificationSettingsComponent);

'use strict';

class OrdersService {
  constructor(_, Modal) {
    'ngInject';

    this._ = _;
    this.Modal = Modal;
  }

  getAggregateDepartments(depInfo, departments) {
    return this._(depInfo)
      .keys()
      .map(did => {
        const department = this._.find(departments, { _id: did });
        return this._.merge(depInfo[did], department || {}, {
          _id: did,
          // Check if at least one task is not in Todo
          hasStarted: this._.some(depInfo[did].tasks, t => t.status !== 0)
        });
      })
      .sortBy('weight')
      .value();
  }
}

angular.module('uvsApp').service('ordersService', OrdersService);

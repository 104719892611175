class TenantsStore {
  constructor($log, _, tenantsService) {
    'ngInject';

    this.$log = $log;
    this._ = _;
    this.tenantsService = tenantsService;

    this.tenant = null;
    this.isFetched = false;
    this.isFetching = false;
  }

  getTenant() {
    if (!(this.isFetched || this.isFetching)) {
      this.fetchTenant();
    }

    return this.tenants[0];
  }

  fetchTenant() {
    this.isFetching = true;

    return this.tenantsService
      .getTenant()
      .then(tenant => {
        this.setTenant(tenant);
        this.isFetched = true;
      })
      .catch(error => this.$log.error(error))
      .finally(() => (this.isFetching = false));
  }

  setTenant(data) {
    this.tenant = data;
  }
}

mobx.decorate(TenantsStore, {
  tenant: mobx.observable,

  setTenant: mobx.action
});

angular.module('app.tenants').service('tenantsStore', TenantsStore);

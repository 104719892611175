'use strict';

const eventDetailsComponent = {
  templateUrl: 'components/event/event-details/event-details.html',
  bindings: {
    event: '<'
  }
};

angular
  .module('components.event')
  .component('eventDetails', eventDetailsComponent);

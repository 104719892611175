class EventController {
  constructor(engagementsService, toastr) {
    'ngInject';

    this.toastr = toastr;
    this.engagementsService = engagementsService;
  }

  handleAddComment({ text }) {
    this.engagementsService
      .createComment(this.event.sourceId, { text })
      .then(savedComment => {
        this.event.source.comments = [...this.event.source.comments, savedComment];
      })
      .catch(() => {
        this.toastr.error('Nepavyko įrašyti komentaro.', 'Klaida!');
      });
  }

  handleRemoveComment({ itemId }) {
    this.engagementsService
      .removeComment(this.event.sourceId, itemId)
      .then(() => {
        this.event.source.comments = this._removeComment(this.event.source.comments, itemId);
      })
      .catch(() => {
        this.toastr.error('Nepavyko ištrinti komentaro.', 'Klaida!');
      });
  }

  _removeComment(comments, commentId) {
    return comments.filter(c => c._id !== commentId);
  }
}

angular.module('components.event').controller('EventController', EventController);

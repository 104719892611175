class PipelineSettingsController {
  constructor($state, $log, pipelinesService, toastr) {
    'ngInject';

    this.$log = $log;
    this.$state = $state;
    this.toastr = toastr;
    this.pipelinesService = pipelinesService;
  }

  onSave(event) {
    this.pipelinesService
      .updatePipeline(event.pipeline)
      .then((pipeline) => {
        this.toastr.success('Sėkmingai užsaugota.');
      })
      .catch((err) => {
        this.toastr.error('Įvyko klaida. Bandykite dar kartą.', 'Klaida');
        this.$log.error(err);
      });
  }

  onCancel() {
    this.$state.go('root.settings.deals');
  }
}

angular
  .module('components.settings')
  .controller('PipelineSettingsController', PipelineSettingsController);

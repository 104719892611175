class OperationNewController {
  constructor($state, toastr, operationsStore) {
    'ngInject';

    this.$state = $state;
    this.toastr = toastr;
    this.store = operationsStore;
  }

  $onInit() {
    this.operation = {
      name: ''
    };
  }

  createNewOperation({operation}) {
    return this.store
      .createOperation(operation)
      .then((deal) => {
        this.toastr.success('Operacija sukurta sėkmingai.', 'Sėkmė');
        this.$state.go('root.settings.operations');
      })
      .catch(() => {
        this.toastr.error(
          'Nepavyko užsaugoti operacijos. Bandykite dar  kartą.',
          'Klaida'
        );
      });
  }
}

angular
  .module('app.operations')
  .controller('OperationNewController', OperationNewController);

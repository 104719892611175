const commentsComponent = {
  templateUrl: 'components/comments/comments.html',
  controller: 'CommentsController',
  bindings: {
    items: '<',
    onAddComment: '&',
    onRemoveComment: '&'
  }
};

angular.module('components.comments').component('comments', commentsComponent);

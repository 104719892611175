const operationsSettingsComponent = {
  templateUrl: 'app/settings/operations/operations.html',
  controller: 'OperationsSettingsController',
  bindings: {
    operation: '<'
  }
};

angular
  .module('components.settings')
  .component('operationsSettings', operationsSettingsComponent)
  .config($stateProvider => {
    $stateProvider.state('root.settings.operations', {
      url: '/operations',
      component: 'operationsSettings',
      title: 'Operacijos'
      // resolve: {
      //   operation: ($transition$, operationsService) => {
      //     return operationsService.getOperations();
      //   }
      // }
    });
  });

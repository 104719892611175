angular
  .module('uvsApp')
  .filter('userName', (usersStore) => {
    return (userId) => {
      let name = '';

      if (!userId) {
        return name;
      }

      const user = usersStore.getById(userId);

      return user ? user.name : name;
    };
  });

'use strict';

angular.module('uvsApp').config(function($stateProvider) {
  $stateProvider.state('root.employment', {
    url: '/employment',
    title: 'Užimtumas',
    views: {
      content: {
        controller: 'EmploymentCtrl as ctrl',
        templateUrl: 'app/employment/employment.html'
      }
    },
    resolve: {
      tasks: function(Employment) {
        return Employment.query().$promise;
      },
      employmentOrder: function(tenantsStore) {
        var dispose;
        return new Promise(function(resolve, reject) {
          dispose = mobx.autorun(function() {
            var tenant = tenantsStore.tenant;
            resolve(tenant.settings.employmentOrder);
          });
        }).finally(function() {
          dispose();
        });
      },
      users: function(usersStore) {
        var dispose;
        return new Promise(function(resolve, reject) {
          dispose = mobx.autorun(function() {
            var users = usersStore.users;
            resolve(users);
          });
        }).finally(function() {
          dispose();
        });
      }
    }
  });
});

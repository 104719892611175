angular.module('uvsApp').directive('uvsSortableHead', () => ({
  templateUrl: 'app/directives/uvsSortableTable/uvsSortableHead.html',
  transclude: true,
  scope: {
    field: '@'
  },
  require: '^uvsSortableTable',
  link: (scope, element, attrs, ctrl) => {
    scope.ctrl = ctrl;

    element.bind('click', () => {
      ctrl.changeType(scope.field);
      scope.$apply();
    });
  }
}));

'use strict';

angular
  .module('uvsApp')
  .filter('orderListFilter', function(_, Auth) {
    return function(items, fltrs, sts) {
      let sq = {};
      const fq = {};
      const user = Auth.getCurrentUser();

      switch (sts) {
        case '1': // Active
          sq = {
            active: true,
            completed: false,
            closed: false
          };
          break;
        case '2': // Inactive
          sq = {
            active: false,
            completed: false,
            closed: false
          };
          break;
        case '3': // Completed
          sq = {
            active: true,
            completed: true,
            closed: false
          };
          break;
      }

      _.forEach(fltrs, fltr => {
        switch (fltr) {
          case '1': // Only my
            fq.user = user._id;
            break;
        }
      });

      _.sortBy(items, 'weight');

      items = _.filter(items, sq);

      return _.filter(items, item => {
        var match = true;

        if (match && _.has(fq, 'user')) {
          match = item.owner._id === fq.user;
        }

        return match;
      });
    };
  })

  .filter('selectedDep', function(_) {
    return (items, dep) => {
      if (!dep) {
        return items;
      }

      return _.filter(items, item => {
        return _.includes(item.departments, dep);
      });
    };
  })

  .controller('OrdersCtrl', function(
    $scope,
    $stateParams,
    orders,
    ordersRepository,
    Order,
    departments,
    $location,
    Modal,
    localStorageService,
    _,
    TaskRepository,
    users
  ) {
    $scope.dep = $stateParams.dep || 'all';

    const showAll = $scope.dep === 'all';
    const department = _.find(departments, { _id: $scope.dep });

    $scope.showAll = showAll;
    $scope.orders = orders;
    $scope.deps = $scope.userDeps = departments;
    $scope.isOrderDirty = false;

    const { clients, owners } = users;

    $scope.clients = clients;
    $scope.owners = owners;

    $scope.searchQuery = {
      ready: localStorageService.get('showReady', false)
    };
    $scope.isActive = $stateParams.type === 'active' || $stateParams.type === undefined;

    $scope.department = department ? department.name : 'visi';

    $scope.remove = Modal.confirm.delete(order => {
      Order.delete({ id: order._id }, () => {
        _.remove($scope.orders, { _id: order._id });
      });
    });

    $scope.edit = function() {
      $location.path('orders' + this.order._id + '/edit');
    };

    $scope.$on('$destroy', function() {
      // socket.unsyncUpdates('order');
    });

    $scope.sortableOptions = {
      orderChanged: function() {
        $scope.isOrderDirty = true;
        // $filter('orderListFilter')();
        // $filter('selectedDep')();
        // console.log($scope.orders);
      }
    };

    $scope.saveOrder = function() {
      Order.order(
        { dep: $scope.dep },
        _.map($scope.orders, o => o._id)
      );
      $scope.isOrderDirty = false;
    };

    $scope.onCreateTask = function(order) {
      TaskRepository.createTask({ orderId: order._id }).then(function(task) {
        order.tasks.push(task);
        // TODO: update normaliai
      });
    };

    $scope.handleSearchChange = () => {
      const { term } = $scope.searchQuery;

      if (!term) {
        getOrders();
        return;
      }

      if (term.length < 3) return;

      $scope.searchQuery.page = null;
      getOrders();
    };

    $scope.handleFilterChange = () => {
      $scope.searchQuery.page = null;
      getOrders();
    };

    $scope.pageSize = 200;
    $scope.totalItems = ordersRepository.getTotalItems();
    $scope.currentPage = 1;
    $scope.isLoading = false;

    $scope.handlePageChange = function() {
      $scope.searchQuery.page = $scope.currentPage;

      getOrders();
    };

    $scope.handleOnlyReadyChange = function() {
      $scope.searchQuery.ready = !$scope.searchQuery.ready;
      localStorageService.set('showReady', $scope.searchQuery.ready);
      getOrders();
    };

    $scope.handleSortChange = function({ field, direction }) {
      $scope.searchQuery.sort = field;
      $scope.searchQuery.direction = direction;
      getOrders();
    };

    function getOrders(query = {}) {
      const defaultQuery = {
        dep: $stateParams.dep
      };

      if (!$stateParams.dep) {
        defaultQuery.type = 'active';
      }

      $scope.isLoading = true;

      ordersRepository
        .getOrders(Object.assign({}, defaultQuery, $scope.searchQuery, query))
        .then(orders => {
          $scope.orders = orders;
          $scope.isLoading = false;
          $scope.totalItems = ordersRepository.getTotalItems();
        });
    }
  })

  .controller('OrdersCompletedCtrl', function($scope, _, orders, users, ordersRepository) {
    $scope.orders = orders;
    $scope.searchQuery = {};

    const { clients, owners } = users;

    $scope.clients = clients;
    $scope.owners = owners;

    $scope.handleSearchChange = () => {
      const { term } = $scope.searchQuery;

      if (!term) {
        getOrders();
        return;
      }

      if (term.length < 3) return;

      $scope.searchQuery.page = null;
      getOrders();
    };

    $scope.handleFilterChange = () => {
      $scope.searchQuery.page = null;
      getOrders();
    };

    $scope.pageSize = 100;
    $scope.totalItems = ordersRepository.getTotalItems();
    $scope.currentPage = 1;
    $scope.isLoading = false;

    $scope.handlePageChange = function() {
      $scope.searchQuery.page = $scope.currentPage;

      getOrders();
    };

    $scope.handleSortChange = function({ field, direction }) {
      $scope.searchQuery.sort = field;
      $scope.searchQuery.direction = direction;
      getOrders();
    };

    function getOrders(query = {}) {
      const defaultQuery = {
        type: 'closed'
      };

      $scope.isLoading = true;

      ordersRepository
        .getOrders(Object.assign({}, defaultQuery, $scope.searchQuery, query))
        .then(orders => {
          $scope.orders = orders;
          $scope.isLoading = false;
          $scope.totalItems = ordersRepository.getTotalItems();
        });
    }
  })

  .controller('OrdersSearchCtrl', function(
    $scope,
    orders,
    departments,
    $stateParams,
    Order,
    _,
    Modal,
    TaskRepository
  ) {
    $scope.orders = orders;
    $scope.deps = departments;
    $scope.q = $stateParams.q;

    $scope.loadMore = function() {
      const lastOrder = _.last($scope.orders);

      Order.search({ q: $scope.q, last: lastOrder.createdAt }, response => {
        $scope.orders = [...$scope.orders, ...response];
      });
    };

    $scope.remove = Modal.confirm.delete(function(order) {
      Order.delete({ id: order._id }, function() {
        _.remove($scope.orders, { _id: order._id });
      });
    });

    $scope.onCreateTask = function(order) {
      TaskRepository.createTask({ orderId: order._id }).then(function(task) {
        order.tasks.push(task);
        // TODO: update normaliai
      });
    };
  })

  .controller('OrderViewCtrl', function(
    $scope,
    $state,
    _,
    order,
    invoices,
    departments,
    Order,
    User,
    Auth,
    $uibModal,
    $rootScope,
    $window,
    printer,
    dependants,
    toastr,
    TaskRepository,
    ordersService,
    Modal,
    API_URL,
    titleService
  ) {
    var tenant = Auth.getCurrentUser().tenant;

    $scope.isInvoiceGenerating = false;
    $scope.order = order;
    $scope.workers = User.getWorkers();
    $scope.user = Auth.getCurrentUser();
    $scope.tenant = tenant;

    titleService.setTitle(`${order.number} ${order.name}`);

    $scope.hasOldJobs = !_.every(order.jobs, 'dep');

    $scope.invoices = _.filter(invoices, function(invoice) {
      return invoice.hasOwnProperty('version') && invoice.version != 2;
    });
    $scope.order.invoice = _.find(invoices, { version: 2 });

    $scope.dependantTasks = _.map(dependants, dep => {
      return {
        dep: dep._id,
        name: dep.name,
        status: 0,
        selected: _.includes($scope.order.departments, dep._id)
      };
    });

    $scope.print = function(dep) {
      let document;

      switch (dep) {
        case 'inspection-form':
          document = 'adsystems-inspection-form';
          break;
        case 'quality-check':
          document = 'adsystems-quality-check';
          break;
        default:
          document = `${tenant}-order`;
          break;
      }

      printer.print(`app/prints/${document}.html`, {
        order: $scope.order,
        dep: dep,
        deps: $scope.aggregatedDepartments
      });
    };

    $scope.reload = function() {
      Order.reload({ id: $scope.order._id })
        .$promise.then(function(order) {
          $scope.order = order;
          $window.location.reload();
        })
        .catch(function() {
          toastr.error('Atnaujinti nepavyko');
        });
    };

    $scope.remove = Modal.confirm.delete(order => {
      Order.delete({ id: order._id }, () => {
        $state.go('root.orders.list');
      });
    });

    $scope.toggleTask = function toggleTask(task) {
      var oTask = _.findIndex($scope.order.tasks, { dep: task.dep });

      if (oTask === -1) {
        $scope.order.tasks.push(task);
        $scope.order.departments.push(task.dep);
      } else {
        $scope.order.tasks.splice(oTask, 1);
        removeDep(task.dep);
      }

      $scope.order.$update();
    };

    function removeDep(depId) {
      var index = $scope.order.departments.indexOf(depId);

      if (index !== -1) {
        $scope.order.departments.splice(index, 1);
      }
    }

    function setCanBeClosed() {
      $scope.canBeClosed =
        $scope.order.completed === true && _.every($scope.order.tasks, { status: 2 });
    }

    function setAgregatedDepartments() {
      $scope.aggregatedDepartments = ordersService.getAggregateDepartments(
        order.depInfo,
        departments
      );
    }

    setAgregatedDepartments();
    setCanBeClosed();

    $scope.completeOrder = function(toComplete) {
      Order.toggleState({ id: $scope.order._id, cp: toComplete }, resp => {
        _.merge($scope.order, resp);
        setCanBeClosed();
      });
    };

    $scope.closeOrder = function(toClose) {
      Order.toggleState({ id: $scope.order._id, c: toClose }, resp => {
        _.merge($scope.order, resp);
      });
    };

    $scope.onTaskStatusChange = function($event) {
      if (!$event.hasOwnProperty('task')) {
        return;
      }

      const task = $event.task;
      const orderTask = _.find($scope.order.tasks, { _id: task._id });

      _.merge(orderTask, task);

      setAgregatedDepartments();
      setCanBeClosed();
    };

    $scope.openOrder = function() {
      $scope.order = Order.toggleState({ id: $scope.order._id, c: false });
    };

    $scope.onTaskCreate = function(options = {}) {
      TaskRepository.createTask({
        orderId: $scope.order._id,
        depId: options.depId
      }).then(function(task) {
        order.tasks.push(task);

        // TODO: baisu baisu
        var department = _.find($scope.aggregatedDepartments, {
          _id: task.dep
        });

        if (!department) {
          department = _.find(departments, { _id: task.dep });
          $scope.aggregatedDepartments.push(department);
        }

        department.tasks = department.tasks || [];
        department.tasks.push(task);
      });
    };

    $scope.reportJob = function() {
      var modalScope = $rootScope.$new();

      angular.extend(modalScope, {
        modal: {
          title: 'Report job',
          buttons: [
            {
              classes: 'btn-primary',
              text: 'Report',
              click: function(e) {
                reportModal.close(e);
              }
            },
            {
              classes: 'btn-default',
              text: 'Cancel',
              click: function(e) {
                reportModal.dismiss(e);
              }
            }
          ]
        }
      });

      var reportModal = $uibModal.open({
        templateUrl: 'app/orders/partials/report-job.html',
        scope: modalScope,
        controller: 'JobReportCtrl',
        resolve: {
          workers: function() {
            return $scope.workers;
          }
        }
      });

      // reportModal.result.then(function (selectedItem) {
      //  //$scope.selected = selectedItem;
      //  console.log($scope);
      // }, function () {
      //  console.log('Modal dismissed at: ' + new Date());
      // });
    };

    $scope.generateInvoice = function() {
      $scope.isInvoiceGenerating = true;
      Order.generateInvoice({ id: $scope.order._id })
        .$promise.then(function(invoice) {
          $scope.order.invoice = invoice;
          toastr.success('Sąskaita sugeneruota');
        })
        .catch(function() {
          toastr.error('Klaida generuojant sąskaitą');
        })
        .finally(function() {
          $scope.isInvoiceGenerating = false;
        });
    };

    $scope.downloadInvoice = function(invoice) {
      $window.open(`${API_URL}/invoices/${invoice._id}/download`, '_blank');
    };
  })

  .controller('OrderFormCtrl', function(
    $scope,
    $rootScope,
    order,
    orderNumber,
    customers,
    departments,
    _,
    Customer,
    Order,
    Auth,
    $state,
    $uibModal,
    toastr,
    ordersManager,
    orderFactory,
    titleService
  ) {
    titleService.setTitle(`${order.number} ${order.name}`);

    var tenant = Auth.getCurrentUser().tenant;
    var customFields = {
      picoline: [
        {
          key: 'custom.fileName',
          type: 'input',
          templateOptions: {
            label: 'Failas'
          }
        },
        {
          noFormControl: true,
          template: '<label>Failo vieta</label>'
        },
        {
          key: 'custom.filePlace.ftp',
          type: 'checkbox',
          templateOptions: {
            label: 'FTP'
          }
        },
        {
          key: 'custom.filePlace.email',
          type: 'checkbox',
          templateOptions: {
            label: 'email'
          }
        },
        {
          key: 'custom.filePlace.weTransfer',
          type: 'checkbox',
          templateOptions: {
            label: 'WeTransfer'
          }
        },
        {
          key: 'custom.material',
          type: 'input',
          templateOptions: {
            label: 'Medžiaga'
          }
        },
        {
          key: 'custom.finish',
          type: 'textarea',
          templateOptions: {
            label: 'Apdaila'
          }
        },
        {
          key: 'custom.careLabel',
          type: 'checkbox',
          templateOptions: {
            label: 'Priežūros etikėtė'
          }
        },
        {
          key: 'custom.sizeAmount',
          type: 'textarea',
          templateOptions: {
            label: 'Gaminio dydis, m (plotis, aukštis); kiekis',
            row: 5
          }
        },
        {
          key: 'custom.price',
          type: 'textarea',
          templateOptions: {
            label: 'Kaina',
            row: 5
          }
        },
        {
          noFormControl: true,
          template: '<label>Atsiims</label>'
        },
        {
          key: 'custom.takenIn.office',
          type: 'checkbox',
          templateOptions: {
            label: 'ofisas'
          }
        },
        {
          key: 'custom.takenIn.production',
          type: 'checkbox',
          templateOptions: {
            label: 'gamyba'
          }
        },
        {
          key: 'custom.takenIn.send',
          type: 'checkbox',
          templateOptions: {
            label: 'siuntimas'
          }
        },
        {
          key: 'custom.takenIn.transfer',
          type: 'checkbox',
          templateOptions: {
            label: 'nuvešti/montuoti'
          }
        }
      ],
      adgo: [
        {
          key: 'custom.fileName',
          type: 'input',
          // model: 'order.custom.fileLoacation',
          templateOptions: {
            label: 'Failas'
          }
        }
      ]
    };

    order = orderFactory.getNewOrder(order);

    if (order.deadline) {
      order.deadline = new Date(order.deadline);
    }

    $scope.isEditMode = false;
    $scope.order = order;
    $scope.customers = customers;
    $scope.departments = departments;
    $scope.newCustomer = new Customer();
    $scope.datepickerOptions = {
      startingDay: 1
    };
    $scope.isBtnDisabled = false;
    $scope.isVP = tenant === 'vizpro';

    order.tasks = order.tasks || [];

    Order.history(function(resp) {
      $scope.cityHistory = resp.city;
      $scope.addressHistory = resp.address;
      $scope.producerHistory = resp.producer;
    });

    if (order.hasOwnProperty('_id')) {
      $scope.isEditMode = true;
    }

    if (!$scope.isEditMode) {
      order.number = orderNumber;
    }

    if (order.deal && !$scope.isEditMode) {
      const deal = order.deal;
      order.client = deal.company;
      order.name = deal.name;
      order.deadline = deal.dueDate ? new Date(deal.dueDate) : undefined;
      order.description = deal.description;
    }

    if (order.client && order.client._id) {
      order.client = _.find(customers, { _id: order.client._id });
    }

    $scope.addCustomer = function() {
      var modalResult = getCustomerFormModal($scope.order);

      modalResult.result.then(function(customer) {
        $scope.customers.push(customer);
        $scope.order.client = customer;
      });
    };

    $scope.handleDeadlineChange = function({ moment: dateMoment }) {
      $scope.order.deadline = dateMoment.toDate();
    };

    function getCustomerFormModal() {
      const modalScope = $rootScope.$new();

      angular.extend(modalScope, {
        modal: {
          title: 'Naujas klientas'
        }
      });

      const formModal = $uibModal.open({
        templateUrl: 'app/orders/partials/customer-modal.html',
        scope: modalScope,
        controller: [
          '$scope',
          '$uibModalInstance',
          function($scope, $uibModalInstance) {
            $scope.onSave = function(customer) {
              $uibModalInstance.close(customer);
            };
          }
        ]
      });

      return formModal;
    }

    function getDepSettingsModal(dep) {
      const modalScope = $rootScope.$new();

      const formModal = $uibModal.open({
        templateUrl: 'app/orders/partials/department-modal.html',
        scope: modalScope,
        resolve: {
          workers: [
            'UserFactory',
            function(UserFactory) {
              return UserFactory.getWorkers(['_id', 'name']);
            }
          ]
        },
        controller: [
          '$scope',
          'workers',
          '$uibModalInstance',
          function($scope, workers, $uibModalInstance) {
            $scope.department = dep;
            $scope.task = ordersManager.getTask(order, dep._id);
            $scope.workers = workers;

            $scope.onSave = function() {
              $uibModalInstance.close($scope.deptSettings);
            };
          }
        ]
      });

      return formModal;
    }

    function appendTask(options) {
      const task = ordersManager.getTask(order, options.dep);
      let startDate;
      let endDate;

      if (!$scope.order.tasks) {
        $scope.order.tasks = [];
      }

      if (
        options.hasOwnProperty('start') &&
        options.start.hasOwnProperty('date') &&
        options.start.hasOwnProperty('time')
      ) {
        startDate = new Date(options.start.date + ' ' + options.start.time);
      }

      if (
        options.hasOwnProperty('end') &&
        options.start.hasOwnProperty('date') &&
        options.start.hasOwnProperty('time')
      ) {
        endDate = new Date(options.end.date + ' ' + options.end.time);
      }

      const taskData = {
        dep: options.dep,
        name: options.name,
        assignee: [options.assignee],
        startDate: startDate,
        endDate: endDate
      };

      if (task) {
        angular.extend(task, taskData);
      } else {
        $scope.order.tasks.push(taskData);
      }
    }

    function getDepartmentDefaultUserId(did) {
      const dep = _.find($scope.departments, { _id: did });

      return dep && dep.defaultUser ? dep.defaultUser : null;
    }

    $scope.onDepChanged = function(dep) {
      var taskIndex = _.findIndex(order.tasks, { dep: dep._id });

      if (taskIndex === -1) {
        order.addNewTask({
          name: dep.name,
          dep: dep._id,
          assignee: getDepartmentDefaultUserId(dep._id)
        });
      } else {
        order.removeTaskByIndex(taskIndex);
      }
    };

    $scope.editDepSettings = function(dep) {
      var modalResult = getDepSettingsModal(dep);

      modalResult.result.then(function(depSettings) {
        appendTask({
          name: dep.name,
          dep: dep._id,
          assignee: depSettings.worker,
          start: depSettings.start,
          end: depSettings.end
        });
      });
    };

    $scope.submit = function(valid) {
      $scope.isBtnDisabled = true;
      $scope.$broadcast('show-errors-check-validity');

      var fnSuccess = function() {
        toastr.success('Užsakymas sėkmingai užsaugotas', 'Užsaugota!');
        if ($rootScope.previousState && $rootScope.previousState.name) {
          $state.go($rootScope.previousState.name, $rootScope.previousState.params);
        } else {
          $state.go('root.orders.list');
        }
      };
      var fnFail = function(error) {
        const { data: message = 'Užsakymas neužsaugotas. Bandykite dar kartą' } = error;
        toastr.error(message, 'Klaida');
        $scope.isBtnDisabled = false;
      };

      if (!valid) {
        $scope.isBtnDisabled = false;
        return;
      }

      if ($scope.isEditMode) {
        Order.update($scope.order, fnSuccess, fnFail);
      } else {
        Order.save($scope.order, fnSuccess, fnFail);
      }
    };

    $scope.customFields = customFields.hasOwnProperty(tenant) ? customFields[tenant] : [];
  })

  .controller('OrderMaterialsCtrl', function($scope, _, Inventory, Order, toastr) {
    var vm = this;

    vm.order = $scope.$parent.order;
    vm.selectedMaterial = null;

    vm.getMaterial = function(query) {
      return Inventory.search({ query: query }).$promise;
    };

    vm.onMaterialSelect = function($item) {
      $item.units[0].amount = 0;
      if ($item.units[1]) {
        $item.units[1].amount = 0;
      }
    };

    vm.addMaterial = function(materialForm) {
      $scope.$broadcast('show-errors-check-validity');

      if (!_.has(vm.selectedMaterial, '_id') || !materialForm.$valid) {
        return;
      }

      Order.addMaterial(
        { id: vm.order._id },
        vm.selectedMaterial,
        resp => {
          vm.order.materials.push(resp);
          toastr.success('Medžiaga ' + resp.name + ' sėkmingai pridėta', 'Išsaugota');
        },
        () => {
          toastr.error('Medžiagos užsaugoti nepavyko', 'Klaida');
        }
      );

      vm.selectedMaterial = null;
      vm.selectedAmount = null;
      vm.selectedLength = null;

      materialForm.$setPristine();
      $scope.$broadcast('show-errors-reset');
    };

    vm.removeMaterial = function(mtrl) {
      const materials = vm.order.materials;
      const indx = _.findIndex(materials, { _id: mtrl._id });

      materials.splice(indx, 1);

      Order.delMaterial(
        { id: vm.order._id, mtrl: mtrl._id },
        () => {
          toastr.success('Medžiaga ' + mtrl.name + ' sėkmingai ištrinta', 'Ištrinta');
        },
        () => {
          toastr.error('Medžiagos užsaugoti nepavyko', 'Klaida');
        }
      );
    };
  })

  .controller('OrderCommentCtrl', function($scope, Order, Auth, toastr) {
    var order = $scope.$parent.order;

    $scope.saveComment = function(commentForm) {
      $scope.$broadcast('show-errors-check-validity');

      if (!commentForm.$valid) {
        return;
      }

      if (!$scope.order.comments) {
        $scope.order.comments = [];
      }

      Order.saveComment(
        { id: order._id },
        { text: $scope.comment.text, author: Auth.getCurrentUser() },
        resp => {
          $scope.order.comments.push(resp);
          toastr.success('Komentaras sėkmingai pridėtas', 'Išsaugota');
        },
        () => {
          toastr.error('Komentaro išsaugoti nepavyko', 'Klaida');
        }
      );

      $scope.comment.text = '';

      commentForm.$setPristine();
      $scope.$broadcast('show-errors-reset');
    };

    $scope.removeComment = function(comment, index) {
      Order.deleteComment(
        { id: order._id, cid: comment._id },
        () => {
          $scope.order.comments.splice(index, 1);
          toastr.success('Komentaras sėkmingai ištrintas', 'Ištrinta');
        },
        () => {
          toastr.error('Komentaro ištrinti nepavyko', 'Klaida');
        }
      );
    };
  })

  .controller('OrderJobsCtrl', function(
    $scope,
    $rootScope,
    Order,
    User,
    Auth,
    $uibModal,
    toastr,
    _
  ) {
    const order = $scope.$parent.order;
    const getJobModal = function getJobModal(job) {
      var modalScope = $rootScope.$new();

      angular.extend(modalScope, {
        modal: {
          title: job ? 'Redaguoti darbą' : 'Naujas darbas'
        }
      });

      return $uibModal.open({
        templateUrl: 'app/orders/partials/order-job-form.html',
        scope: modalScope,
        controller: 'OrderJobFormCtrl as ctrl',
        resolve: {
          job: () => job
        }
      });
    };

    $scope.removeJob = function(id) {
      const jobs = order.jobs;
      const jobIndx = _.findIndex(jobs, { _id: id });

      Order.deleteJob(
        { id: order._id, jid: id },
        () => {
          jobs.splice(jobIndx, 1);
          toastr.success('Darbas sėkmingai ištrintas', 'Ištrinta');
        },
        () => {
          toastr.error('Darbo ištrinti nepavyko', 'Klaida');
        }
      );
    };

    $scope.jobForm = function(job) {
      var modal = getJobModal(job);

      modal.result.then(nJob => {
        var isNew = nJob.hasOwnProperty('_id') ? false : true;

        Order.saveJob(
          { id: order._id },
          nJob,
          resp => {
            if (isNew) {
              order.jobs.push(resp);
            } else {
              job = resp;
            }
            toastr.success('Darbas sėkmingai išsaugotas', 'Išsaugota');
          },
          () => {
            toastr.error('Darbo išsaugoti nepavyko', 'Klaida');
          }
        );
      });
    };
  })

  .controller('OrderJobFormCtrl', function($scope, $uibModalInstance, User, job, _) {
    $scope.job = job || { type: 'salary' };
    $scope.workers = User.getWorkers();

    $scope.setWorker = function() {
      var user = _.find($scope.workers, { _id: $scope.job.user._id });

      $scope.job.user.name = user.name;
    };

    $scope.addJob = function(jobForm) {
      $scope.$broadcast('show-errors-check-validity');

      if (!jobForm.$valid) {
        return;
      }

      $uibModalInstance.close($scope.job);
    };
  })

  .controller('NotesController', function($scope, _, toastr, Order) {
    $scope.newNote = '';

    $scope.editorCreated = function(editor) {
      $scope.editor = editor;
    };

    $scope.saveNote = function(noteForm) {
      $scope.$broadcast('show-errors-check-validity');

      if (!noteForm.$valid) {
        return;
      }

      if (!$scope.order.notes) {
        $scope.order.notes = [];
      }

      Order.saveNote(
        { id: $scope.order._id },
        { text: $scope.newNote },
        resp => {
          $scope.order.notes.push(resp);
          toastr.success('Užrašas sėkmingai pridėtas', 'Išsaugota');
        },
        () => {
          toastr.error('Užrašo išsaugoti nepavyko', 'Klaida');
        }
      );

      $scope.newNote = '';
      $scope.editor.setText('');
      noteForm.$setPristine();
      $scope.$broadcast('show-errors-reset');
    };

    $scope.removeNote = function(note) {
      const index = _.findIndex($scope.order.notes, note);

      Order.deleteNote(
        { id: $scope.order._id, nid: note._id },
        () => {
          $scope.order.notes.splice(index, 1);
          toastr.success('Užrašas sėkmingai ištrintas', 'Ištrinta');
        },
        () => {
          toastr.error('Užrašo ištrinti nepavyko', 'Klaida');
        }
      );
    };
  })

  .controller('OrderInvoicesCtrl', function($scope, _, Invoice, Modal, Order, toastr) {
    const getNewInvoice = function getNewInvoice() {
      return new Invoice({
        amount: 0,
        order: [$scope.order._id],
        currency: 'EUR'
      });
    };
    const innerExp = {};
    const getSums = function() {
      let inc = 0;
      let exp = 0;

      _.forEach($scope.invoices, invoice => {
        if (invoice.type === 'in') {
          inc += invoice.amount;
        } else {
          exp += invoice.amount;
        }
      });

      exp += _.reduce(
        innerExp,
        (total, innExp) => {
          return total + innExp;
        },
        0
      );

      $scope.expenses = exp.toFixed(2);
      $scope.income = inc.toFixed(2);
      $scope.balance = (inc - exp).toFixed(2);
    };

    $scope.newInvoice = getNewInvoice();
    $scope.orderMaterials = (angular.copy($scope.order.materials) || []).map(material => {
      const unit = material.units[0];

      return angular.extend(material, {
        amount: unit.amount,
        unitName: unit.name
      });
    });

    $scope.$watchCollection('invoices', getSums);

    $scope.invoiceSubmit = function(form) {
      const isNew = !$scope.newInvoice.hasOwnProperty('_id');

      $scope.$broadcast('show-errors-check-validity');

      if (!form.$valid) {
        return;
      }

      const action = isNew ? '$save' : '$update';

      $scope.newInvoice[action](
        function(resp) {
          if (isNew) {
            $scope.invoices.push(resp);
          }

          $scope.newInvoice = getNewInvoice();
          form.$setPristine();
          $scope.$broadcast('show-errors-reset');
          toastr.success('Sąskaita-faktūra ' + resp.number + ' sėkmingai išsaugota', 'Išsaugota');
        },
        function() {
          toastr.error('Sąskaitos-faktūros išsaugoti nepavyko', 'Klaida');
        }
      );
    };

    $scope.updateJob = function(job) {
      Order.saveJob({ id: $scope.order._id }, job);
    };

    $scope.updateMaterial = function(material) {
      Order.updateMaterial(
        {
          id: $scope.order._id,
          mtrl: material._id
        },
        material
      );
    };

    $scope.editInvoice = function(invoice) {
      if (invoice.hasOwnProperty('date') && invoice.date) {
        invoice.date = new Date(invoice.date);
      }

      $scope.newInvoice = invoice;
    };

    $scope.removeInvoice = Modal.confirm.delete(function(invoice, index) {
      invoice.$delete(
        function() {
          $scope.invoices.splice(index, 1);
          toastr.success('Sąskaita-faktūra {{invoice.number}} sėkmingai ištrinta', 'Ištrinta');
        },
        function() {
          toastr.error('Sąskaitos-faktūros ištrinti nepavyko', 'Klaida');
        }
      );
    });

    $scope.getJobSum = function(job) {
      var sum = (job.amount * (job.tariff || 0)).toFixed(2);
      innerExp[job._id] = +sum;
      getSums();
      return sum;
    };

    $scope.getMtrlSum = function(mtrl) {
      const sum = ((mtrl.amount || 1) * (mtrl.tariff || 0)).toFixed(2);

      innerExp[mtrl._id] = +sum;
      getSums();
      return sum;
    };
  });

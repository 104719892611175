'use strict';

angular.module('uvsApp')
  .controller('RolesCtrl', function ($scope) {
    $scope.message = 'Hello';
  })
  .controller('RolesFormCtrl', function (Role) {
    var vm = this;

    vm.role = new Role();

    vm.submit = function () {
      vm.role.$save();
      vm.role = new Role();
    };
  });

'use strict';

class TenantsService {
  constructor(tenantsResource) {
    'ngInject';

    this.tenantsResource = tenantsResource;
  }

  getTenant() {
    return this.tenantsResource.get().$promise;
  }
}

angular.module('app.tenants').service('tenantsService', TenantsService);

class CompanyInfoController {
  constructor($state) {
    'ngInject';

    this.$state = $state;
  }

  $onChanges(changes) {
    if (changes.isChangeable) this.isChangeable = this.isChangeable === 'true';
  }

  onSelect() {
    this.$state.go('companySelect', {
      onClose: company => {
        this.onChange({
          $event: {
            company
          }
        });
      }
    });
  }

  onCompanyCreate() {
    this.$state.go('companyCreate', {
      onClose: company =>
        this.onChange({
          $event: {
            company
          }
        })
    });
  }
}

angular.module('components.companies').controller('CompanyInfoController', CompanyInfoController);

'use strict';

angular.module('uvsApp').factory('Proposal', function($resource, API_URL) {
  var Proposal = $resource(
    API_URL + '/proposals/:id',
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      },
      getByOrderId: {
        method: 'GET',
        url: API_URL + '/proposals/order/:orderId'
      }
    }
  );
  return Proposal;
});

const dealDescriptionComponent = {
  templateUrl: 'components/activity/deal-description/deal-description.html',
  controller: 'DealDescriptionController',
  bindings: {
    description: '<',
    onChange: '&'
  }
}

angular
  .module('components.activity')
  .component('dealDescription', dealDescriptionComponent);

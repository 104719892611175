const dealInvoicesComponent = {
  templateUrl: 'app/deals/deal-invoices/deal-invoices.html',
  controller: 'DealInvoicesController',
  bindings: {
    dealId: '<',
    onChange: '&'
  }
};

angular.module('app.deals').component('dealInvoices', dealInvoicesComponent);

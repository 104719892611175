'use strict';

const activityComponent = {
  templateUrl: 'components/activity/activity.html',
  controller: 'ActivityController',
  transclude: true,
  bindings: {
    target: '<',
    active: '<',
    targetType: '@'
  }
};

angular.module('components.activity').component('activity', activityComponent);

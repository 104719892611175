'use strict';

angular.module('uvsApp').factory('Inventory', function($resource, API_URL) {
  var Inventory = $resource(
    API_URL + '/inventories/:id/:controller',
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      },
      search: {
        method: 'GET',
        params: {
          controller: 'search',
          query: '@query'
        },
        isArray: true
      },
      vary: {
        method: 'POST',
        params: {
          controller: 'vary'
        }
      },
      getCategories: {
        method: 'GET',
        url: API_URL + '/inventories/categories',
        isArray: true
      },
      getSummary: {
        method: 'GET',
        url: API_URL + '/inventories/summary'
      }
    }
  );

  return Inventory;
});

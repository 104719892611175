const profileSettingsComponent = {
  bindings: {
    settings: '<',
    onChange: '&'
  },
  templateUrl: 'app/profile/profileSettings/profile-settings.html',
  controller: 'ProfileSettingsController'
};

angular.module('components.profile').component('profileSettings', profileSettingsComponent);

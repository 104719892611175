'use strict';

class PortfolioService {
  constructor($uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  showSummary(order, tasks) {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'components/portfolio/summary/summary.html',
      controller: 'PortfolioSummaryController as $ctrl',
      size: 'l',
      resolve: {
        order: () => order,
        tasks: () => tasks
      }
    });

    return modalInstance.result;
  }
}

angular
  .module('components.portfolio')
  .service('PortfolioService', PortfolioService);

const estimateSummaryComponent = {
  templateUrl: 'app/estimates/estimate-summary/estimate-summary.html',
  controller: 'EstimateSummaryController',
  bindings: {
    estimate: '<',
    updatedAt: '<',
    onChange: '&'
  }
};


angular
  .module('app.estimates')
  .component('estimateSummary', estimateSummaryComponent);

angular.module('app.estimates').factory('estimatesResource', function($resource, API_URL) {
  return $resource(
    `${API_URL}/estimates/:id`,
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      },

      getProposalPdf: {
        method: 'GET',
        url: `${API_URL}/estimates/:id/download-proposal`,
        responseType: 'arraybuffer',
        transformResponse: function(data, headersGetter) {
          return { data, filename: headersGetter('filename') };
        }
      },

      getDetailedPdf: {
        method: 'GET',
        url: `${API_URL}/estimates/:id/download-detailed`,
        responseType: 'arraybuffer',
        transformResponse: function(data, headersGetter) {
          return { data, filename: headersGetter('filename') };
        }
      }
    }
  );
});

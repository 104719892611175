class OrderInfoController {
  constructor(ordersStore) {
    'ngInject';

    this.store = ordersStore;
  }

  handleTasksChange({task}) {
    this.store.updateTask(task);
  }

  handleTaskDelete({task}) {
    this.store.deleteTask(task);
  }
}

angular.module('uvsApp').controller('OrderInfoController', OrderInfoController);

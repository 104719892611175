'use strict';

angular.module('uvsApp')
  .directive('uvsDepartmentSettings', function () {
    return {
      restrict: 'E',
      scope: {
        departmentSettings: '=',
        workers: '=',
        task: '=?',
        cb: '&'
      },
      templateUrl: 'app/directives/uvsDepartmentSettings/form.html',
      controller: 'DepartmentSettingsController',
      controllerAs: 'ds',
      bindToController: true
    };
  });

const estimateComponent = {
  templateUrl: 'app/estimates/estimate/estimate.html',
  controller: 'EstimateController',
  bindings: {
    deal: '<',
    estimate: '<',
    $transition$: '<'
  }
};

angular
  .module('app.estimates')
  .component('estimate', estimateComponent)
  .config(function($stateProvider) {
    $stateProvider.state('root.estimates.details', {
      url: '/:id?did',
      component: 'estimate',
      params: {
        did: null
      },
      resolve: {
        estimate: ($transition$, estimatesService) => {
          if (!$transition$.params().id) {
            return;
          }

          return estimatesService.getEstimate($transition$.params().id);
        }
      }
    });
  });

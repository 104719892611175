const estimateWidget = {
  templateUrl: 'app/estimates/estimate-widget/estimate-widget.html',
  controller: 'EstimateWidgetController',
  bindings: {
    estimate: '<',
    deal: '<',
    onDownloadInvoice: '&',
    onUpdate: '&'
  }
};

angular.module('app.estimates').component('estimateWidget', estimateWidget);

'use strict';

angular.module('uvsApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('root.proposals', {
        abstract: true,
        url: '/proposals',
        views: {
          'content': {
            template: '<div ui-view></div>'
          }
        }
      })
      .state('root.proposals.list', {
        url: '/list',
        templateUrl: 'app/proposals/partials/list.html',
        controller: 'ProposalsCtrl',
        authenticate: true,
        resolve: {
          proposals: function (Proposal) {
            return Proposal.query().$promise;
          }
        }
      })
      .state('root.proposals.active', {
        url: '/active',
        templateUrl: 'app/proposals/partials/list.html',
        controller: 'ProposalsCtrl',
        authenticate: true,
        resolve: {
          proposals: function (Proposal) {
            return Proposal.query({status: 0}).$promise;
          }
        }
      })
      .state('root.proposals.converted', {
        url: '/converted',
        templateUrl: 'app/proposals/partials/list.html',
        controller: 'ProposalsCtrl',
        authenticate: true,
        resolve: {
          proposals: function (Proposal) {
            return Proposal.query({status: 1}).$promise;
          }
        }
      })
      .state('root.proposals.rejected', {
        url: '/rejected',
        templateUrl: 'app/proposals/partials/list.html',
        controller: 'ProposalsCtrl',
        authenticate: true,
        resolve: {
          proposals: function (Proposal) {
            return Proposal.query({status: 2}).$promise;
          }
        }
      })
      .state('root.proposals.new', {
        url: '/new',
        templateUrl: 'app/proposals/partials/form.html',
        controller: 'ProposalFormCtrl',
        authenticate: true,
        resolve: {
          proposal: function (Proposal) {
            return new Proposal({status: 0});
          },
          customers: function (Customer) {
            return Customer.query().$promise;
          }
        }
      })
      .state('root.proposals.edit', {
        url: '/:id',
        templateUrl: 'app/proposals/partials/form.html',
        controller: 'ProposalFormCtrl',
        authenticate: true,
        resolve: {
          proposal: function (Proposal, $stateParams) {
            return Proposal.get({id: $stateParams.id}).$promise;
          },
          customers: function (Customer) {
            return Customer.query().$promise;
          }
        }
      });
  });

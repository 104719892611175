'use strict';

angular.module('uvsApp').factory('Template', function($resource, API_URL) {
  var Template = $resource(
    API_URL + '/templates/:id',
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      },
      getReplaceableFields: {
        method: 'GET',
        url: API_URL + '/templates/replaceableFields',
        isArray: true
      }
    }
  );
  return Template;
});

const pipelineSettingsComponent = {
  templateUrl: 'app/settings/pipeline/pipeline-settings.html',
  controller: 'PipelineSettingsController',
  bindings: {
    pipeline: '<'
  }
};

angular
  .module('components.settings')
  .component('pipelineSettings', pipelineSettingsComponent)
  .config(($stateProvider) => {
    $stateProvider
      .state('root.settings.pipeline', {
        url: '/pipeline/:id',
        component: 'pipelineSettings',
        resolve: {
          pipeline: ($transition$, pipelinesService) => {
            return pipelinesService.getPipeline($transition$.params().id);
          }
        }
      });
  });

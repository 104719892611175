let departmentListComponent = {
  templateUrl: 'app/directives/departmentList/departmentList.html',
  bindings: {
    order: '<',
    deps: '<'
  },
  controller: 'DepartmentListController',
  controllerAs: 'ctrl'
};

angular.module('uvsApp')
  .component('departmentList', departmentListComponent);



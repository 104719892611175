class WatchSourceController {
    constructor(SubscriptionService, toastr) {
      'ngInject';
      this.subscriptionService = SubscriptionService;
      this.toastr = toastr;
    }

    $onInit() {
      this.isWatching = false;
      this.subscriptionService
        .getOne(this.source, this.user)
        .then((subscriptions) => {
          let [sub] = subscriptions;
          this.setSubscription(sub);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setSubscription(sub) {
      this.subscription = sub;
      this.setIsWatching();
    }

    setIsWatching() {
      this.isWatching = !!this.subscription;
    }

    watchSource() {
      let sub = this.subscription || this.getNewSubscription();

      this.subscriptionService
        .toggleSubscription(sub)
        .then((subscription) => {
          if (!subscription.hasOwnProperty('_id')) {
            subscription = undefined;
            this.toastr.success('Stebėjimas sustabdytas.');
          } else {
            this.toastr.success('Stebėjimas pradėtas.');
          }

          this.setSubscription(subscription);
        });
    }

    getNewSubscription() {
      return {
        event: 'order.task.status.done',
        source: this.source,
        subscriber: this.user
      };
    }
  }

  angular.module('uvsApp')
    .controller('WatchSourceController', WatchSourceController);

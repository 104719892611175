class AppController {
  constructor(socket, Auth, TaskRepository, ordersRepository, usersStore) {
    'ngInject';

    this.socket = socket;
    this.auth = Auth;
    this.tasksRepository = TaskRepository;
    // this.orders = orders;
    this.ordersRepository = ordersRepository;
    this.usersStore = usersStore;
  }

  $onInit() {
    const user = this.usersStore.currentUser;
    // Notify server about self
    this.socket.socket.emit('info', getUserDataForSocket(user));

    // this.socket.syncUpdates('order', this.orders);
    // this.socket.syncUpdates('task',
    //   this.tasksRepository.getTasksFromOrders(this.orders),
    //   (event, task) => {
    //     if (event === 'created') {
    //       this.ordersRepository.addTask(task.order, task);
    //     }

    //     if (event === 'deleted') {
    //       this.ordersRepository.deleteTask(task.order, task);
    //     }
    //   }
    // );
  }
}

angular.module('uvsApp').controller('AppController', AppController);

function getUserDataForSocket(user) {
  return {
    id: user._id,
    name: user.name,
    email: user.email,
    tenant: user.tenant
  };
}

'use strict';

class DealEditController {
  constructor($state, toastr, dealsStore, titleService) {
    'ngInject';

    this.$state = $state;
    this.toastr = toastr;
    this.dealsStore = dealsStore;
    this.titleService = titleService;
  }

  $onInit() {
    this.titleService.setTitle(`${this.deal.number} ${this.deal.name}`);
  }

  onSave(event) {
    return this.dealsStore
      .updateDeal(event.deal)
      .then(deal => {
        this.toastr.success('Sandoris atnaujintas sėkmingai.');
        this.$state.go('root.deals.view', {
          id: deal._id
        });
      })
      .catch(() => {
        this.toastr.error('Nepavyko užsaugoti sandorio. Bandykite dar  kartą.');
      });
  }
}

angular.module('app.deals').controller('DealEditController', DealEditController);

class EngagementNoteController {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.reset();
  }

  onNoteSave() {
    this.onSave({
      $event: {
        metadata: {
          body: this.note.body
        },
        type: 'NOTE',
        associations: [{
          kind: this.targetType,
          item: this.target._id
        }]
      }
    });
    this.note.body = '';
  }

  reset() {
    this.note = {
      body: ''
    };
  }
}

angular
  .module('components.activity')
  .controller('EngagementNoteController', EngagementNoteController);

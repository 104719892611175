'use strict';

angular.module('uvsApp').config(function($stateProvider) {
  $stateProvider
    .state('root.departments', {
      abstract: true,
      url: '/departments',
      views: {
        content: {
          templateUrl: 'layouts/with-left-sidebar.html'
        },
        'sidebar@root.departments': {
          templateUrl: 'components/sidebar/sidebar-settings.html'
        }
      }
    })
    .state('root.departments.list', {
      url: '',
      templateUrl: 'app/departments/partials/list.html',
      controller: 'DepartmentsCtrl',
      title: 'Padaliniai'
    })
    .state('root.departments.new', {
      url: '/new',
      templateUrl: 'app/departments/partials/form.html',
      controller: 'DepartmentFormCtrl',
      title: 'Naujas padalinys',
      resolve: {
        department: function() {
          return null;
        }
      }
    })
    .state('root.departments.edit', {
      url: '/:id/edit',
      templateUrl: 'app/departments/partials/form.html',
      controller: 'DepartmentFormCtrl',
      resolve: {
        department: function(Department, $stateParams) {
          return Department.get({ id: $stateParams.id }).$promise;
        },
        workers: function(department, UserFactory) {
          return UserFactory.getByDepartment(department._id);
        }
      }
    });
});

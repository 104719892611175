'use strict';

angular
  .module('uvsApp')
  .controller('InventoryCtrl', function($scope, _, inventories, $rootScope, $uibModal, Modal) {
    $scope.inventories = inventories;

    $scope.categories = _.reduce(
      inventories,
      function(agg, inv) {
        if (inv.category && !_.includes(agg, inv.category)) {
          agg.push(inv.category);
        }

        return agg;
      },
      []
    );

    // socket.syncUpdates('inventory', $scope.inventories);

    $scope.inc = function(inv) {
      var modalResult = getVaryModal(inv);

      modalResult.result.then(function(updatedInv) {
        _.forEach(updatedInv.units, function(unit, i) {
          inv.units[i].amount += unit.amount;
        });

        _.merge(inv, {
          lastModified: new Date(),
          purchasePrice: updatedInv.purchasePrice,
          salesPrice: updatedInv.salesPrice
        });

        inv.$update();
      });
    };

    $scope.del = Modal.confirm.delete(function(inv) {
      inv.$delete();
    });

    $scope.showInfo = function() {
      $uibModal.open({
        component: 'inventorySummary',
        scope: $rootScope.$new()
      });
    };

    function getVaryModal(inv) {
      var modalScope = $rootScope.$new();
      var varyModal;

      angular.extend(modalScope, {
        modal: {
          title: 'Increase/decrease inventory',
          buttons: [
            {
              classes: 'btn-success btn-sm',
              text: 'Increase',
              click: function() {
                varyModal.close($scope.amount);
              }
            },
            {
              classes: 'btn-warning btn-sm',
              text: 'Decrease',
              click: function(e) {
                varyModal.close(e);
              }
            }
          ]
        }
      });

      varyModal = $uibModal.open({
        templateUrl: 'app/inventory/partials/vary.html',
        scope: modalScope,
        controller: 'VaryInventoryCtrl',
        resolve: {
          inv: function() {
            return inv;
          }
        }
      });

      return varyModal;
    }
  })

  .controller('VaryInventoryCtrl', function($scope, $uibModalInstance, _, inv) {
    $scope.currentAmount = _.map(inv.units, function(unit) {
      return unit.amount;
    });
    // just comment
    $scope.units = _.forEach(angular.copy(inv.units), function(unit) {
      unit.amount = 0;
    });

    $scope.oldPurchasePrice = inv.purchasePrice;
    $scope.oldSalesPrice = inv.salesPrice;

    $scope.ok = function(isValid) {
      if (!isValid) {
        return;
      }
      _.forEach($scope.units, function(unit) {
        unit.amount = angular.isNumber(unit.amount) ? unit.amount : parseFloat(unit.amount);
        if ($scope.dec) {
          unit.amount *= -1;
        }
      });

      if ($scope.units[0].amount === 0 && $scope.units[1] && $scope.units[1].amount !== 0) {
        $scope.units[0].amount = $scope.units[1].ratio * $scope.units[1].amount;
      }

      $uibModalInstance.close({
        units: $scope.units,
        purchasePrice: $scope.purchasePrice,
        salesPrice: $scope.salesPrice
      });
    };
  })

  .controller('InventoryFormCtrl', function($scope, inventory, categories, _, $state, UNITS) {
    var isEditMode = false;
    var toList = function toList() {
      $state.go('root.inventories.list');
    };

    if (_.has(inventory, '_id')) {
      isEditMode = true;
    }

    $scope.isEditMode = isEditMode;
    $scope.inventory = inventory;
    $scope.categories = categories;
    $scope.units = inventory.units || [];
    $scope.sysUnits = UNITS;

    $scope.remAlt = function() {
      if ($scope.units.length > 1) {
        $scope.units.pop();
      }
    };
    $scope.submit = function(valid) {
      $scope.$broadcast('show-errors-check-validity');

      if (!valid) {
        return;
      }

      $scope.inventory.units = $scope.units;

      if (isEditMode) {
        $scope.inventory.$update(toList);
      } else {
        $scope.inventory.$save(toList);
      }
    };
  });

const productListComponent = {
  templateUrl: 'app/products/product-list/product-list.html',
  controller: 'ProductListController',
  bindings: {
    products: '<',
    onChange: '&',
    onRemove: '&'
  }
};

angular
  .module('app.products')
  .component('productList', productListComponent);

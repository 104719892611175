angular.module('app.users')

  .factory('UserFactory', function($q, _, User) {
    var factory = {};

    function filterProps(users, props) {
      return _.map(users, function(user) {
          return _.pick(user, props);
        });
    }

    factory.getWorkers = function(props) {
      var dfr = $q.defer();

      User.getWorkers().$promise.then(function(workers) {
        if (props) {
          workers = filterProps(workers, props);
        }
        dfr.resolve(workers);
      });

      return dfr.promise;
    };

    factory.getByDepartment = function(did) {
      return User.query({did: did}).$promise;
    };

    return factory;
  });

class CustomerInvoicesController {
  constructor(invoicesService, customersStore, fileDownloadService) {
    this.invoicesService = invoicesService;
    this.fileDownloadService = fileDownloadService;
    this.customersStore = customersStore;
  }

  $onInit() {
    this.customersStore.fetchInvoices(this.customer._id);

    this.dispose = mobx.autorun(() => {
      this.isLoading = this.customersStore.isInvoicesLoading;
      this.invoices = this.customersStore.invoices;
    });
  }

  $onDestroy() {
    this.dispose();
  }

  downloadInvoice(invoice) {
    this.invoicesService.getPdf(invoice).then(pdf => this.fileDownloadService.download(pdf));
  }
}

angular
  .module('app.customers')
  .controller('CustomerInvoicesController', CustomerInvoicesController);

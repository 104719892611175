'use strict';

angular.module('uvsApp')

  .controller('ProposalsCtrl', function ($scope, $stateParams, proposals, Modal, Proposal, $location, $state) {
      $scope.proposals = proposals;

      $scope.remove = Modal.confirm.delete(function (proposal) {
        Proposal.delete({id: proposal._id}).$promise
          .then(function() {
            $state.go($state.current, $state.params, {reload: true});
          });
      });

      $scope.edit = function () {
        $location.path('proposals/edit/' + this.proposal._id);
      };
  })

  .controller('ProposalFormCtrl', function ($scope, $rootScope, proposal, customers, Customer, Proposal, Auth, $state, $uibModal, Modal, toastr, ProposalStatuses) {

    $scope.isEditMode = false;
    $scope.proposal = proposal;
    $scope.customers = customers;
    $scope.newCustomer = new Customer();
    $scope.datepickerOptions = {
      startingDay: 1
    };

    if (proposal.hasOwnProperty('_id')) {
      $scope.isEditMode = true;
    }

    $scope.statuses = ProposalStatuses;


    if (proposal.client && proposal.client._id) {
      proposal.client = _.find(customers, {_id: proposal.client._id});
    }

    $scope.addCustomer = function () {
      var modalResult = getCustomerFormModal($scope.proposal);

      modalResult.result.then(function (customer) {
        $scope.customers.push(customer);
        $scope.proposal.client = customer;
      });
    };

    function getCustomerFormModal() {
      var modalScope = $rootScope.$new(),
        formModal;

      angular.extend(modalScope, {
        modal: {
          title: 'Naujas klientas'
        }
      });

      formModal = $uibModal.open({
        templateUrl: 'app/orders/partials/customer-modal.html',
        scope: modalScope,
        controller: function ($scope, $uibModalInstance) {
          $scope.onSave = function () {
            $uibModalInstance.close($scope.newCustomer);
          };
        }
      });

      return formModal;
    }


    if(ProposalStatuses[proposal.status].code === 'ACTIVE') {
      $scope.showActionButtons = true;
    }

    $scope.reject = function() {

      var options = {
        html: '<p>Ar jūs tikrai norite atmesti pasiūlymą ?</p>',
        title: 'Tikrai atmesti ?',
        actionText: 'Atmesti',
        css: 'modal-danger',
        actionCss: 'btn-danger'
      };

      Modal.confirm.change(function () {
        var options = {
          content: 'Pasiūlymas sėkmingai atmestas',
          title: 'Atmesta!',
          state: 'root.proposals.rejected'
        };
        proposal.status = 2;
        $scope.submit($scope.proposalForm.$valid, options);
      })(options);

    };

    $scope.convert = function() {

      var options = {
        html: '<p>Ar jūs tikrai norite konvertuoti pasiūlymą ?</p>',
        title: 'Tikrai konvertuoti ?',
        actionText: 'Konvertuoti',
        css: 'modal-success',
        actionCss: 'btn-success'
      };

      Modal.confirm.change(function () {
        $state.go('root.orders.new', { proposal: proposal });
      })(options);

    };


    $scope.submit = function (valid, options) {
      $scope.$broadcast('show-errors-check-validity');

      options = options || {};

      var fnSuccess = function () {
            toastr.success(options.content || 'Pasiūlymas sėkmingai užsaugotas', options.title || 'Užsaugota!');
            if(!options.state) {
              options.state = 'root.proposals.active';
              if ($scope.isEditMode && $rootScope.previousState && $rootScope.previousState.name) {
                  options.state = $rootScope.previousState.name;
              }
            }
            $state.go(options.state);
          },
          fnFail = function () {
            toastr.error('Pasiūlymas neužsaugotas. Bandykite dar kartą', 'Klaida');
          };

      if (!valid) {
        return;
      }

      if ($scope.isEditMode) {
        $scope.proposal.$update(fnSuccess, fnFail);
      } else {
        $scope.proposal.$save(fnSuccess, fnFail);
      }
    };
  });

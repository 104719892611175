const engagementTask = {
  templateUrl: 'components/activity/engagement-task/engagement-task.html',
  controller: 'EngagementTaskController',
  bindings: {
    target: '<',
    targetType: '@',
    onSave: '&'
  }
};

angular
  .module('components.activity')
  .component('engagementTask', engagementTask)
  .constant('REMINDERS', {
    0: 'Tuo pačiu metu',
    5: 'Likus 5 minutėms',
    15: 'Likus 15 minučių',
    30: 'Likus 30 minučių',
    60: 'Likus 1 valandai',
    240: 'Likus 3 valandoms',
    1440: 'Dieną prieš'
  });

'use strict';

angular.module('uvsApp').factory('Order', function($resource, API_URL) {
  const Order = $resource(
    API_URL + '/orders/:id/:controller',
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      },
      getByNumber: {
        method: 'GET',
        url: API_URL + '/orders/number/:number'
      },
      order: {
        method: 'POST',
        params: {
          id: 'order'
        }
      },
      history: {
        method: 'GET',
        params: {
          id: 'history'
        }
      },
      search: {
        method: 'GET',
        url: API_URL + '/orders/search',
        isArray: true
      },
      addMaterial: {
        method: 'POST',
        url: API_URL + '/orders/:id/materials'
      },
      updateMaterial: {
        method: 'PUT',
        url: API_URL + '/orders/:id/materials/:mtrl'
      },
      delMaterial: {
        method: 'DELETE',
        url: API_URL + '/orders/:id/materials/:mtrl'
      },
      saveJob: {
        method: 'POST',
        url: API_URL + '/orders/:id/jobs'
      },
      updateJob: {
        method: 'PUT',
        url: API_URL + '/orders/:id/jobs/:jid'
      },
      deleteJob: {
        method: 'DELETE',
        url: API_URL + '/orders/:id/jobs/:jid'
      },
      generateInvoice: {
        method: 'GET',
        url: API_URL + '/orders/:id/invoices'
      },
      saveComment: {
        method: 'POST',
        url: API_URL + '/orders/:id/comments'
      },
      deleteComment: {
        method: 'DELETE',
        url: API_URL + '/orders/:id/comments/:cid'
      },
      saveNote: {
        method: 'POST',
        url: API_URL + '/orders/:id/notes'
      },
      deleteNote: {
        method: 'DELETE',
        url: API_URL + '/orders/:id/notes/:nid'
      },
      toggleState: {
        method: 'GET',
        url: API_URL + '/orders/:id/state'
      },
      reload: {
        method: 'GET',
        url: API_URL + '/orders/:id/reload'
      },
      deleteAttachment: {
        method: 'DELETE',
        url: API_URL + '/orders/:id/attach/:aid'
      },
      getUsers: {
        method: 'GET',
        url: API_URL + '/orders/users'
      }
    }
  );

  return Order;
});

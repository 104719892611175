class DepartmentInfoController {
  constructor($http, $log, _, TaskRepository, materialsService, jobsService, toastr, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this._ = _;
    this.toastr = toastr;
    this.API_URL = API_URL;

    this.taskRepository = TaskRepository;
    this.materialsService = materialsService;
    this.jobsService = jobsService;

    this.statusWorkflow = {
      0: [1, 2],
      1: [0, 3, 2],
      2: [0],
      3: [0, 1]
    };
  }

  $onInit() {
    this.isOrderClosed = this.order.closed;
    this.legacyMaterials = this.getLegacyMaterials(this.order, this.department._id);
  }

  changeStatus(task, status) {
    task.status = status;

    this.$http
      .post(`${this.API_URL}/tasks/${task._id}/status`, { status: status })
      .then(resp => {
        this.toastr.success('Statusas pakeistas');
        this._.assign(task, resp.data);
        this.onStatusChange({
          $event: {
            task: task
          }
        });
      })
      .catch(err => {
        this.$log.error(err);
        this.toastr.error('Statuso pakeisti nepavyko. Bandykite dar kartą');
      });
  }

  onTaskEdit(task) {
    this.taskRepository.updateTask(angular.copy(task), this.order._id).then(savedTask => {
      if (savedTask) {
        // edited
        this._.merge(task, savedTask);
      } else {
        // deleted
        const index = this._.findIndex(this.department.tasks, task);
        this.department.tasks.splice(index, 1);
      }
    });
  }

  isStatusBtnVisible(btnStatus, taskStatus) {
    return this.statusWorkflow[taskStatus].includes(btnStatus);
  }

  addMaterial() {
    this.materialsService.addMaterial(this.order._id, this.department).then(material => {
      this.department.materials = this.department.materials || [];
      this.department.materials.push(material);
    });
  }

  destroyMaterial(material) {
    this.materialsService.destroyMaterial(material, this.order._id).then(() => {
      const index = this._.findIndex(this.department.materials, {
        _id: material._id
      });
      this.department.materials.splice(index, 1);
    });
  }

  addJob(fromTask) {
    const userId = fromTask && fromTask.assignee && fromTask.assignee._id;
    // To send Zero makes no sense; it's better to leave field empty
    const totalTime =
      fromTask && fromTask.totalTime && fromTask.totalTime !== 0 ? fromTask.totalTime : undefined;

    this.jobsService
      .addJob({
        orderId: this.order._id,
        departmentId: this.department._id,
        totalTime,
        userId
      })
      .then(job => {
        this.department.jobs = this.department.jobs || [];
        this.department.jobs.push(job);
      });
  }

  editJob(job) {
    this.jobsService.editJob(job, this.order._id).then(updatedJob => {
      this._.merge(job, updatedJob);
    });
  }

  destroyJob(jobId) {
    this.jobsService.destroyJob(jobId, this.order._id).then(() => {
      const index = this._.findIndex(this.department.jobs, { _id: jobId });
      this.department.jobs.splice(index, 1);
    });
  }

  getLegacyMaterials(order, departmentId) {
    const activeTask = this._.find(order.tasks, { dep: departmentId });
    let materials = [];

    if (activeTask && activeTask.materials) {
      materials = activeTask.materials;
    }

    return materials;
  }
}

angular.module('uvsApp').controller('DepartmentInfoController', DepartmentInfoController);

class SalesTaskStatusController {
  constructor($http, $log, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.API_URL = API_URL;
  }

  onClick() {
    this.task.metadata.completed = !this.task.metadata.completed;

    this.$http
      .put(`${this.API_URL}/engagements/${this.task._id}`, this.task)
      .catch(err => this.$log.error(err));

    if (this.onCompleted) {
      this.onCompleted({ task: this.task });
    }
  }
}

angular
  .module('components.sales-task-status')
  .controller('SalesTaskStatusController', SalesTaskStatusController);

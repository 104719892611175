'use strict';

class InvoicesService {
  constructor(invoicesResource) {
    'ngInject';

    this.invoicesResource = invoicesResource;
  }

  search(filters) {
    return this.invoicesResource.search(filters).$promise;
  }

  createInvoice(invoice) {
    return this.invoicesResource.save(invoice).$promise;
  }

  patchInvoice(fields) {
    return this.invoicesResource.patch(fields).$promise;
  }

  deleteInvoice({ _id }) {
    return this.invoicesResource.delete({ id: _id }).$promise;
  }

  getPdf({ _id }) {
    return this.invoicesResource.getPdf({ id: _id }).$promise;
  }
}

angular.module('app.invoices').service('invoicesService', InvoicesService);

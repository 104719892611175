'use strict';

class CommentItemController {
  constructor(usersStore) {
    'ngInject';

    this.usersStore = usersStore;
  }

  $onInit() {
    this.dispose = mobx.autorun(() => {
      this.item.author = this.usersStore.resolveUserById(this.item.author);
      if (!this.item.author) return;
      this.canEdit = this.usersStore.currentUser._id === this.item.author._id;
    });
  }

  $onDestroy() {
    this.dispose();
  }

  handleRemove(item) {
    this.onRemove({
      $event: { itemId: item._id }
    });
  }
}

angular.module('components.comments').controller('CommentItemController', CommentItemController);

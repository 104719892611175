'use strict';

class DepartmentsManager {
  constructor($q, _, Department) {
    this.$q = $q;
    this._ = _;
    this.depResource = Department;
    this.departments = [];
  }

  loadAll() {
    return this.depResource.query(
      (deps) => {
        this.set(deps);
      },
      (error) => {
        // @TODO: implement logger
      }
    );
  }

  set(deps = []) {
    this.departments = deps;
  }

  getAll() {
    return this.departments;
  }

  getById(id) {
    return this._.find(this.departments, {_id: id});
  }

  getActive() {
    return this._.filter(this.departments, {active: true});
  }

  getDependants(deps, userDeps) {
    let dependats = [];

    userDeps.forEach((uDep) => {
      let dep = this._.find(deps, {_id: uDep});

      if (dep && this._.has(dep, 'dependants')) {
        dependats = [
          ...dependats,
          ...this._.map(dep.dependants, (depId) => {
            return this._.find(deps, {_id: depId});
          })
        ];
      }
    });

    return this._.uniq(dependats);
  }
}

angular.module('uvsApp').service('departmentsManager', DepartmentsManager);

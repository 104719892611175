'use strict';

class UsersService {
  constructor(User) {
    'ngInject';

    this.usersResource = User;
  }

  getUsers() {
    return this.usersResource.query().$promise;
  }

  getUser(id) {
    return this.usersResource.get({
      id: id
    }).$promise;
  }

  createUser(user) {
    return this.usersResource.save(user).$promise;
  }

  updateUser(user) {
    return this.usersResource.update(user).$promise;
  }
}

angular
  .module('app.users')
  .service('usersService', UsersService);

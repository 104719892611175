angular
  .module('app.estimates', [
    'app.products',
    'app.jwt',
    'app.deals',
    'helpers.businessCalculator',
    'angular-uuid'
  ])
  .config(function($stateProvider) {
    $stateProvider.state('root.estimates', {
      abstract: true,
      url: '/estimates',
      views: {
        content: {
          templateUrl: 'layouts/no-sidebars.html'
        }
      }
    });
  });

angular.module('uvsApp').directive('hasPermission', function(_, Auth) {
  return {
    scope: {
      any: '@'
    },
    link: function(scope, element, attrs) {
      if (!_.isString(attrs.hasPermission)) {
        throw new Error('hasPermission value must be a string');
      }

      var values = attrs.hasPermission.trim().split(' ');

      var matchAny = scope.any === 'true';

      function toggleVisibilityBasedOnPermission() {
        values = values.map((value) => {
          var notPermissionFlag = value[0] === '!';

          if (notPermissionFlag) {
            value = value.slice(1).trim();
          }

          var hasPermission = Auth.hasPermission(value);

          return (
            (hasPermission && !notPermissionFlag) ||
            (!hasPermission && notPermissionFlag)
          );
        });

        /**
         * If matchAny is true,
         * we show element when at least one permission is granted;
         * otherwise all permissions needs to be grantes
         */
        if (matchAny ? _.some(values) : _.every(values)) {
          element.show();
        } else {
          element.hide();
        }
      }

      toggleVisibilityBasedOnPermission();
      scope.$on('permissionsChanged', toggleVisibilityBasedOnPermission);
    }
  };
});

class SearchController {
  constructor($state) {
    'ngInject';

    this.$state = $state;
    this.searchQ = '';
  }

  search() {
    if (this.searchQ && this.searchQ.length > 0) {
      this.$state.go('root.orders.search', {q: this.searchQ});
      this.searchQ = '';
    }
  }
}

angular
  .module('components.portfolio')
  .controller('SearchController', SearchController);

'use strict';

class ActivityController {
  constructor(engagementsStore) {
    'ngInject';

    this.engagementsStore = engagementsStore;
    this.lastUpdate = this._getTime();
  }

  $onChanges(changes) {
    if (changes.target && changes.target.currentValue) {
      this.engagementsStore.setTarget(this.target._id);
    }
  }

  onCreate() {
    this.lastUpdate = this._getTime();
  }

  _getTime() {
    return new Date().getTime();
  }
}

angular.module('components.activity').controller('ActivityController', ActivityController);

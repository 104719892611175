'use strict';

angular.module('uvsApp')
  .directive('hasPlugin', function(Auth) {
    return {
      link: function(scope, element, attrs) {
        if (!_.isString(attrs.hasPlugin)) {
          throw new Error('hasPlugin value must be a string');
        }

        // var plugin = attrs.hasPlugin.trim();

        function toggleVisibilityBasedOnPlugin() {
          var tenant = Auth.getTenant();

          if (tenant === 'adsystems') {
            element.show();
          } else {
            element.hide();
          }
        }

        toggleVisibilityBasedOnPlugin();
        // scope.$on('permissionsChanged', toggleVisibilityBasedOnPermission);
      }
    };
  });

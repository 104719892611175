'use strict';

class PipelinesService {
  constructor(pipelinesResource) {
    'ngInject';

    this.pipelinesResource = pipelinesResource;
  }

  getPipelines() {
    return this.pipelinesResource.query().$promise;
  }

  getPipeline(id) {
    return this.pipelinesResource.get({id: id}).$promise;
  }

  updatePipeline(pipeline) {
    return this.pipelinesResource.update(pipeline).$promise;
  }
}

angular
  .module('components.pipelines')
  .service('pipelinesService', PipelinesService);

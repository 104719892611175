'use strict';

angular
  .module('uvsApp')

  .controller('DepartmentSettingsController', function($scope, moment, _) {
    var vm = this;

    vm.$onInit = function() {
      vm.departmentSettings = vm.departmentSettings || {};

      if (vm.task) {
        if (vm.task.assignee && vm.task.assignee.length) {
          vm.departmentSettings.worker = _.find(vm.workers, {
            _id: vm.task.assignee[0]._id
          });
        }

        if (vm.task.startDate) {
          vm.startDate = new Date(vm.task.startDate);
        }

        if (vm.task.endDate) {
          vm.endDate = new Date(vm.task.endDate);
        }
      }
    };

    vm.openStartDatePicker = function() {
      vm.isStartDatepickerOpen = true;
    };

    vm.openEndDatePicker = function() {
      vm.isEndDatepickerOpen = true;
    };

    vm.submit = function(valid) {
      $scope.$broadcast('show-errors-check-validity');

      if (!valid) {
        return;
      }
      setDates();
      vm.cb();
    };

    function setDates() {
      var startDate = new moment(vm.startDate);
      var endDate = new moment(vm.endDate);

      vm.departmentSettings.start = {
        date: startDate.format('YYYY-MM-DD'),
        time: startDate.format('HH:mm')
      };

      vm.departmentSettings.end = {
        date: endDate.format('YYYY-MM-DD'),
        time: endDate.format('HH:mm')
      };
    }
  });

'use strict';

class CommentFormController {
  constructor() {
    'ngInject';
  }

  onKeyPress(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();

      this.onSubmit({ $event: { text: this.text } });
      this.text = '';
    }
  }
}

angular.module('components.comments').controller('CommentFormController', CommentFormController);

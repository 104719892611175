class DealsController {
  constructor(dealsStore, Modal, localStorageService, Auth) {
    'ngInject';

    this.dealsStore = dealsStore;
    this.Modal = Modal;
    this.Auth = Auth;
    this.localStorageService = localStorageService;

    this.onRemove = this.Modal.confirm.delete(deal => {
      dealsStore.deleteDeal(deal);
    });
  }

  $onInit() {
    this._setShowAll();

    this._setFiltersAndFetch({
      byStageId: this.stage,
      onlyArchived: this.archived,
      showAll: !this.showOnlyMyDeals
    });

    this.dealsStore.fetchCustomers();

    this.dispose = mobx.autorun(() => {
      this.deals = this.dealsStore.deals;
      this.isLoading = this.dealsStore.isLoading;
      this.totalItems = this.dealsStore.totalItems;
      this.customers = this.dealsStore.customers;
      this.filters = this.dealsStore.filters;

      this.searchText = this.filters.bySearchTerm;
    });
  }

  $onDestroy() {
    this.dispose();
  }

  _setShowAll() {
    const hasPermission = this.Auth.hasPermission('dealsViewAll');

    if (!hasPermission) {
      this.showOnlyMyDeals = true;
      return;
    }

    const showOnlyMyDeals = this.localStorageService.get('showOnlyMyDeals');
    this.showOnlyMyDeals = showOnlyMyDeals != null ? showOnlyMyDeals : false;
  }

  _setFiltersAndFetch(filters) {
    this.dealsStore.setFilters(filters);
    this._fetchDeals();
  }

  _fetchDeals() {
    this.isLoading = true;
    this.dealsStore.fetchDeals().then(() => {
      this.isLoading = false;
    });
  }

  onSortChange(sortOptions) {
    const { field, direction } = sortOptions;

    this.sortType = field;
    this.sortDirection = direction;

    this._setFiltersAndFetch({ field, direction });
  }

  handleSearchChange() {
    if (this.searchText && this.searchText.length < 3) return;
    this._setFiltersAndFetch({ bySearchTerm: this.searchText, page: 1 });
  }

  handleFilterChange() {
    this._setFiltersAndFetch();
  }

  handleMyFilterChange({ selected }) {
    this.localStorageService.set('showOnlyMyDeals', selected);
    this._setFiltersAndFetch({ showAll: !selected });
  }

  handlePageChange() {
    this._fetchDeals();
  }

  onSubmit(isValid) {
    if (!isValid) {
      return;
    }
  }
}

angular.module('app.deals').controller('DealsController', DealsController);

class DateTimePickerController {
  constructor(moment) {
    'ngInject';

    this.moment = moment;
  }

  $onInit() {
    this.showTime = this.showTime === undefined ? true : this.showTime;
  }

  $onChanges(changes) {
    if (changes.date && changes.date.currentValue) {
      if (this.moment.isMoment(changes.date.currentValue)) {
        this.date = changes.date.currentValue.toDate();
      }
      this.timeLabel = this._getTimeLabel(changes.date.currentValue);
    }
  }

  onChange() {
    const offset = this.moment(this.date).utcOffset();

    this.onSelect({
      $event: {
        moment: this.moment
          .utc(this.date)
          .add(offset, 'm')
          .hours(0)
          .minutes(0)
          .seconds(0)
          .add(this.time, 'm')
      }
    });
  }

  onTimeSelected(duration) {
    this.time = duration;
    this.onChange();
  }

  _getTimeLabel(date) {
    const mmnt = this.moment.utc(date);
    // const now = this.moment();

    if (!mmnt.hours() && !mmnt.minutes()) {
      // mmnt.hours(now.hours() + 1).minutes();
      return;
    }

    return mmnt.format('HH:mm');
  }
}

angular
  .module('components.date-time-picker')
  .controller('DateTimePickerController', DateTimePickerController);

'use strict';

function Product(product) {
  Object.assign(this, product);
}

Product.prototype.enableRecipe = function () {
  this.recipe = {
    ingredients: []
  };
};


angular
  .module('app.products')
  .factory('Product', () => Product);

'use strict';

class ProductsService {
  constructor(productsResource) {
    'ngInject';

    this.productsResource = productsResource;
  }

  getProduct(id) {
    return this.productsResource.get({
      id: id
    }).$promise;
  }

  getByDealId(did) {
    return this.productsResource.query({
      deal: did
    }).$promise;
  }

  search(q) {
    return this.productsResource.query({ q }).$promise;
  }

  save(product) {
    if (product._id) {
      return this.update(product);
    }

    return this.create(product);
  }

  create(product) {
    return this.productsResource.save(product).$promise;
  }

  update(product) {
    return this.productsResource.update(product).$promise;
  }
}

angular.module('app.products').service('productsService', ProductsService);

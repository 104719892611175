'use strict';

angular
  .module('app.operations', [])
  .config($stateProvider => {
    $stateProvider.state('root.operations', {
      abstract: true,
      url: '/operations',
      views: {
        content: {
          templateUrl: 'layouts/with-left-sidebar.html'
        },
        'sidebar@root.operations': {
          templateUrl: 'components/sidebar/sidebar-settings.html'
        }
      }
    });
  })
  .run(operationsStore => operationsStore.fetchOperations());

'use strict';

angular.module('uvsApp')
  .directive('usedMaterials', function () {
    return {
      templateUrl: 'app/directives/usedMaterials/usedMaterials.html',
      restrict: 'E',
      scope: {
        items: '=',
        dep: '@',
        oid: '@'
      },
      replace: true,
      controller: 'UsedMaterialsCtrl as ctrl',
      bindToController: true
    };
  });

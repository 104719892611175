class EditableFieldController {
  constructor() {
    'ngInit';

    this.isActive = false;
  }

  handleActive() {
    this.isActive = true;
  }

  handleCancel() {
    this.isActive = false;
  }

  handleSubmit(event) {
    console.log('handleSubmit', event);
    this.onSave({
      $event: { value: 'value' }
    });
    this.isActive = false;
  }
}

angular
  .module('uvsApp')
  .controller('EditableFieldController', EditableFieldController);

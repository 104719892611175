'use strict';

angular
  .module('uvsApp')
  .directive('refresher', function() {
    return {
      transclude: true,
      controller: function($scope, $transclude, $attrs, $element) {
        let childScope;

        $scope.$watch($attrs.condition, (value) => {
          $element.empty();
          if (childScope) {
            childScope.$destroy();
            childScope = null;
          }

          $transclude((clone, newScope) => {
            childScope = newScope;
            $element.append(clone);
          });
        });
      }
    };
  });

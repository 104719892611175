class UserBadgeController {
  constructor(usersStore) {
    'ngInject';

    this.store = usersStore;
  }

  $onChanges(changes) {
    if (changes.uid) {
      this.user = this.getUser(changes.uid.currentValue);
    }
  }

  getUser(id) {
    const defaultUser = {
      name: 'Nepriskirta'
    };

    if (!id) {
      return defaultUser;
    }

    const user = this.store.getById(id);

    return user ? user : defaultUser;
  }
}

angular
  .module('components.user-badge')
  .controller('UserBadgeController', UserBadgeController);

class InventoryItemSelectorController {
  constructor(
    $log,
    Inventory,
    INGREDIENT_TYPE,
    operationsStore,
    productsService
  ) {
    'ngInject';

    this.$log = $log;
    this.Inventory = Inventory;
    this.operationsStore = operationsStore;
    this.productsService = productsService;
    this.INGREDIENT_TYPE = INGREDIENT_TYPE;
  }

  getItems(query) {
    return Promise.all([
      this.Inventory.search({query}).$promise,
      this._getOperations(query),
      this.productsService.search(query)
    ])
      .then(([materialas, operations, products]) => {
        materialas.map((material, index) => {
          material.ingredientType = this.INGREDIENT_TYPE.MATERIAL;
          if (index === 0) {
            material.group = 'Medžiagos';
          }
        });

        if (operations.length) {
          operations[0].group = 'Operacijos';
        }

        products.map((product, index) => {
          product.ingredientType = this.INGREDIENT_TYPE.PRODUCT;
          if (index === 0) {
            product.group = 'Produktai';
          }
        });

        return [...materialas, ...operations, ...products];
      })
      .catch((error) => this.$log(error));
  }

  handleChange() {
    this.handleSelect({
      name: this.item
    });
  }

  handleSelect(item) {
    this.onSelect({
      $event: {
        item
      }
    });
  }

  _getOperations(query) {
    return Promise.resolve(
      this.operationsStore.search(query).map((op) => {
        return Object.assign({}, op, {
          ingredientType: this.INGREDIENT_TYPE.OPERATION,
          purchasePrice: op.costPerUnit
        });
      })
    );
  }
}

angular
  .module('app.inventoryItemSelector')
  .controller(
    'InventoryItemSelectorController',
    InventoryItemSelectorController
  );

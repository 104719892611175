'use strict';

angular.module('uvsApp')
  .filter('duration', function() {
    return function(duration) {
      var hours = Math.floor(duration / 60);
      var minutes = duration % 60;
      var output = [];

      if (hours) {
        output.push(hours + ' h ');
        output.push(minutes + ' min');
      } else {
        output.push(duration + ' min');
      }

      return output.join(' ');
    };
  });

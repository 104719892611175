'use strict';

angular.module('uvsApp')

  .controller('TemplatesListCtrl', function ($scope, $stateParams, templates, Modal, Template, $location, $state) {
      $scope.templates = templates;

      $scope.remove = Modal.confirm.delete(function (template) {
        Template.delete({id: template._id}).$promise
          .then(function() {
            $state.go($state.current, $state.params, {reload: true});
          });
      });

      $scope.edit = function () {
        $location.path('templates/edit/' + this.template._id);
      };
  });

class CompanyCreateModalController {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.company = this.resolve.company;
  }

  onSubmit(customer) {
    this.close({
      $value: customer
    });
  }
}

angular
  .module('components.company-create-modal')
  .controller('CompanyCreateModalController', CompanyCreateModalController);

class PipelineDetailsController {
  constructor($scope, _) {
    'ngInject';

    this._ = _;
    this.$scope = $scope;
  }

  addStage() {
    this.pipeline.stages.push({
      label: ''
    });
  }

  removeStage(stage) {
    const index = this._.indexOf(this.pipeline.stages, stage);

    if (index === -1) {
      return;
    }

    this.pipeline.stages.splice(index, 1);
  }

  onSubmit(isValid) {
    this.$scope.$broadcast('show-errors-check-validity');

    if (!isValid) {
      return;
    }

    this.onSave({
      $event: {
        pipeline: this.pipeline
      }
    });
  }
}

angular
  .module('components.pipelines')
  .controller('PipelineDetailsController', PipelineDetailsController);

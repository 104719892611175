class EstimateWidgetController {
  constructor(
    $state,
    $window,
    JWTService,
    dealsService,
    estimatesService,
    estimatesStore,
    toastr,
    fileDownloadService
  ) {
    'ngInject';

    this.$state = $state;
    this.$window = $window;
    this.jwt = JWTService;
    this.dealsService = dealsService;
    this.estimatesService = estimatesService;
    this.estimatesStore = estimatesStore;
    this.toastr = toastr;
    this.fileDownloadService = fileDownloadService;
  }

  $onInit() {
    this.dispose = mobx.autorun(() => {
      this.isDownloading = this.estimatesStore.downloading;
    });
  }

  $onDestroy() {
    this.dispose();
  }

  onEstimateDetails() {
    this.$state.go('root.estimates.details', {
      id: this.estimate ? this.estimate._id : null,
      did: this.deal._id
    });
  }

  onDownload(type) {
    this.toastr.info('Dokumentas siunčiamas', 'Siunčiama', { timeOut: 5000 });

    this.estimatesService.getPdf(this.estimate._id, type).then(({ data, filename }) => {
      this.fileDownloadService.download({ data, filename });
    });
  }

  onDownloadInvoice() {
    if (!this.deal.company) {
      this.toastr.warning('Pasirinkite įmonę, kuriai išrašysite sąskaitą.', 'Dėmesio');
      return;
    }

    this.onDownloadInvoice();
  }
}

angular.module('app.estimates').controller('EstimateWidgetController', EstimateWidgetController);

'use strict';

// ATTENSION!!!!
// USE statusLabel INSTEAD

angular
  .module('uvsApp')
  .directive('orderStatus', function() {
    return {
      templateUrl: 'app/directives/order-status/order-status.html',
      restrict: 'E',
      controller: 'OrderStatusController',
      controllerAs: 'ctrl',
      bindToController: {
        order: '='
      }
    };
  })
  .controller('OrderStatusController', function() {
    var vm = this;
    var orderStatuses = {
      inactive: {
        name: 'Neaktyvus',
        css: 'danger'
      },
      active: {
        name: 'Aktyvus',
        css: 'warning'
      },
      completed: {
        name: 'Atliktas',
        css: 'success'
      },
      closed: {
        name: 'Užbaigtas',
        css: 'info'
      }
    };

    vm.$onInit = function() {
      if (!vm.order.active) {
        setProperties(orderStatuses.inactive);
      } else if (vm.order.completed && !vm.order.closed) {
        setProperties(orderStatuses.completed);
      } else if (vm.order.closed) {
        setProperties(orderStatuses.closed);
      } else {
        setProperties(orderStatuses.active);
      }
    };

    function setProperties(options) {
      vm.css = 'label-' + options.css;
      vm.name = options.name;
    }
  });

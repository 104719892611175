const toggleButton = {
  template: `
    <button
      class="btn toggle-button"
      ng-class="{'btn-default': !$ctrl.selected, 'btn-primary': $ctrl.selected}"
      type="button"
      ng-click="
        $ctrl.selected = !$ctrl.selected;
        $ctrl.onChange({ $event: { selected: $ctrl.selected} })
      "
    >
      <ng-transclude />
    </button>`,
  transclude: true,
  bindings: {
    selected: '<',
    onChange: '&'
  }
};

angular.module('uvsApp').component('toggleButton', toggleButton);

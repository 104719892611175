'use strict';

angular.module('uvsApp').directive('emptyToNull', function(_) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, elem, attrs, ctrl) {
      ctrl.$parsers.push(function(viewValue) {
        if (viewValue === '' || _.isNull(viewValue)) {
          return undefined;
        }
        return viewValue;
      });
    }
  };
});

'use strict';

angular
  .module('uvsApp')
  .controller('DepartmentsCtrl', function($scope, _, departmentsStore, Department) {
    var dispose = mobx.autorun(function() {
      $scope.departments = departmentsStore.activeDepartments;
      $scope.inactiveDepartments = departmentsStore.inactiveDepartments;
    });
    $scope.$on('$destroy', dispose);
    $scope.isOrderDirty = false;

    $scope.create = function() {
      Department.save($scope.newDep, function(resp) {
        $scope.departments.push(resp);
        $scope.newDep = {};
      });
    };

    $scope.deactivate = function(department) {
      departmentsStore.deleteDepartment(department._id);
    };

    $scope.sortableOptions = {
      orderChanged: function() {
        $scope.isOrderDirty = true;
      }
    };

    $scope.saveOrder = function() {
      Department.order(
        _.map($scope.departments, function(d) {
          return d._id;
        })
      );
      $scope.isOrderDirty = false;
    };
  })
  .controller('DepartmentFormCtrl', function(
    $scope,
    $state,
    department,
    usersStore,
    departmentsStore,
    titleService
  ) {
    function toList() {
      $state.go('root.departments.list', {}, { reload: true });
    }
    var saving;

    $scope.department = department;
    $scope.isEditMode = !!department;
    $scope.workers = usersStore.workers;

    if ($scope.isEditMode) {
      titleService.setTitle($scope.department.name);
    }

    $scope.toggleDefault = function(uid) {
      if ($scope.department.defaultUser === uid) {
        uid = null;
      }

      $scope.department.defaultUser = uid;
    };

    $scope.submit = function(form) {
      $scope.$broadcast('show-errors-check-validity');

      if (!form.$valid) {
        return;
      }

      if ($scope.department._id) {
        saving = departmentsStore.updateDepartment($scope.department);
      } else {
        saving = departmentsStore.createDepartment($scope.department);
      }

      saving.then(toList);
    };
  });

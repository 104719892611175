class Notification {
  constructor($state) {
    'ngInject';

    this.$state = $state;
  }

  onMarkClicked() {
    this.listCtrl.onMarkOne({
      $event: {
        notification: this.notify
      }
    });
  }

  onClick() {
    this.onMarkClicked();

    if (this.notify.type === 'SALES_TASK_ASSIGNED') {
      this.$state.go('root.deals.view', {
        id: this.notify.source._id
      });
    } else {
      this.$state.go('root.orders.view', {
        number: this.notify.source.label.split(' ')[0]
      });
    }
  }
}

angular.module('uvsApp').controller('NotificationController', Notification);

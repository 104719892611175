'use strict';

const search = {
  templateUrl: 'components/search/search.html',
  controller: 'SearchController'
};

angular
  .module('components.search')
  .component('search', search);

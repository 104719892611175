const customer = {
  templateUrl: 'app/customers/customer/customer.html',
  controller: 'CustomerController',
  bindings: {
    customer: '<',
    orders: '<'
  }
};

angular.module('app.customers').component('customer', customer);

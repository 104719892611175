class DepartmentsStore {
  constructor($log, departmentsService) {
    'ngInject';

    this.$log = $log;
    this.departmentsService = departmentsService;

    this.departments = [];

    this.getById = mobxUtils.createTransformer(did =>
      this.departments.find(dep => dep._id === did)
    );
  }

  // @action
  fetchDepartments() {
    return this.departmentsService
      .getDepartments()
      .then(department => this.setDepartments(department))
      .catch(err => this.$log.error(err));
  }

  createDepartment(data) {
    return this.departmentsService
      .createDepartment(data)
      .then(dep => this.setDepartment(dep))
      .catch(err => this.$log.error(err));
  }

  updateDepartment(department) {
    return this.departmentsService
      .updateDepartment(department)
      .then(dep => this.setDepartment(dep))
      .catch(err => this.$log.error(err));
  }

  deleteDepartment(did) {
    return this.departmentsService
      .deleteDepartment(did)
      .then(() => this.deactivateDepartment(did))
      .catch(err => this.$log.error(err));
  }

  setDepartments(data) {
    this.departments = data;
  }

  setDepartment(department) {
    const i = this.departments.findIndex(dep => dep._id === department._id);
    const newDeps = [...this.departments];

    if (i === -1) {
      newDeps.push(department);
    } else {
      newDeps[i] = department;
    }

    this.departments.replace(newDeps);
  }

  deactivateDepartment(id) {
    const department = this.departments.find(dep => dep._id === id);

    if (!department) {
      return;
    }

    department.active = false;

    this.setDepartment(department);
  }

  get activeDepartments() {
    return this.departments.filter(department => department.active);
  }

  get inactiveDepartments() {
    return this.departments.filter(department => !department.active);
  }
}

mobx.decorate(DepartmentsStore, {
  departments: mobx.observable,

  setDepartments: mobx.action,
  setDepartment: mobx.action,

  activeDepartments: mobx.computed,
  inactiveDepartments: mobx.computed
});

angular.module('uvsApp').service('departmentsStore', DepartmentsStore);

class ProfileSettingsController {
  constructor() {
    'ngInject';

    this.homePages = [
      { url: '/orders', label: 'Užsakymai' },
      { url: '/deals', label: 'Pardavimai' },
      { url: '/sales-tasks', label: 'Užduotys' }
    ];
  }

  onChanged() {
    this.onChange({
      $event: {
        settings: this.settings
      }
    });
  }
}

angular
  .module('components.profile')
  .controller('ProfileSettingsController', ProfileSettingsController);

class InventorySummaryController {
  constructor(Inventory) {
    'ngInject';

    this.isLoading = true;

    this.Inventory = Inventory;

    this.itemsCount = 0;
    this.purcahsePriceSum = 0;
    this.salesPriceSum = 0;
    this.lastSynced;
  }

  $onInit() {
    this.Inventory.getSummary().$promise.then(({ itemsCount, purcahsePriceSum, salesPriceSum }) => {
      this.itemsCount = itemsCount;
      this.purcahsePriceSum = purcahsePriceSum;
      this.salesPriceSum = salesPriceSum;
      this.lastSynced = new Date();

      this.isLoading = false;
    });
  }
}

angular.module('uvsApp').controller('InventorySummaryController', InventorySummaryController);

'use strict';

angular.module('uvsApp').config(function($stateProvider, APP_PERMISSIONS) {
  $stateProvider
    .state('root.inventories', {
      abstract: true,
      url: '/inventories',
      views: {
        content: {
          templateUrl: 'layouts/no-sidebars.html',
          permission: APP_PERMISSIONS.inventory
        }
      }
    })
    .state('root.inventories.list', {
      url: '',
      templateUrl: 'app/inventory/partials/list.html',
      controller: 'InventoryCtrl',
      title: 'Sandėlis',
      resolve: {
        inventories: function(Inventory) {
          return Inventory.query().$promise;
        }
      }
    })
    .state('root.inventories.new', {
      url: '/new',
      templateUrl: 'app/inventory/partials/form.html',
      controller: 'InventoryFormCtrl',
      title: 'Sandėlis',
      resolve: {
        inventory: function(Inventory) {
          return new Inventory();
        },
        categories: function(Inventory) {
          return Inventory.getCategories().$promise;
        }
      }
    })
    .state('root.inventories.edit', {
      url: '/:id/edit',
      templateUrl: 'app/inventory/partials/form.html',
      controller: 'InventoryFormCtrl',
      title: 'Sandėlis',
      resolve: {
        inventory: function(Inventory, $stateParams) {
          return Inventory.get({ id: $stateParams.id }).$promise;
        },
        categories: function(Inventory) {
          return Inventory.getCategories().$promise;
        }
      }
    });
});

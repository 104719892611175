'use strict';

angular.module('uvsApp')
  .directive('historyList', function () {
    return {
      templateUrl: 'app/directives/historyList/historyList.html',
      restrict: 'E',
      scope: {
        list: '='
      },
      controller: function () {
      },
      controllerAs: 'ctrl',
      bindToController: true
    };
  });

class SalesTasksService {
  constructor(_, engagementResource, usersStore) {
    'ngInject';

    this._ = _;
    this.engagementResource = engagementResource;
    this.usersStore = usersStore;
  }

  getTasks() {
    return this.engagementResource
      .query({ type: 'TASK' })
      .$promise.then(tasks =>
        // populate assignees
        tasks.map(task => {
          task.metadata.assignee = this.usersStore.resolveUserById(task.metadata.assigneeId);

          task.associatedWith = task.associations[0];
          return task;
        })
      )
      .then(tasks => this._.sortBy(tasks, 'metadata.dueDate'));
  }

  updateTask(task) {
    return this.engagementResource.update(task).$promise;
  }
}

angular.module('components.sales-tasks').service('salesTasksService', SalesTasksService);

'use strict';

class EstimatesService {
  constructor(estimatesResource, estimatesStore) {
    'ngInject';

    this.estimatesResource = estimatesResource;
    this.estimatesStore = estimatesStore;
  }

  getEstimate(id) {
    return this.estimatesResource.get({
      id: id
    }).$promise;
  }

  getByDealId(did) {
    return this.estimatesResource.query({
      deal: did
    }).$promise;
  }

  save(estimate) {
    if (estimate._id) {
      return this.update(estimate);
    }

    return this.create(estimate);
  }

  create(estimate) {
    return this.estimatesResource.save(estimate).$promise;
  }

  update(estimate) {
    return this.estimatesResource.update(estimate).$promise;
  }

  getPdf(estimateId, type) {
    this.estimatesStore.setDownloading(true);

    const promise =
      type === 'proposal'
        ? this.estimatesResource.getProposalPdf({ id: estimateId }).$promise
        : this.estimatesResource.getDetailedPdf({ id: estimateId }).$promise;

    promise.finally(() => this.estimatesStore.setDownloading(false));

    return promise;
  }
}

angular.module('app.estimates').service('estimatesService', EstimatesService);

const timeField = {
  templateUrl: 'components/time-field/time-field.html',
  controller: 'TimeFieldController',
  bindings: {
    hours: '<',
    label: '@',
    onChange: '&'
  }
};

angular.module('uvsApp').component('timeField', timeField);

'use strict';

class DealsService {
  constructor(_, dealsResource, invoicesService) {
    'ngInject';

    this._ = _;
    this.dealsResource = dealsResource;
    this.invoicesService = invoicesService;
  }

  getDeals(filters) {
    return this.dealsResource.query(filters).$promise;
  }

  getDeal(id) {
    return this.dealsResource.get({ id: id }).$promise;
  }

  createDeal(deal) {
    return this.dealsResource.save(deal).$promise;
  }

  updateDeal(deal) {
    return this.dealsResource.update(deal).$promise;
  }

  patchDeal(fields) {
    return this.dealsResource.patch(fields).$promise;
  }

  deleteDeal(deal) {
    return this.dealsResource.delete({ id: deal._id }).$promise;
  }

  changeStage(deal) {
    return this.dealsResource.changeStage(
      {
        id: deal._id
      },
      {
        stage: deal.currentStage
      }
    ).$promise;
  }

  getInvoices(dealId) {
    return this.invoicesService.search({ byParentIds: [dealId] });
  }

  generateInvoice({ _id }) {
    return this.dealsResource.generateInvoice({ id: _id }).$promise;
  }

  createInvoice(id, invoiceData) {
    return this.dealsResource.createInvoice({ id }, invoiceData).$promise;
  }

  patchInvoice(id, invoiceData) {
    const invoiceId = invoiceData._id;

    return this.dealsResource.patchInvoice({ id, iid: invoiceId }, this._.omit(invoiceData, '_id'))
      .$promise;
  }

  getCustomers() {
    return this.dealsResource.searchCustomers().$promise;
  }
}

angular.module('app.deals').service('dealsService', DealsService);

'use strict';

angular.module('uvsApp')
.controller('ItemsModalCtrl', function($scope, $uibModalInstance, item) {
  $scope.item = item;

  $scope.addItem = function(itemFrom) {
    $scope.$broadcast('show-errors-check-validity');

    if (!itemFrom.$valid) {
      return;
    }

    $uibModalInstance.close($scope.item);
  };
});

const eventSourceNoteComponent = {
  template: `<div ng-bind-html="$ctrl.note.metadata.body"></div>`,
  bindings: {
    note: '<source'
  }
};

angular
  .module('components.event')
  .component('eventSourceNote', eventSourceNoteComponent);

const productListExtended = {
  templateUrl: 'app/products/product-list-extended/product-list-extended.html',
  controller: 'ProductListExtendedController',
  bindings: {
    products: '<',
    onChange: '&',
    onRemove: '&',
    isCollapsed: '@'
  }
};

angular.module('app.products').component('productListExtended', productListExtended);

'use strict';

const companyInfoComponent = {
  templateUrl: 'app/companies/company-info/company-info.html',
  controller: 'CompanyInfoController',
  bindings: {
    company: '<',
    onChange: '&',
    onRemove: '&',
    isChangeable: '@'
  }
};

angular
  .module('components.companies')
  .component('companyInfo', companyInfoComponent);

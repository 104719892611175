'use strict';

angular.module('uvsApp').config(function($stateProvider) {
  $stateProvider
    .state('root.orders', {
      abstract: true,
      url: '/orders',
      title: 'Užsakymai',
      views: {
        content: {
          templateUrl: 'layouts/with-left-sidebar.html'
        },
        'sidebar@root.orders': 'ordersSidebar'
      }
    })

    .state('root.orders.completed', {
      url: '/completed',
      templateUrl: 'app/orders/partials/orders-completed.html',
      controller: 'OrdersCompletedCtrl',
      authenticate: true,
      title: 'Atlikti užsakymai',
      resolve: {
        orders: function(ordersRepository) {
          return ordersRepository.getOrders({
            type: 'closed'
          });
        },
        users: function(ordersManager) {
          return ordersManager.getUsers({ type: 'closed' });
        }
      }
    })

    .state('root.orders.search', {
      url: '/search?q',
      templateUrl: 'app/orders/partials/list-search.html',
      controller: 'OrdersSearchCtrl',
      authenticate: true,
      resolve: {
        orders: function(Order, $stateParams) {
          if (!$stateParams.q) {
            return [];
          }
          return Order.search({ q: $stateParams.q }).$promise;
        },
        departments: function(departmentsStore) {
          return departmentsStore.activeDepartments;
        }
      }
    })

    .state('root.orders.new', {
      url: '/new',
      templateUrl: 'app/orders/partials/form.html',
      controller: 'OrderFormCtrl',
      authenticate: true,
      title: 'Naujas gamybos užsakymas',
      params: {
        deal: null
      },
      resolve: {
        order: function(Order, $stateParams) {
          return new Order({ active: true, deal: $stateParams.deal });
        },
        orderNumber: function($http, API_URL) {
          return $http.get(API_URL + '/orders/new').then(function(resp) {
            return resp.data;
          });
        },
        customers: function(Customer) {
          return Customer.query().$promise;
        },
        departments: function(departmentsStore) {
          return departmentsStore.activeDepartments;
        }
      }
    })
    .state('root.orders.view', {
      url: '/:number',
      templateUrl: 'app/orders/partials/order.html',
      controller: 'OrderViewCtrl',
      authenticate: true,
      resolve: {
        order: function(ordersManager, $stateParams) {
          return ordersManager.getByNumber($stateParams.number);
        },
        dependants: function(departments, departmentsManager, usersStore) {
          var dispose;
          return new Promise(function(resolve, reject) {
            dispose = mobx.autorun(function() {
              var currentUser = usersStore.currentUser;

              if (currentUser && currentUser.departments) {
                resolve(currentUser);
              }
            });
          })
            .then(function(currentUser) {
              return departmentsManager.getDependants(departments, currentUser.departments);
            })
            .finally(function() {
              dispose();
            });
        },
        invoices: function(Invoice, order) {
          return Invoice.query({ order: order._id }).$promise;
        },
        departments: function(departmentsStore) {
          return departmentsStore.activeDepartments;
        }
      }
    })

    .state('root.orders.edit', {
      url: '/:id/edit',
      templateUrl: 'app/orders/partials/form.html',
      controller: 'OrderFormCtrl',
      authenticate: true,
      resolve: {
        order: function(ordersManager, $stateParams) {
          return ordersManager.getOrder($stateParams.id);
        },
        orderNumber: function() {
          return null;
        },
        customers: function(Customer) {
          return Customer.query().$promise;
        },
        departments: function(departmentsStore) {
          return departmentsStore.activeDepartments;
        }
      }
    })
    .state('root.orders.list', {
      url: '?type&dep&ready',
      templateUrl: 'app/orders/partials/list.html',
      controller: 'OrdersCtrl',
      authenticate: true,
      title: 'Užsakymai',
      resolve: {
        orders: function(ordersRepository, $stateParams, localStorageService) {
          var type = $stateParams.type || 'active';
          if ($stateParams.dep) {
            type = undefined;
          }

          return ordersRepository.getOrders({
            type: type,
            dep: $stateParams.dep,
            ready: localStorageService.get('showReady', false)
          });
        },
        departments: function(departmentsStore) {
          return departmentsStore.activeDepartments;
        },
        users: function(ordersManager) {
          return ordersManager.getUsers();
        }
      }
    });
});

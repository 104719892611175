'use strict';

angular.module('uvsApp')
  .factory('employmentInfo', function($rootScope, $q, ordersManager) {
    var factory = {};
    var activeTask;
    var activeOrder;

    factory.show = function(task) {
      setActiveTask(task);

      $rootScope.$broadcast('calendar-panel:open');
    };

    factory.getInfo = function() {
      var defer = $q.defer();

      // getOrder(activeTask.orderId)
      //   .then(function(order) {
          defer.resolve({
            task: activeTask,
            // order: order
          });
        // });

      return defer.promise;
    };

    function setActiveTask(task) {
      activeTask = task;
    }
    function getOrder(oid) {
      var defer = $q.defer();

      if (activeOrder && activeOrder._id === oid) {
        defer.resolve(activeOrder);
      } else {
        ordersManager.getOrder(oid).then(function(order) {
          defer.resolve(order);
          activeOrder = order; // @TODO: <---- very bad - refactor :)
        });
      }

      return defer.promise;
    }

    return factory;
  });

// 'use strict';
//
// angular.module('uvsApp')
//   .factory('Task', function() {
//     function Task(taskData) {
//       /* Defaults */
//       this.status = 0;
//       this.assignee = [];
//
//       if (taskData) {
//         this.setData(taskData);
//       }
//       // Some other initializations related to task
//     }
//
//     Task.prototype = {
//       setData: function(taskData) {
//         angular.extend(this, taskData);
//       }
//     };
//
//     return Task;
//   });

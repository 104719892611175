const operationEdit = {
  templateUrl: 'app/operations/operation-edit/operation-edit.html',
  controller: 'OperationEditController',
  bindings: {
    operationId: '@'
  }
};

angular
  .module('app.operations')
  .component('operationEdit', operationEdit)
  .config(function($stateProvider) {
    $stateProvider.state('root.operations.edit', {
      url: '/:id/edit',
      component: 'operationEdit',
      resolve: {
        operationId: ($transition$) => $transition$.params().id
      }
    });
  });

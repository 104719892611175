'use strict';

const pipelineComponent = {
  templateUrl: 'components/pipeline/pipeline.html',
  controller: 'PipelineController',
  bindings: {
    activeStage: '<',
    onStageChange: '&'
  }
};

angular
  .module('components.pipeline')
  .component('pipeline', pipelineComponent);

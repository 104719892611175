class ProfileController {
  constructor(toastr, usersStore) {
    'ngInject';

    this.toastr = toastr;
    this.usersStore = usersStore;
  }

  $onInit() {
    this.dispose = mobx.autorun(() => {
      this.user = this.usersStore.currentUser;
    });
  }

  $onDestroy() {
    this.dispose();
  }

  onSettingsChanged(event) {
    if (!event) {
      return;
    }

    Object.assign(this.user, event);

    this.user.$update(
      () => this.toastr.success('Nustatymai išsaugoti'),
      () => this.toastr.error('Užsaugoti nepavyko')
    );
  }
}

angular.module('components.profile').controller('ProfileController', ProfileController);

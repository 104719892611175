class BusinessCalculator {
  constructor(number) {
    'ngInject';

    this.round = number.roundDecimal;
  }

  calculateRevenue(cost = 0, markup = 0) {
    return this.round(cost + (cost * markup) / 100, 3);
  }

  calculateMarkup(cost = 0, revenue = 0) {
    return this.round((100 * (revenue - cost)) / cost, 3);
  }
}

angular
  .module('helpers.businessCalculator')
  .service('businessCalculator', BusinessCalculator);

'use strict';

angular.module('uvsApp').config(function($stateProvider) {
  $stateProvider
    .state('root.writeoffs', {
      abstract: true,
      url: '/writeoffs',
      views: {
        content: {
          template: '<div ui-view></div>'
        }
      }
    })
    .state('root.writeoffs.list', {
      url: '',
      templateUrl: 'app/writeoffs/writeoffs.html',
      controller: 'WriteoffsCtrl',
      controllerAs: '$ctrl',
      authenticate: true,
      resolve: {
        writeoffs: function(WriteOffs) {
          return WriteOffs.query().$promise;
        }
      }
    });
});

const companySelectModal = {
  templateUrl: 'components/company-select-modal/company-select-modal.html',
  controller: 'CompanySelectModalController',
  bindings: {
    close: '&',
    dismiss: '&'
  }
};

angular
  .module('components.company-select-modal')
  .component('companySelectModal', companySelectModal);

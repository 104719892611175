'use strict';

const dealDetails = {
  templateUrl: 'app/deals/deal-details/deal-details.html',
  controller: 'DealDetailsController',
  bindings: {
    deal: '<',
    onSave: '&'
  }
};

angular.module('app.deals').component('dealDetails', dealDetails);

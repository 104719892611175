'use strict';

angular.module('uvsApp').factory('Report', function($resource, API_URL) {
  var Report = $resource(
    API_URL + '/reports/task-completion-times',
    {},
    {
      update: {
        method: 'PUT'
      }
    }
  );

  return Report;
});

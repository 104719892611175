const collapsiblePanel = {
  templateUrl: 'components/collapsible-panel/collapsible-panel.html',
  controller: 'CollapsiblePanelController',
  transclude: true,
  bindings: {
    title: '@',
    name: '@'
  }
};

angular
  .module('components.collapsible-panel')
  .component('collapsiblePanel', collapsiblePanel);

class PriceMarginControlController {
  constructor(businessCalculator) {
    'ngInject';

    this.calculator = businessCalculator;
  }

  $onInit() {
    this.markup = this.markup || 0;

    if (this.revenue == undefined) {
      this.revenue = this.cost;
      // this.ngModelCtrl.$setViewValue(this.cost);
    }

    this._setMarkup();
  }

  $onChanges(changes) {
    if (changes.hasOwnProperty('cost') && !changes.cost.isFirstChange()) {
      if (this.revenue === undefined) {
        this.revenue = changes.cost.currentValue;
        this.ngModelCtrl.$setViewValue(changes.cost.currentValue);
      }

      if (this.ngModelCtrl.$modelValue != null) {
        this.onRevenueChange();
      } else {
        this.onMarginChange();
      }
    }

    if (changes.hasOwnProperty('revenue')) {
      this.onRevenueChange();
    }
  }

  onMarginChange() {
    this.ngModelCtrl.$setViewValue(
      this.calculator.calculateRevenue(this.cost, this.markup)
    );
  }

  onRevenueChange() {
    this._setMarkup();
    this.ngModelCtrl.$setViewValue(this.revenue);
  }

  _setMarkup() {
    this.markup = this.calculator.calculateMarkup(this.cost, this.revenue);
  }
}

angular
  .module('components.priceMarginControl')
  .controller('PriceMarginControlController', PriceMarginControlController);

const engagementEditor = {
  templateUrl: 'components/activity/engagement-editor/engagement-editor.html',
  controller: 'EngagementEditorController',
  transclude: true,
  bindings: {
    target: '<',
    active: '<',
    targetType: '@',
    onCreateAny: '&'
  }
};

angular.module('components.activity').component('engagementEditor', engagementEditor);

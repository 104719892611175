/* eslint new-cap:0 */
'use strict';

angular
  .module('uvsApp')
  .controller('EmploymentCtrl', function(
    tasks,
    moment,
    EmploymentRepository,
    _,
    TaskRepository,
    localStorageService,
    $compile,
    $scope,
    employmentInfo,
    employmentHelpers,
    users,
    employmentOrder
  ) {
    var vm = this;

    var workers =
      employmentOrder && employmentOrder.length
        ? users.filter(function(user) {
            return employmentOrder.includes(user._id);
          })
        : users;

    _.forEach(tasks, function(task) {
      task.start = moment(task.start);
      task.end = moment(task.end);
      task.classes = 'important-' + task.importance;
    });

    vm.tasks = tasks;
    vm.activeTask = 'aktyvi';

    var Calendar = {
      Periods: [
        {
          Name: '3 days',
          Label: '3 dienos',
          TimeframePeriod: 60 * 3,
          TimeframeOverall: 60 * 24 * 3,
          TimeframeHeaders: ['MMMM D', 'HH'],
          Classes: 'period-3day'
        },
        {
          Name: '1 week',
          Label: '1 savaitė',
          StartOf: 'week',
          TimeframePeriod: 60 * 24,
          TimeframeOverall: 60 * 24 * 7,
          TimeframeHeaders: ['MMMM', 'D'],
          Classes: 'period-1week'
        },
        {
          Name: '2 weeks',
          Label: '2 savaitės',
          StartOf: 'week',
          TimeframePeriod: 60 * 24,
          TimeframeOverall: 60 * 24 * 14,
          TimeframeHeaders: ['MMMM', 'D'],
          Classes: 'period-1week'
        },
        {
          Name: '1 month',
          Label: '1 mėnuo',
          StartOf: 'month',
          TimeframePeriod: 60 * 24 * 1,
          TimeframeOverall: 60 * 24 * 28,
          TimeframeHeaders: ['MMMM', 'D'],
          Classes: 'period-1month'
        }
      ],

      Items: tasks,

      Sections: _.chain(workers)
        .filter(function(worker) {
          return worker.settings && worker.settings.showInEmployment;
        })
        .map(function(worker) {
          return {
            id: worker._id,
            name: worker.name
          };
        })
        .value(),

      Init: function() {
        TimeScheduler.Options.GetSections = Calendar.GetSections;
        TimeScheduler.Options.GetSchedule = Calendar.GetSchedule;
        TimeScheduler.Options.Periods = Calendar.Periods;
        TimeScheduler.Options.SelectedPeriod = '2 weeks';

        TimeScheduler.Options.Start = moment().startOf('week');

        TimeScheduler.Options.Element = $('.calendar');
        TimeScheduler.Options.HeaderFormat = 'YYYY MMMM D';

        TimeScheduler.Options.AllowDragging = true;
        TimeScheduler.Options.AllowResizing = true;

        TimeScheduler.Options.Events.DateClicked = Calendar.Date_Clicked;

        TimeScheduler.Options.Events.ItemClicked = Calendar.Item_Clicked;
        TimeScheduler.Options.Events.ItemDblClicked = Calendar.Item_DblClicked;
        TimeScheduler.Options.Events.ItemDropped = Calendar.Item_Dragged;
        TimeScheduler.Options.Events.ItemResized = Calendar.Item_Resized;

        TimeScheduler.Options.Events.ItemMovement = Calendar.Item_Movement;
        TimeScheduler.Options.Events.ItemMovementStart = Calendar.Item_MovementStart;
        TimeScheduler.Options.Events.ItemMovementEnd = Calendar.Item_MovementEnd;

        TimeScheduler.Options.Text.NextButton = '&nbsp;';
        TimeScheduler.Options.Text.PrevButton = '&nbsp;';

        TimeScheduler.Options.MaxHeight = 100;

        TimeScheduler.Init();
      },

      GetSections: function(callback) {
        callback(Calendar.Sections);
      },

      GetSchedule: function(callback, start, end) {
        callback(Calendar.Items);
      },

      Date_Clicked: function(date) {
        var selectedDate = TimeScheduler.Options.Start.clone().add(date.timeCount, 'day');
        var selectedSection = _.find(Calendar.Sections, { id: date.sectionId });

        EmploymentRepository.createTask({
          date: selectedDate,
          section: selectedSection
        }).then(function(task) {
          task.start = moment(task.start);
          task.end = moment(task.end);

          Calendar.Items.push(task);
          TimeScheduler.Init();
        });
      },

      Item_Clicked: function(item) {
        employmentInfo.show(_.find(Calendar.Items, { id: item.id }));
        $scope.$apply();
      },

      Item_DblClicked: function(item) {
        var task = employmentHelpers.employmentToTask(item);
        var orderId = item.orderId;

        TaskRepository.updateTask(task, orderId).then(function(updatedTask) {
          if (!updatedTask) {
            // delete item
            deleteItem(item);
          } else {
            updatedTask = employmentHelpers.taskToEmployment(updatedTask);
            // update item
            _.merge(item, updatedTask);
          }

          TimeScheduler.Init();
        });
      },

      Item_Dragged: function(item, sectionID, start, end) {
        var foundItem;

        // Calendar updates dates directly in the item, but not sectionID
        item.sectionID = sectionID;

        EmploymentRepository.updateTask(item);

        for (var i = 0; i < Calendar.Items.length; i++) {
          foundItem = Calendar.Items[i];

          if (foundItem.id === item.id) {
            foundItem.sectionID = sectionID;
            foundItem.start = start;
            foundItem.end = end;

            Calendar.Items[i] = foundItem;
          }
        }

        TimeScheduler.Init();
      },

      Item_Resized: function(item, start, end) {
        var foundItem;

        EmploymentRepository.updateTask(item);

        for (var i = 0; i < Calendar.Items.length; i++) {
          foundItem = Calendar.Items[i];

          if (foundItem.id === item.id) {
            foundItem.start = start;
            foundItem.end = end;

            Calendar.Items[i] = foundItem;
          }
        }

        TimeScheduler.Init();
      },

      Item_Movement: function(item, start, end) {
        // var html;
        //
        // html =  '<div>';
        // html += '   <div>';
        // html += '       Start: ' + start.format('Do MMM YYYY HH:mm');
        // html += '   </div>';
        // html += '   <div>';
        // html += '       End: ' + end.format('Do MMM YYYY HH:mm');
        // html += '   </div>';
        // html += '</div>';
        // console.log(item);
        // $('.realtime-info').empty().append(html);

        item.start = start;
        item.end = end;

        $scope.$apply();
      },

      Item_MovementStart: function() {
        console.log('movement-dtart');
        vm.showInfo = true;
        $scope.$apply();
      },

      Item_MovementEnd: function() {
        console.log('movement-end');
        vm.showInfo = false;
        $scope.$apply();
      }
    };

    Calendar.Init();

    function deleteItem(item) {
      var index = _.findIndex(Calendar.Items, item);

      if (index < 0) {
        return;
      }

      Calendar.Items.splice(index, 1);
    }
  });

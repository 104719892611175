angular.module('app.products').factory('productsResource', function($resource, API_URL) {
  return $resource(
    `${API_URL}/products/:id`,
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      }
    }
  );
});

'use strict';

class DealDetailsController {
  constructor($scope, _, pipelinesStore) {
    'ngInject';

    this.$scope = $scope;
    this._ = _;
    this.pipelinesStore = pipelinesStore;
  }

  $onInit() {
    if (this.deal.dueDate) {
      this.deal.dueDate = new Date(this.deal.dueDate);
    }

    this.deal.pipeline = this._getPipeline();
    this.deal.currentStage = this._getStage();
  }

  onCompanySelect({ item }) {
    this.shouldCreateNewCompany = !(item && item._id);

    this.deal.company = item;
  }

  onSubmit(isValid) {
    this.$scope.$broadcast('show-errors-check-validity');

    if (!isValid) {
      return;
    }

    this.deal.pipeline = this.deal.pipeline._id;

    if (this.deal.company) {
      this.deal.company = this.deal.company._id || this.deal.company;
    }

    this.onSave({
      $event: {
        deal: this.deal
      }
    });
  }

  /**
   * If deal has connected pipeline and it is one of users pipeline, return it,
   * else return very first (default) user's pipeline
   */
  _getPipeline() {
    let pipeline = this._.find(this.pipelinesStore.pipelines, {
      _id: this.deal.pipeline
    });

    if (!pipeline) {
      pipeline = this.pipelinesStore.pipelines[0];
    }

    return pipeline;
  }

  /**
   * If current stage is one of current pipelines, return it, else
   * return first stage of current pipeline
   */
  _getStage() {
    let currentStage = this.deal.currentStage;
    const i = this._.findIndex(this.deal.pipeline.stages, {
      _id: currentStage
    });

    if (i === -1) {
      currentStage = this.deal.pipeline.stages[0]._id;
    }

    return currentStage;
  }
}

angular.module('app.deals').controller('DealDetailsController', DealDetailsController);

let watchSourceComponent = {
    templateUrl: 'app/orders/directives/watchSource/watch-source.html',
    bindings: {
      source: '@',
      user: '@'
    },
    controller: 'WatchSourceController'
  };

  angular.module('uvsApp')
    .component('watchSource', watchSourceComponent);

angular
  .module('components.sales-tasks', [
    'ui.router',
    'components.sales-task-status',
    'components.sidebar.sales'
  ])
  .config($stateProvider => {
    $stateProvider.state('root.sales-tasks', {
      abstract: true,
      url: '/sales-tasks',
      views: {
        content: {
          templateUrl: 'layouts/with-left-sidebar.html'
        },
        'sidebar@root.sales-tasks': 'salesSidebar'
      }
    });
  });

const companyCreateModal = {
  templateUrl: 'components/company-create-modal/company-create-modal.html',
  controller: 'CompanyCreateModalController',
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<'
  }
};

angular
  .module('components.company-create-modal')
  .component('companyCreateModal', companyCreateModal);

const priceMarginControlControler = {
  templateUrl: 'components/price-margin-control/price-margin-control.html',
  controller: 'PriceMarginControlController',
  require: {
    ngModelCtrl: 'ngModel'
  },
  bindings: {
    revenue: '<ngModel',
    cost: '<',
    markup: '<',
    label: '@'
  }
};

angular
  .module('components.priceMarginControl')
  .component('priceMarginControl', priceMarginControlControler);

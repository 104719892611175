class TitleService {
  constructor($window) {
    'ngInject';

    this.$window = $window;
  }

  setTitle(title) {
    this.$window.document.title = title;
    // eslint-disable-next-line no-undef
    parent.postMessage({ event: 'setTitle', data: { title } }, '*');
  }
}

angular.module('core').service('titleService', TitleService);

'use strict';

angular.module('uvsApp').config(function($stateProvider) {
  $stateProvider
    .state('root.reports', {
      abstract: true,
      url: '/reports',
      views: {
        content: {
          template: '<div ui-view></div>'
        }
      }
    })
    .state('root.reports.taskCompletionTime', {
      url: '?year&month&wid&did',
      templateUrl: 'app/reports/reports.html',
      controller: 'ReportsCtrl as ctrl',
      authenticate: true,
      title: 'Ataskaitos',
      resolve: {
        workers: function(UserFactory) {
          return UserFactory.getWorkers(['_id', 'name']);
        },
        reports: function(Report, $stateParams, workers) {
          return Report.query({
            year: $stateParams.year,
            month: $stateParams.month,
            wid: $stateParams.wid || workers[0]._id,
            did: $stateParams.did
          }).$promise;
        }
      }
    });
});

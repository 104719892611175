'use strict';

const tasks = {
  templateUrl: 'app/orders/order-tasks/order-tasks.html',
  controller: 'OrderTasksController',
  bindings: {
    order: '<',
    onChange: '&',
    onDelete: '&'
  }
};

angular.module('uvsApp').component('orderTasks', tasks);

'use strict';

angular
  .module('uvsApp')
  .factory('EmploymentRepository', function(
    _,
    $q,
    $uibModal,
    $log,
    moment,
    Employment,
    employmentHelpers,
    Task,
    TaskRepository,
    toastr
  ) {
    var factory = {};

    factory.workers = [];

    factory.onTaskDrawEnd = function(task) {
      factory.showModal(
        {
          from: task.model.from.format('YYYY-MM-DD HH:mm'),
          to: task.model.to.format('YYYY-MM-DD HH:mm'),
          worker: task.row.model.name
        },
        function(orderTask) {
          task.model.name = orderTask.order.label;
          Employment.save(
            orderTask,
            function() {
              toastr.success('Užduotis sėkmingai užsaugota.', 'Išsaugota!');
            },
            function() {
              toastr.error(
                'Užsaugoti nepavyko, bandykite dar kartą.',
                'Klaida!'
              );
            }
          );
        }
      );
    };

    factory.createTask = function(options) {
      options = options || {};
      var defer = $q.defer();
      var from = options.date || moment();
      var to = from.clone();

      from.hour(7).minute(30);
      to.hour(16).minute(30);

      var task = employmentHelpers.employmentToTask({
        start: from,
        end: to,
        sectionID: options.section.id
      });

      TaskRepository.showModal(
        {
          task: task,
          order: options.order
        },
        function(orderTask) {
          orderTask.start = new Date(orderTask.startDate);
          orderTask.end = new Date(orderTask.endDate);

          Employment.save(
            orderTask,
            function(task) {
              toastr.success('Užduotis sėkmingai užsaugota.', 'Išsaugota!');
              defer.resolve(task);
            },
            function() {
              toastr.error(
                'Užsaugoti nepavyko, bandykite dar kartą.',
                'Klaida!'
              );
            }
          );
        }
      );

      return defer.promise;
    };

    factory.updateTask = function(item) {
      return Task.update(
        {id: item.id, oid: item.orderId},
        employmentHelpers.employmentToTask(item)
      ).$promise;
    };

    factory.editTask = function(item) {
      var defer = $q.defer();

      var task = employmentHelpers.employmentToTask(item);

      TaskRepository.showModal(
        {
          task: task,
          orderId: item.orderId
        },
        function(orderTask) {
          if (!orderTask) {
            defer.resolve();
          } else {
            Task.update(
              {
                id: item.id,
                oid: item.orderId,
                jid: item.parentId
              },
              orderTask,
              function(savedTask) {
                toastr.success('Užduotis sėkmingai užsaugota.', 'Išsaugota!');
                defer.resolve(
                  employmentHelpers.taskToEmployment(savedTask, item.order)
                );
              },
              function() {
                toastr.error(
                  'Užsaugoti nepavyko, bandykite dar kartą.',
                  'Klaida!'
                );
              }
            );
          }
        }
      );

      return defer.promise;
    };

    factory.onTaskChange = function(ganttTask) {
      // Collect all task's data
      var task = {
        _id: ganttTask.model._id,
        from: ganttTask.model.from,
        to: ganttTask.model.to,
        worker: ganttTask.row.model.name,
        data: ganttTask.model.data
      };
    };

    factory.getData = function(from) {
      return Employment.query({from: from.toDate()}).$promise;
    };

    function findTaskById(tid, worker) {
      var task;

      _.forEach(worker.tasks, function(dayTasks) {
        task = _.find(dayTasks, {_id: tid});
        if (task) {
          return false;
        }
      });

      return task;
    }

    function findTaskIndexById(tid, worker) {
      var task;

      _.forEach(worker.tasks, function(dayTasks) {
        task = _.findIndex(dayTasks, {_id: tid});
        if (task !== -1) {
          return false;
        }
      });

      return task;
    }

    factory.getTask = function getTask(tid, wid) {
      var worker;
      var task;

      if (wid) {
        worker = factory.findWorkerById(wid);
      }

      if (worker) {
        task = findTaskById(tid, worker);
      }

      if (task) {
        return task;
      }

      _.forEach(factory.workers, function(wrk) {
        task = findTaskById(tid, wrk);

        if (task) {
          return false;
        }
      });

      return task;
    };

    factory.showModal = function showModal(obj, callback) {
      var modalInstance;

      modalInstance = $uibModal.open({
        templateUrl: 'app/tasks/form-modal/form-modal.html',
        controller: 'TaskFormModalCtrl as ctrl',
        size: 'l',
        resolve: {
          order: [
            'ordersManager',
            function(ordersManager) {
              var gettingOrder = undefined;

              if (obj.order) {
                return obj.order;
              }

              if (obj.orderId) {
                return ordersManager.getOrder(obj.orderId);
              }

              return gettingOrder;
            }
          ],
          workers: [
            'UserFactory',
            function(UserFactory) {
              return UserFactory.getWorkers(['_id', 'name', 'departments']);
            }
          ],
          departments: [
            'departmentsStore',
            function(departmentsStore) {
              return departmentsStore.departments;
            }
          ],
          task: function() {
            return obj;
          }
        }
      });

      modalInstance.result.then(
        function(task) {
          if (_.isFunction(callback)) {
            callback(task);
          }
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    };

    factory.saveTask = function saveTask(task) {
      var action;

      if (!task) {
        return $q.when({});
      }

      task.startDay = moment(task.day).dayOfYear();

      if (task.hasOwnProperty('_id')) {
        action = Employment.update(task);
      } else {
        action = Employment.save(task);
      }
      return action.$promise;
    };

    factory.deleteTask = function deleteTask(task) {
      var promise = Employment.delete({id: task._id}).$promise;

      promise.then(function() {
        var worker = factory.findWorkerById(task.worker);
        var taskIndex = findTaskIndexById(task._id, worker);

        worker.tasks[task.startDay].splice(taskIndex, 1);
      });

      return promise;
    };

    factory.findWorkerById = function findWorkerById(wid) {
      return _.find(factory.workers, {_id: wid});
    };

    return factory;
  });

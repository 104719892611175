class JWTService {
  constructor($http, API_URL) {
    'ngInject';

    this.$http = $http;
    this.API_URL = API_URL;
  }

  getToken() {
    return this.$http.get(`${this.API_URL}/jwt/token`);
  }
}

angular.module('app.jwt').service('JWTService', JWTService);

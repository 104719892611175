const taskModal = {
  templateUrl: 'app/tasks/task-modal/task-modal.html',
  controller: 'TaskModalController',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
};

angular.module('app.tasks').component('taskModal', taskModal);

class CustomersStore {
  constructor(Customer) {
    'ngInject';

    this.Customer = Customer;

    this.invoices = [];
    this.isInvoicesLoading = false;
  }

  fetchInvoices(customerId) {
    this.setIsInvoiceLoading(true);
    this.Customer.getInvoices({ id: customerId })
      .$promise.then(invoices => this.setInvoices(invoices))
      .finally(() => {
        this.setIsInvoiceLoading(false);
      });
  }

  setInvoices(invoices = []) {
    this.invoices = invoices;
  }

  setIsInvoiceLoading(isLoading = false) {
    this.isInvoicesLoading = isLoading;
  }
}

mobx.decorate(CustomersStore, {
  invoices: mobx.observable,
  isInvoicesLoading: mobx.observable,

  setInvoices: mobx.action,
  setIsInvoiceLoading: mobx.action
});

angular.module('app.customers').service('customersStore', CustomersStore);

const engagementNote = {
  templateUrl: 'components/activity/engagement-note/engagement-note.html',
  controller: 'EngagementNoteController',
  bindings: {
    target: '<',
    targetType: '@',
    onSave: '&'
  }
};

angular
  .module('components.activity')
  .component('engagementNote', engagementNote);

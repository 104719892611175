'use strict';

angular.module('uvsApp')
  .directive('hasRole', function (Auth) {
      return {
        link: function (scope, element, attrs) {
          if (!_.isString(attrs.hasRole)) {
            throw 'hasRole value must be a string';
          }

          var value = attrs.hasRole.trim(),
            hasRoleFlag = value[0] === '!';

          if (hasRoleFlag) {
            value = value.slice(1).trim();
          }

          function toggleVisibilityBasedOnRole() {
            var userRoles = Auth.getCurrentUserRole(),
                hasRole = _.indexOf(userRoles, value) !== -1;

            if (hasRole && !hasRoleFlag || !hasRole && hasRoleFlag) {
              element.show();
            } else {
              element.hide();
            }
          }

          toggleVisibilityBasedOnRole();
          scope.$on('roleChanged', toggleVisibilityBasedOnRole);
        }
      };
  });

class OperationsStore {
  constructor($log, _, operationsService) {
    'ngInject';

    this.$log = $log;
    this._ = _;
    this.operationsService = operationsService;

    this.operations = [];
    this.isFetched = false;
    this.isFetching = false;

    this.getById = mobxUtils.createTransformer(oid =>
      this.operations.find(operation => operation._id === oid)
    );

    this.getByDepartmentId = mobxUtils.createTransformer(did =>
      this.operations.find(operation => operation.department === did)
    );
  }

  getOperations() {
    if (!(this.isFetched || this.isFetching)) {
      this.fetchOperations();
    }

    return this.operations;
  }

  fetchOperations() {
    this.isFetching = true;

    return this.operationsService
      .getOperations()
      .then(operations => {
        this.setOperations(operations);
        this.isFetched = true;
      })
      .catch(error => this.$log.error(error))
      .finally(() => (this.isFetching = false));
  }

  setOperations(data) {
    this.operations.replace(data);
  }

  setOperation(operation) {
    const i = this.operations.findIndex(o => o._id === operation._id);
    const operations = [...this.operations];

    if (i === -1) {
      operations.push(operation);
    } else {
      operations[i] = operation;
    }

    this.setOperations(operations);
  }

  createOperation(operation) {
    return this.operationsService
      .createOperation(operation)
      .then(savedOperation => this.setOperation(savedOperation))
      .catch(error => this.$log.error(error));
  }

  updateOperation(operation) {
    return this.operationsService
      .updateOperation(operation)
      .then(savedOperation => this.setOperation(savedOperation))
      .catch(error => this.$log.error(error));
  }

  deleteOperation(operation) {
    return this.operationsService.deleteOperation(operation).then(() => {
      const i = this.operations.findIndex(o => o._id === operation._id);

      if (i === -1) {
        return;
      }

      const operations = [...this.operations];
      operations.splice(i, 1);
      this.setOperations(operations);
    });
  }

  search(query) {
    const regex = RegExp(query, 'i');

    return this.operations.filter(op => regex.test(op.name));
  }
}

mobx.decorate(OperationsStore, {
  operations: mobx.observable,

  setOperations: mobx.action
});

angular.module('app.operations').service('operationsStore', OperationsStore);

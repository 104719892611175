const orderInfoComponent = {
  templateUrl: 'app/orders/order-info/order-info.html',
  controller: 'OrderInfoController',
  bindings: {
    order: '<'
  }
};

angular
  .module('uvsApp')
  .component('orderInfo', orderInfoComponent);

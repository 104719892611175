const productComponent = {
  templateUrl: 'app/products/product/product.html',
  controller: 'ProductController',
  transclude: true,
  bindings: {
    product: '<',
    onRemove: '&',
    onChange: '&',
    isFirst: '@',
    hasAdvancedPricing: '@',
    shouldCollapse: '@',
    resetCollapse: '&'
  }
};

angular.module('app.products').component('product', productComponent);

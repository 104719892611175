const productIngredientComponent = {
  templateUrl: 'app/products/product-ingredient/product-ingredient.html',
  controller: 'ProductIngredientController',
  // require: {
  //   recipeCtrl: '^productRecipe'
  // },
  bindings: {
    ingredient: '<',
    onChange: '&',
    onRemove: '&',
    onSave: '&',
    hasAdvancedPricing: '@',
    isComponent: '@'
  }
};

angular.module('app.products').component('productIngredient', productIngredientComponent);

'use strict';

class DealController {
  constructor(
    $log,
    $state,
    $window,
    toastr,
    Modal,
    dealsService,
    ASSOC_KINDS,
    dealsStore,
    companiesStore,
    ordersStore,
    fileDownloadService,
    invoicesService,
    API_URL,
    titleService
  ) {
    'ngInject';

    this.$log = $log;
    this.$window = $window;
    this.API_URL = API_URL;
    this.toastr = toastr;
    this.dealsService = dealsService;
    this.store = dealsStore;
    this.companiesStore = companiesStore;
    this.ordersStore = ordersStore;
    this.ASSOC_KINDS = ASSOC_KINDS;
    this.$state = $state;
    this.fileDownloadService = fileDownloadService;
    this.invoicesService = invoicesService;
    this.titleService = titleService;

    this.onRemove = Modal.confirm.delete(deal => {
      dealsStore.deleteDeal(deal);
      $state.go('root.deals.list');
    });
  }

  $onInit() {
    this.dispose = mobx.autorun(() => {
      this.deal = this.store.deal;
      this.orders = this.ordersStore.orders;
      this.prepaymentInvoices = this.store.prepaymentInvoices;

      this.titleService.setTitle(`${this.deal.number} ${this.deal.name}`);
    });
    // Estimate is array of estimates when retrieved from server
    // We need only one
    this.estimate = this.estimate[0];
  }

  $onDestroy() {
    this.dispose();
  }

  onStageChange({ stage }) {
    this.store
      .changeDealStage(this.deal, stage)
      .then(deal => {
        this.toastr.success('Būsena pakeista');
      })
      .catch(err => {
        this.$log.error(err);
        this.toastr.error('Būsenos pakeisti nepavyko. Bandykite dar kartą!');
      });
  }

  onDescriptionChange() {
    this.store.updateDeal(Object.assign({}, this.deal)).catch(err => {
      this.$log.error(err);
      this.toastr.error('Užsaugoti nepavyko. Bandykite dar kartą.', 'Klaida');
    });
  }

  onCompanyChange({ company }) {
    this.store
      .updateDeal(
        Object.assign({}, this.deal, {
          company: company._id
        })
      )
      .catch(err => {
        this.$log.error(err);
        this.toastr.error('Užsaugoti nepavyko. Bandykite dar kartą.', 'Klaida');
      });
  }

  onCompanyRemove() {
    this.store
      .updateDeal(
        Object.assign({}, this.deal, {
          company: null
        })
      )
      .catch(err => {
        this.$log.error(err);
        this.toastr.error('Užsaugoti nepavyko. Bandykite dar kartą.', 'Klaida');
      });
  }

  handlePatch(fields) {
    this.store.updateDeal(Object.assign({}, { _id: this.deal._id }, fields)).catch(err => {
      this.$log.error(err);
      this.toastr.error('Užsaugoti nepavyko. Bandykite dar kartą.', 'Klaida');
    });
  }

  onEdit() {
    this.$state.go('root.deals.edit', { id: this.deal._id });
  }

  onInvoiceChange({ invoice }) {
    this._assertCreateInvoice();

    const invoiceData = this._getInvoiceData(this.deal, this.estimate, invoice);

    this.store
      .saveInvoice(invoiceData)
      .then(() => {
        this.toastr.success('Sąskaita-faktūra išsaugota sėkmingai', 'Išsaugota!');
      })
      .catch(err => {
        this.$log.error(err);
        this.toastr.error('Užsaugoti nepavyko. Bandykite dar kartą.', 'Klaida');
      });
  }

  _assertCreateInvoice() {
    if (!this.estimate) {
      this.toastr.warning('Pirmiausia sukurkite sandorį.', 'Sąskaitos sukurti negalima');
      return;
    }

    if (!this.deal.company) {
      this.toastr.warning('Pairmiauskia priskirkite įmonę/asmenį.', 'Sąskaitos sukurti negalima');
      return;
    }
  }

  _getInvoiceData(deal, estimate, invoice = {}) {
    this._assertCreateInvoice();

    const { tenant, company } = deal;
    const { items, discount = 0, publicNote } = estimate;
    const { number, createdOn = new Date(), type = 'in', _id, tax, payDelay } = invoice;

    return {
      ...(_id ? { _id } : {}),
      tenant,
      number,
      payDelay,
      parentId: this.deal._id,
      date: moment(createdOn).toDate(),
      client: company,
      discount,
      tax,
      notes: publicNote,
      items: items
        .filter(item => item.active)
        .map(({ name, salesPrice = 0, quantity = 0, code }) => ({
          name,
          code,
          price: salesPrice,
          quantity
        })),
      type
    };
  }

  handlePrepaymentInvoiceDownload() {
    const existingInvoice = this.store.prepaymentInvoices[0];

    const invoiceData = this._getInvoiceData(this.deal, this.estimate, {
      number: this.deal.number,
      type: 'prepayment',
      ...existingInvoice
    });

    this.store
      .saveInvoice(invoiceData)
      .then(invoice => this.invoicesService.getPdf(invoice))
      .then(pdf => this.fileDownloadService.download(pdf));
  }
}

angular.module('app.deals').controller('DealController', DealController);

'use strict';

const dealsNew = {
  templateUrl: 'app/deals/deal-new/deal-new.html',
  controller: 'DealsNewController'
};

angular
  .module('app.deals')
  .component('dealsNew', dealsNew)
  .config(function($stateProvider) {
    $stateProvider.state('root.deals.new', {
      url: '/new',
      component: 'dealsNew',
      title: 'Naujas sandorus'
    });
  });

class DealDescriptionController {
  constructor() {
    'ngIject';

    this.isDirty = false;
  }

  $onInit() {
    this.initialDescription = angular.copy(this.description);
  }

  onSave() {
    this.onChange({
      $event: {
        description: this.description
      }
    });
    this.isDirty = false;
  }

  reset() {
    this.description = angular.copy(this.initialDescription);
    this.isDirty = false;
  }
}

angular
  .module('components.activity')
  .controller('DealDescriptionController', DealDescriptionController);

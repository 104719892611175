'use strict';

angular.module('uvsApp')
  .directive('canUpdateStatus', function (Auth) {
    return {
      link: function (scope, element, attrs) {

        if (!_.isString(attrs.canUpdateStatus)) {
          throw 'canUpdateStatus value must be a string';
        }

        var value = attrs.canUpdateStatus.trim(),
            canUpdate = Auth.hasDepartment(value);

        if (!canUpdate) {
          element.remove();
        }
      }
    };
  });

'use strict';

angular.module('uvsApp').factory('Notification', function($resource, _, API_URL) {
  const apiPath = `${API_URL}/notifications`;

  return $resource(
    `${apiPath}/:id`,
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      },
      unreadCount: {
        method: 'GET',
        url: `${apiPath}/unreadCount`
      },
      markAllRead: {
        method: 'GET',
        url: `${apiPath}/markAllRead`
      },
      toggleUnread: {
        method: 'GET',
        url: `${apiPath}/:id/toggleUnread`
      }
    }
  );
});

'use strict';

angular.module('uvsApp').factory('Job', function($resource, API_URL) {
  var Job = $resource(
    API_URL + '/jobs/:id',
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      }
    }
  );

  return Job;
});

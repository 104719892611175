'use strict';

angular.module('uvsApp')
  .factory('taskClass', function() {
    var task = function(options) {
      var self = options || {};

      var proto = {
        addTask: function(taskData) {
          self.tasks.push(taskData);
        }
      };

      return Object.assign(Object.create(proto), options);
    };

    return task;
  });

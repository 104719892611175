'use strict';

angular.module('uvsApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('root.templates', {
        abstract: true,
        url: '/templates',
        views: {
          'content': {
            template: '<div ui-view></div>'
          }
        }
      })
      .state('root.templates.list', {
        url: '/list',
        templateUrl: 'app/templates/partials/list.html',
        controller: 'TemplatesListCtrl',
        authenticate: true,
        resolve: {
          templates: function (Template) {
            return Template.query().$promise;
          }
        }
      })
      .state('root.templates.new', {
        url: '/new',
        templateUrl: 'app/templates/partials/form.html',
        controller: 'TemplateFormCtrl',
        authenticate: true,
        resolve: {
          template: function (Template) {
            return new Template({status: 0});
          },
          replaceableFields: function(Template) {
            return Template.getReplaceableFields().$promise;
          }
        }
      })
      .state('root.templates.edit', {
        url: '/:id',
        templateUrl: 'app/templates/partials/form.html',
        controller: 'TemplateFormCtrl',
        authenticate: true,
        resolve: {
          template: function (Template, $stateParams) {
            return Template.get({id: $stateParams.id}).$promise;
          },
          replaceableFields: function(Template) {
            return Template.getReplaceableFields().$promise;
          }
        }
      });
  });

const pipelineDetailsComponent = {
  templateUrl: 'app/pipelines/pipeline-details/pipeline-details.html',
  controller: 'PipelineDetailsController',
  bindings: {
    pipeline: '<',
    onSave: '&',
    onCancel: '&'
  }
};

angular
  .module('components.pipelines')
  .component('pipelineDetails', pipelineDetailsComponent);

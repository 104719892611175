'use strict';

angular.module('uvsApp').directive('uvsCustomerForm', function() {
  return {
    restrict: 'E',
    scope: {
      customer: '=',
      cb: '&'
    },
    transclude: {
      footer: '?formFooter'
    },
    templateUrl: 'app/directives/uvsCustomerForm/form.html',
    controller: 'CustomerFormController',
    controllerAs: 'customerFormCtrl',
    bindToController: true
  };
});

'use strict';

angular.module('uvsApp')
  .controller('MaterialsCtrl', function ($scope, $window, Material) {
    $scope.units = [
      { key: 'm', value: 'm' },
      { key: 'm2', value: 'm2'},
      { key: 'm3', value: 'm3' },
      { key: 'pc', value: 'pc' },
      { key: 'sht', value: 'sheet' },
      { key: 'kg', value: 'kg' }
    ];

    Material.query(function (resp) {
      $scope.materials = resp;
      // socket.syncUpdates('material', $scope.materials);
    });
    $scope.newMaterial = new Material();

    $scope.addMaterial = function (valid) {
      console.log($scope.newMaterial);
      if (!valid) {
        return;
      }

      $scope.newMaterial.$save();
      $scope.newMaterial = new Material();
    };

    $scope.delete = function (material) {
      if (!$window.confirm('Do you really want to delete material "' + material.name + '"?')) {
        return;
      }

      material.$delete();
    };
  });

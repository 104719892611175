'use strict';

const portfolio = {
  templateUrl: 'components/portfolio/portfolio.html',
  controller: 'PortfolioController',
  bindings: {
    order: '<'
  }
};

angular
  .module('components.portfolio')
  .component('portfolio', portfolio);

'use strict';

class EngagementsService {
  constructor(engagementsResource, engagementCommentResource) {
    'ngInject';

    this.engagementsResource = engagementsResource;
    this.engagementCommentResource = engagementCommentResource;
  }

  getEngagements() {
    return this.engagementsResource.query().$promise;
  }

  getEngagement(id) {
    return this.engagementsResource.get({
      id: id
    }).$promise;
  }

  createEngagement(engagement) {
    return this.engagementsResource.save(engagement).$promise;
  }

  updateEngagement(engagement) {
    return this.engagementsResource.update(engagement).$promise;
  }

  createComment(engagementId, comment) {
    return this.engagementCommentResource.save(
      Object.assign({}, comment, {
        engagementId
      })
    ).$promise;
  }

  removeComment(engagementId, commentId) {
    return this.engagementCommentResource.delete(commentId, { engagementId, _id: commentId })
      .$promise;
  }
}

angular.module('app.engagements').service('engagementsService', EngagementsService);

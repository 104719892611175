'use strict';

angular.module('components.audit').factory('auditResource', function($resource, API_URL) {
  return $resource(
    `${API_URL}/auditlogs/:id`,
    { id: '@_id' },
    {
      query: {
        method: 'GET'
      },
      update: {
        method: 'PUT'
      }
    }
  );
});

class EngagementTaskController {
  constructor($scope, moment, usersStore, REMINDERS) {
    'ngInjcet';

    this.$scope = $scope;
    this.moment = moment;
    this.usersStore = usersStore;
    this.reminders = REMINDERS;
  }

  $onInit() {
    this.reset();
    this.reminder = null;
    this.allowReminder = false;
  }

  onTaskCreate(isValid) {
    this.$scope.$broadcast('show-errors-check-validity');

    if (!isValid) {
      return;
    }

    this.onSave({
      $event: {
        metadata: {
          subject: this.task.subject,
          description: this.task.description,
          assigneeId: this.task.assignee,
          dueDate: this.dueMoment.toDate()
        },
        type: 'TASK',
        associations: [
          {
            kind: this.targetType,
            item: this.target._id
          }
        ],
        reminder: this.reminder
      }
    });
    this.reset();
  }

  setReminder(reminder) {
    this.reminder = reminder;
  }

  handleDueDateChange({ moment }) {
    // Clones moment to have state changes
    this.dueMoment = this.moment(moment);
    this.allowReminder = this.hasTimeChanged();
  }

  reset() {
    this.dueMoment = this.moment()
      .add(1, 'days')
      .hours(0)
      .minutes(0)
      .seconds(0);
    this.task = {
      summary: '',
      description: '',
      dueDate: null,
      assignee: this.usersStore.currentUser._id
    };
    this.previousDueMoment = this.moment(this.dueMoment);
    this.reminder = null;

    this.$scope.$broadcast('show-errors-reset');
  }

  hasTimeChanged() {
    return (
      this.dueMoment.hours() * 60 + this.dueMoment.minutes() !=
      this.previousDueMoment.hours() * 60 + this.previousDueMoment.minutes()
    );
  }
}

angular
  .module('components.activity')
  .controller('EngagementTaskController', EngagementTaskController);

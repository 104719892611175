const operationDetails = {
  templateUrl: 'app/operations/operation-details/operation-details.html',
  controller: 'OperationDetailsController',
  bindings: {
    operation: '<',
    onSave: '&'
  }
};

angular
  .module('app.operations')
  .component('operationDetails', operationDetails);

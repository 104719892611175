'use strict';

angular.module('uvsApp')
  .factory('materialsService', function(_, $q, $log, $uibModal, Order, toastr) {
    var service = {};

    service.addMaterial = function(orderId, department) {
      var defer = $q.defer();

      if (!orderId || !department) {
        defer.reject('Order Id or department is missing.');
      } else {
        showModal({
          department: department
        }, function(material) {
          Order
            .addMaterial({id: orderId}, material,
              function(addedMaterial) {
                toastr.success(
                  'Medžiaga ' + addedMaterial.name + ' sėkmingai pridėta',
                  'Išsaugota'
                );
                defer.resolve(addedMaterial);
              }, function(err) {
                toastr.error('Medžiagos užsaugoti nepavyko', 'Klaida');
                defer.reject(err);
              });
        });
      }

      return defer.promise;
    };

    service.destroyMaterial = function(material, orderId) {
      var defer = $q.defer();

      Order.delMaterial(
        {id: orderId, mtrl: material._id},
        function() {
          toastr
            .success('Medžiaga ' + material.name + ' sėkmingai ištrinta',
              'Ištrinta');
          defer.resolve();
      }, function() {
          toastr
            .error('Medžiagos užsaugoti nepavyko', 'Klaida');
          defer.reject();
      });

      return defer.promise;
    };

    function showModal(obj, callback) {
      var modalInstance;

      obj = obj || {};

      modalInstance = $uibModal.open({
        templateUrl: 'app/directives/usedMaterials/material-form.html',
        controller: 'OrderMaterialFormCtrl as ctrl',
        size: 'l',
        resolve: {
          department: function() {
            return obj.department;
          }
        }
      });

      modalInstance.result.then(function(task) {
        if (_.isFunction(callback)) {
          callback(task);
        }
      }, function() {
        $log.info('Modal dismissed at: ' + new Date());
      });
    }

    return service;
  });

class TaskModalController {
  constructor(
    $scope,
    _,
    usersStore,
    departmentsStore,
    TaskRepository,
    tasksStore,
    Order
  ) {
    'ngInject';

    this.$scope = $scope;
    this._ = _;
    this.usersStore = usersStore;
    this.departmentsStore = departmentsStore;
    this.TaskRepository = TaskRepository;
    this.tasksStore = tasksStore;
    this.Order = Order;
  }

  $onInit() {
    const {order, task} = this.resolve;

    this.orders = [];
    this.workers = this.usersStore.workers;
    this.departments = this.departmentsStore.activeDepartments;

    this.task = this.TaskRepository.getDefaultTask(task); // <=== REFACTOR

    this.assigneeIsKnownAtStart = !!(
      this.task.assignee && this.task.assignee.hasOwnProperty('_id')
    );

    this.task.dep = this._getTaskDepartment(this.task, this.departments);
    this.task.assignee = this._getTaskAssignee(this.task, this.workers);

    this.task.start = {};
    this.task.end = {};

    if (this.task.startDate) {
      this.task.start = moment(this.task.startDate);
      this.task.start = {
        date: this.task.start.toDate(),
        time: this.task.start.format('HH:mm')
      };
    }

    if (this.task.endDate) {
      this.task.end = moment(this.task.endDate);
      this.task.end = {
        date: this.task.end.toDate(),
        time: this.task.end.format('HH:mm')
      };
    }

    if (order) {
      this.task.order = {
        _id: order._id,
        number: order.number,
        name: order.name,
        label: order.number + ' ' + order.name.toLowerCase()
      };
    }

    this.datepickerOptions = {
      startingDay: 1
    };

    this.isEditMode = angular.isDefined(this.task._id);
  }

  onStartDateChange() {
    if (this.task.start.date > this.task.end.date) {
      this.task.end.date = this.task.start.date;
    }
  }

  onEndDateChange() {
    if (this.task.end.date < this.task.start.date) {
      this.task.start.date = this.task.end.date;
    }
  }

  onWorkerChange() {
    this.task.assignee[0] = {
      _id: this.task.worker._id,
      name: this.task.worker.name
    };
  }

  onDepChange() {
    if (this.assigneeIsKnownAtStart) {
      return;
    }

    this.task.assignee = this._getTaskAssignee(this.task, this.workers, true);
  }
  // REFACTOR
  submit(valid) {
    let startTime;
    let endTime;

    this.$scope.$broadcast('show-errors-check-validity');

    if (!valid) {
      return;
    }

    if (this.task.start.hasOwnProperty('date')) {
      startTime = this.task.start.time.split(':');
      this.task.startDate = moment(this.task.start.date)
        .hours(0)
        .hours(startTime[0])
        .minutes(0)
        .minutes(startTime[1])
        .toDate();
    } else {
      this.task.startDate = null;
    }

    if (this.task.end.hasOwnProperty('date')) {
      endTime = this.task.end.time.split(':');

      this.task.endDate = moment(this.task.end.date)
        .hours(0)
        .hours(endTime[0])
        .minutes(0)
        .minutes(endTime[1])
        .toDate();
    } else {
      this.task.endDate = null;
    }

    this.close({
      $value: {
        task: this.task
      }
    });
  }

  // REFACTOR
  getOrders(val) {
    var searchingOrders = this.Order.search({q: val, type: 'active'}).$promise;

    return searchingOrders.then((response) => {
      this.orders = response;
    });
  }

  delete(tid) {
    if (!tid) {
      return;
    }

    this.tasksStore.deleteTask(tid).then(function() {
      this.dismiss();
    });
  }

  clearFromEmployment() {
    this.task.startDate = undefined;
    this.task.start = {};

    this.task.endDate = undefined;
    this.task.end = {};
  }

  _getTaskAssignee(task, workers, forceDepUser) {
    var taskAssignee = task.assignee;
    var depAssignee = this._.find(workers, {_id: task.dep.defaultUser});

    // if not a full user object
    if (taskAssignee && taskAssignee.hasOwnProperty('_id')) {
      taskAssignee = this._.find(workers, {_id: task.assignee._id});
    }

    if (!taskAssignee || (depAssignee && forceDepUser)) {
      taskAssignee = depAssignee;
    }

    return taskAssignee;
  }

  _getTaskDepartment(task, departments) {
    var department;

    if (task.dep) {
      department = this._.find(departments, {_id: task.dep});
    }

    if (!department && task.assignee && task.assignee.hasOwnProperty('_id')) {
      department = this._.find(departments, {defaultUser: task.assignee._id});
    }

    if (!department) {
      department = departments[0];
    }

    return department;
  }
}

angular
  .module('app.tasks')
  .controller('TaskModalController', TaskModalController);

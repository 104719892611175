angular.module('components.event').directive('eventSource', $compile => ({
  replace: true,
  scope: {
    source: '='
  },
  link: (scope, element) => {
    const tagName = `event-source-${scope.source.type.toLowerCase()}`;
    const newHtml = `<div class="event-source"><${tagName} source="source">
                      </${tagName}></div>`;

    element.append($compile(newHtml)(scope));
  }
}));

const operationNew = {
  templateUrl: 'app/operations/operation-new/operation-new.html',
  controller: 'OperationNewController'
};

angular
  .module('app.operations')
  .component('operationNew', operationNew)
  .config(function($stateProvider) {
    $stateProvider.state('root.operations.new', {
      url: '/new',
      component: 'operationNew'
    });
  });

'use strict';

const deal = {
  templateUrl: 'app/deals/deal/deal.html',
  controller: 'DealController',
  bindings: {
    deal: '<',
    pipelines: '<',
    order: '<',
    estimate: '<'
  }
};

angular
  .module('app.deals')
  .component('deal', deal)
  .config($stateProvider => {
    $stateProvider.state('root.deals.view', {
      url: '/:id',
      component: 'deal',
      resolve: {
        deal: ($transition$, dealsStore) => {
          return dealsStore.fetchDeal($transition$.params().id);
        },
        dealOrders: ($transition$, ordersStore) => {
          return ordersStore.fetchDealOrders($transition$.params().id);
        },
        estimate: ($transition$, estimatesService) => {
          return estimatesService.getByDealId($transition$.params().id);
        }
      }
    });
  });

'use strict';

angular.module('components.profile', ['ui.toggle', 'ui.router']).config($stateProvider => {
  $stateProvider.state('root.profile', {
    abstract: true,
    url: '/profile',
    views: {
      content: {
        template: '<div ui-view></div>'
      }
    }
  });
});

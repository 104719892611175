'use strict';

angular.module('app.operations').factory('operationsResource', function($resource, API_URL) {
  return $resource(
    `${API_URL}/operations/:id`,
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      }
    }
  );
});

class NotificationsList {
  constructor() {
    'ngInject';
  }

  $onInit() {
    if (!this.notifications) {
      this.isLoading = true;
    }
  }

  $onChanges() {
    if (this.notifications) {
      this.isLoading = false;
    }
  }

  markAllRead() {
    this.onMarkAllRead({
      $event: {}
    });
  }
}

angular.module('uvsApp')
  .controller('NotificationsListController', NotificationsList);

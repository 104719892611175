'use strict';

const eventComponent = {
  templateUrl: 'components/event/event.html',
  controller: 'EventController',
  bindings: {
    event: '<',
    noComment: '<'
  }
};

angular.module('components.event').component('event', eventComponent);

class UsersStore {
  constructor(usersService) {
    'ngInject';

    this.usersService = usersService;

    this.users = []; // @observable
    this.currentUser = {}; // @observable

    this.getById = mobxUtils.createTransformer(uid => this.users.find(user => user._id === uid));
  }

  // @action
  fetchUsers() {
    return this.usersService
      .getUsers()
      .then(users => this.setUsers(users))
      .catch(err => console.error(err));
  }

  setUsers(data) {
    this.users = data;
  }

  setCurrentUser(user) {
    this.currentUser = user;
  }

  get workers() {
    return this.users.filter(user => user.active && user.role.includes('user'));
  }

  resolveUserById(id) {
    return this.users.find(user => user._id === id);
  }
}

mobx.decorate(UsersStore, {
  currentUser: mobx.observable,
  users: mobx.observable,

  setUsers: mobx.action,
  setCurrentUser: mobx.action,

  workers: mobx.computed
});

angular.module('app.users').service('usersStore', UsersStore);

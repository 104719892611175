class NumberHelper {
  constructor() {
    'ngInject';
  }

  parseFloat(string) {
    if (angular.isNumber(string)) {
      return string;
    }

    const FLOAT_REGEXP_1 = /^\$?\d+(.\d{3})*(\,\d*)?$/; //Numbers like: 1.123,56
    const FLOAT_REGEXP_2 = /^\$?\d+(,\d{3})*(\.\d*)?$/; //Numbers like: 1,123.56

    if (FLOAT_REGEXP_1.test(string)) {
      return parseFloat(string.replace('.', '').replace(',', '.'));
    } else {
      if (FLOAT_REGEXP_2.test(string)) {
        return parseFloat(string.replace(',', ''));
      } else {
        return undefined;
      }
    }
  }

  roundDecimal(value, decimals = 2) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  }
}

angular
  .module('helpers.number')
  .service('number', NumberHelper);

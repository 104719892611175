class CustomerController {
  constructor(ASSOC_KINDS, titleService) {
    this.ASSOC_KINDS = ASSOC_KINDS;
    this.titleService = titleService;
  }

  $onInit() {
    this.titleService.setTitle(this.customer.name);

    if (Array.isArray(this.customer.contacts)) {
      this.contact = this.customer.contacts[0];
    }
  }

  onSave() {
    this.$state.go('root.customers.list');
  }
}

angular.module('app.customers').controller('CustomerController', CustomerController);

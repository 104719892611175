'use strict';

angular.module('uvsApp')
  .factory('orderClass', function(_, taskClass) {
    var order = function(options) {
      var defaults = {
        tasks: []
      };
      var self = _.defaults(options, defaults);

      var proto = {
        addNewTask: function(taskData) {
          var task = taskClass(taskData);
          self.tasks.push(task);
        },
        removeTaskByIndex: function(i) {
          self.tasks.splice(i, 1);
        }
      };

      return Object.assign(Object.create(proto), self);
    };

    return order;
  });

angular
  .module('uvsApp')
  .controller('JobFormModalCtrl', function(
    $scope,
    $uibModalInstance,
    _,
    job,
    User,
    UNITS,
    operationsStore,
    departmentsStore
  ) {
    var vm = this;

    vm.units = UNITS;
    vm.workers = User.getWorkers();
    vm.job = job;

    vm.jobDepartment = departmentsStore.getById(vm.job.dep);

    var jobDepOperation = { name: vm.jobDepartment.name, _id: '__dep__', unit: 'min' };

    if (!vm.job.operation) {
      vm.job = Object.assign(vm.job, {
        operation: jobDepOperation.name,
        operationId: jobDepOperation._id,
        unit: jobDepOperation.unit
      });
    }

    vm.operations = _.concat(
      [jobDepOperation],
      operationsStore.getByDepartmentId(vm.jobDepartment._id) || []
    );

    vm.isDepOperation = isDepOperation(vm.job.operationId);

    vm.setWorker = function() {
      var user = _.find(vm.workers, { _id: vm.job.user._id });

      vm.job.user.name = user.name;
    };

    vm.onTimeChange = function({ hours }) {
      vm.job.amount = hours;
    };

    vm.onOperationChange = function() {
      var operation = operationsStore.getById(vm.job.operationId) || jobDepOperation;

      vm.job.operation = operation.name;
      vm.job.unit = operation.unit;
      vm.job.tariff = operation.costPerUnit;

      vm.isDepOperation = isDepOperation(operation._id);
    };

    vm.submit = function(jobForm) {
      $scope.$broadcast('show-errors-check-validity');

      if (!jobForm.$valid) {
        return;
      }

      vm.setWorker();

      // This happens when default 1h is used
      // time-field doesn't trigger change on default value
      if (vm.job.amount === undefined) {
        vm.job.amount = 1;
      }

      $uibModalInstance.close(vm.job);
    };

    function isDepOperation(operationId) {
      return operationId === jobDepOperation._id;
    }
  });

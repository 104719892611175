// class ProductListController {
//   constructor() {
//     'ngInject';

//     this.shit = 'is fucked';
//   }

//   $onInit() {
//     this.products = this.products || [];
//     console.log(this);
//   }

//   addNewProduct() {
//     const product = {
//       name: '',
//       id: new Date().getTime() // temporary value for ui only
//     };

//     this.products.push(product);

//     this.onChange({
//       'product': product
//     });
//   }

//   onProductChange() {
//     this.onChange({
//       '$event': {
//         'product': product
//       }
//     });
//   }

//   onProductRemove({
//     product
//   }) {
//     // const i = this.products.indexOf(product);
//     // this.products.splice(i, 1);
//     this.onRemove({
//       product: product
//     });
//   }
// }

function ProductListController() {
  this.$onInit = () => {
    this.products = this.products || [];
  };

  this.addItem = function () {
    const product = {
      name: '',
      id: new Date().getTime() // temporary value for ui only
    };

    this.products.push(product);

    this.onChange({
      'product': product
    });
  };

  this.onProductChange = function (event) {
    console.log('onProductChange', this);
    this.onChange({
      '$event': {
        'product': event.product
      }
    });
  };

  this.onProductRemove = function (event) {
    // const i = this.products.indexOf(product);
    // this.products.splice(i, 1);
    this.onRemove({
      product: event.product
    });
  };
}

angular
  .module('app.products')
  .controller('ProductListController', ProductListController);

angular.module('app.invoices').factory('invoicesResource', function($resource, API_URL) {
  const API = `${API_URL}/invoices`;

  return $resource(
    `${API}/:id`,
    { id: '@_id' },
    {
      search: {
        method: 'POST',
        url: `${API}/search`,
        isArray: true
      },
      update: {
        method: 'PUT'
      },
      patch: {
        method: 'PATCH',
        hasBody: true
      },
      getPdf: {
        method: 'GET',
        url: `${API_URL}/invoices/:id/download`,
        responseType: 'arraybuffer',
        transformResponse: function(data, headersGetter) {
          return { data, filename: headersGetter('filename') };
        }
      }
    }
  );
});

angular.module('app.invoices').factory('Invoice', function($resource, API_URL) {
  var Invoice = $resource(
    `${API_URL}/invoices/legacy/:id`,
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      }
    }
  );

  return Invoice;
});

'use strict';

angular.module('uvsApp')
  .directive('taskStatusLabel', function() {
    return {
      template: '<span class="label pull-right {{statusClass}}">' +
        '{{statusLabel}}</span>',
      restrict: 'E',
      replace: true,
      scope: {
        status: '='
      },
      // controller: 'TaskStatusLabelController',
      // controllerAs: 'ctrl',
      // bindToController: true,
      link: function(scope) {
        var clsStatus = {
          0: 'info',
          1: 'warning',
          2: 'success',
          3: 'danger'
        };
        var lblStatus = {
            0: 'To Do',
            1: 'In Progress',
            2: 'Done',
            3: 'On Hold'
          };
        scope.$watch('status', function(newVal) {
          scope.statusClass = 'label-' + clsStatus[newVal] || clsStatus[0];
          scope.statusLabel = lblStatus[newVal] || lblStatus[0];
        });
      }
    };
  });

const ProductRecipeComponent = {
  templateUrl: 'app/products/product-recipe/product-recipe.html',
  controller: 'ProductRecipeController',
  bindings: {
    recipe: '<',
    onChange: '&'
  }
};

angular
  .module('app.products')
  .component('productRecipe', ProductRecipeComponent);

'use strict';

angular.module('uvsApp').config(function($stateProvider) {
  $stateProvider.state('simple.login', {
    url: '',
    templateUrl: 'app/account/login/login.html',
    controller: 'LoginCtrl as $ctrl',
    title: 'Prisijungti'
  });
  // .state('root.signup', {
  //  url: '/signup',
  //  views: {
  //    '@': {
  //      templateUrl: 'app/account/signup/signup.html',
  //      controller: 'SignupCtrl'
  //    }
  //  }
  // });
  // .state('settings', {
  //  url: '/settings',
  //  templateUrl: 'app/account/settings/settings.html',
  //  controller: 'SettingsCtrl',
  //  authenticate: true
  // });
});

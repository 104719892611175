'use strict';

angular.module('app.engagements').factory('engagementsResource', ($resource, API_URL) =>
  $resource(
    `${API_URL}/engagements/:id/:action`,
    {
      id: '@_id'
    },
    {
      update: {
        method: 'PUT'
      }
    }
  )
);

angular.module('app.engagements').factory('engagementCommentResource', ($resource, API_URL) =>
  $resource(
    `${API_URL}/engagements/:eid/comment/:cid`,
    {
      eid: '@engagementId',
      cid: '@_id'
    },
    {
      update: {
        method: 'PUT'
      }
    }
  )
);

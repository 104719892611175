class EstimateSummaryController {
  constructor(number, businessCalculator, estimatesStore) {
    'ngInject';

    this.number = number;
    this.businessCalculator = businessCalculator;
    this.estimatesStore = estimatesStore;
  }

  $onChanges(changes) {
    if (changes.hasOwnProperty('updatedAt')) {
      this._updateValues();
    }
  }

  onMarkupChange() {
    this._updateValues();
    this.onChange({
      $event: {
        subtotal: this.estimate.subtotal,
        markup: this.estimate.markup
      }
    });

    this.estimatesStore.updateTT();
  }

  onDiscountChange() {
    this.onMarkupChange();
  }

  _updateValues() {
    const { purchaseTotal, salesTotal } = this.estimate.items
      .filter(item => item.active)
      .reduce(
        (acc, item) => {
          return {
            purchaseTotal: this.number.roundDecimal(
              acc.purchaseTotal + (item.purchasePrice || 0) * (item.quantity || 0),
              3
            ),
            salesTotal: this.number.roundDecimal(
              acc.salesTotal + (item.salesPrice || 0) * (item.quantity || 0),
              3
            )
          };
        },
        {
          purchaseTotal: 0,
          salesTotal: 0
        }
      );

    this.purchaseTotal = purchaseTotal;
    this.salesTotal = salesTotal;

    const subtotal = this.businessCalculator.calculateRevenue(
      this.salesTotal,
      this.estimate.markup
    );
    const savings = (subtotal * this.estimate.discount || 0) / 100;

    this.estimate.subtotal = subtotal - savings;
  }
}

angular.module('app.estimates').controller('EstimateSummaryController', EstimateSummaryController);

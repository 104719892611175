'use strict';

angular
  .module('uvsApp')
  .factory('Auth', function Auth(
    $location,
    $rootScope,
    $http,
    User,
    $cookies,
    $q,
    _,
    localStorageService,
    usersStore,
    API_URL
  ) {
    var currentUser = {};

    function loadCurrentUser() {
      User.get(function(user) {
        currentUser = user;
        usersStore.setCurrentUser(currentUser);

        // Set to localstorage for new app
        localStorageService.set('currentUser', currentUser);
      });
    }
    if ($cookies.get('token')) {
      loadCurrentUser();
    }

    return {
      /**
       * Authenticate user and save token
       *
       * @param  {Object}   user     - login info
       * @param  {Function} callback - optional
       * @return {Promise}
       */
      login: function(user, callback) {
        var cb = callback || angular.noop;
        var deferred = $q.defer();

        $http
          .post(API_URL + '/auth/local', {
            email: user.email,
            password: user.password
          })
          .then(function(response) {
            $cookies.put('token', response.data.token);
            localStorageService.set('token', response.data.token);

            loadCurrentUser();

            deferred.resolve(response.data);
            return cb();
          })
          .catch(
            function(response) {
              this.logout();
              deferred.reject(response.data);
              return cb(response.data);
            }.bind(this)
          );

        return deferred.promise;
      },

      /**
       * Delete access token and user info
       *
       * @param  {Function}
       */
      logout: function() {
        $cookies.remove('token');

        /* eslint-disable-next-line */
        parent.postMessage({ event: 'logout' });

        currentUser = {};
      },

      /**
       * Create a new user
       *
       * @param  {Object}   user     - user info
       * @param  {Function} callback - optional
       * @return {Promise}
       */
      createUser: function(user, callback) {
        var cb = callback || angular.noop;

        return User.save(
          user,
          function(data) {
            $cookies.put('token', data.token);
            loadCurrentUser();
            return cb(user);
          },
          function(err) {
            this.logout();
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      /**
       * Change password
       *
       * @param  {String}   oldPassword
       * @param  {String}   newPassword
       * @param  {Function} callback    - optional
       * @return {Promise}
       */
      changePassword: function(oldPassword, newPassword, callback) {
        var cb = callback || angular.noop;

        return User.changePassword(
          { id: currentUser._id },
          {
            oldPassword: oldPassword,
            newPassword: newPassword
          },
          function(user) {
            return cb(user);
          },
          function(err) {
            return cb(err);
          }
        ).$promise;
      },

      /**
       * Gets all available info on authenticated user
       *
       * @return {Object} user
       */
      getCurrentUser: function() {
        return currentUser;
      },

      /**
       * Gets role of the current user
       *
       * @returns {role|*|user.role|UserSchema.role|string}
       */
      getCurrentUserRole: function() {
        return currentUser.role;
      },

      /**
       * Check if a user is logged in
       *
       * @return {Boolean}
       */
      isLoggedIn: function() {
        return currentUser.hasOwnProperty('role');
      },

      /**
       * Waits for currentUser to resolve before checking if user is logged in
       */
      isLoggedInAsync: function(cb) {
        if (currentUser.hasOwnProperty('$promise')) {
          currentUser.$promise
            .then(function() {
              cb(true);
            })
            .catch(function() {
              cb(false);
            });
        } else if (currentUser.hasOwnProperty('role')) {
          cb(true);
        } else {
          cb(false);
        }
      },

      /**
       * Check if a user is an admin
       *
       * @return {Boolean}
       */
      isAdmin: function() {
        return currentUser.role === 'admin';
      },

      /**
       * Check if a user is a worker
       *
       * @returns {boolean}
       */
      isWorker: function() {
        return currentUser.role === 'user';
      },

      /**
       * Check if a user is a client
       *
       * @returns {boolean}
       */
      isClient: function() {
        return _.includes(currentUser.role, 'client');
      },

      /**
       * Get auth token
       */
      getToken: function() {
        return $cookies.get('token');
      },

      getTenant: function() {
        return currentUser.tenant;
      },

      /**
       * Check if user has permission
       */
      hasPermission: function(permission) {
        permission.trim();

        return _.some(currentUser.permissions, function(item) {
          return item === permission;
        });
      },

      /**
       * Check if user is assigned to department
       *
       * @param depId Department id
       * @returns {boolean}
       */
      hasDepartment: function(depId) {
        depId.trim();

        return _.some(currentUser.departments, function(item) {
          return item === depId;
        });
      }
    };
  });

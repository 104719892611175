class ProductIngredientController {
  constructor(uuid, productsService) {
    'ngInject';

    this.uuid = uuid;
    this.productsService = productsService;
  }

  $onChanges(changes) {
    if (changes.ingredient) {
      this.ingredient = Object.assign({}, this.ingredient);
      this.hasRecipe = this.ingredient.hasOwnProperty('recipe');
    }

    if (changes.hasAdvancedPricing) {
      this.hasAdvancedPricing = this.hasAdvancedPricing === 'true';
    }

    if (changes.isComponent) {
      this.isComponent = this.isComponent === 'true';
    }
  }

  onSelfChange() {
    this.onChange({
      $event: {
        product: this.ingredient
      }
    });
  }

  onSelfRemove() {
    this.onRemove({
      $event: {
        product: this.ingredient
      }
    });
  }

  onAddRecipe() {
    this.ingredient.recipe = {};
  }

  handleItemSelect({ item }) {
    const gettingProduct = item.id
      ? this.productsService.getProduct(item.id)
      : Promise.resolve(item);

    gettingProduct.then(product => {
      this.ingredient = Object.assign({}, this.ingredient, product);
      this.onSelfChange();
    });
  }
}

angular
  .module('app.products')
  .controller('ProductIngredientController', ProductIngredientController);

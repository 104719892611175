'use strict';

angular.module('uvsApp').service('Task', function($resource, API_URL) {
  return $resource(
    API_URL + '/tasks/:id/',
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      }
    }
  );
});

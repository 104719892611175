class SalesTasksController {
  constructor($timeout, $q, $http, $log, _, API_URL, dealsService) {
    'ngInject';

    this._ = _;
    this.$timeout = $timeout;
    this.$q = $q;
    this.$http = $http;
    this.$log = $log;
    this.API_URL = API_URL;

    this.searchQuery = {};
    this.sortType = 'metadata.dueDate';
    this.sortDirection = 'asc';

    this.associatedWith = {};

    this.dealsService = dealsService;
  }

  $onInit() {
    this.assignees = this._getAssignees();
  }

  onFilterChange() {
    const { assigneeId } = this.searchQuery;

    // assigneId must be undefined for filter to not be applied
    this.searchQuery = Object.assign({}, this.searchQuery, {
      assigneeId: assigneeId === null ? undefined : assigneeId
    });
  }

  onSortChange(sortOptions) {
    const { field, direction } = sortOptions;

    this.sortType = field;
    this.sortDirection = direction;
  }

  onTaskCompleted(task) {
    const taskIndex = this.tasks.findIndex(t => t._id === task._id);

    this.$timeout(() => {
      this.tasks.splice(taskIndex, 1);
    }, 300);
  }

  _getAssignees() {
    return this._.chain(this.tasks)
      .map(task => task.metadata.assignee)
      .compact()
      .uniq('_id')
      .map(assignee => ({
        name: assignee.name,
        _id: assignee._id
      }))
      .value();
  }
}

angular.module('components.sales-tasks').controller('SalesTasksController', SalesTasksController);

'use strict';

angular.module('app.tenants').factory('tenantsResource', function($resource, API_URL) {
  return $resource(
    `${API_URL}/tenants/:id`,
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      }
    }
  );
});

angular.module('uvsApp').directive('uvsSortableTable', () => ({
  scope: {
    type: '<',
    direction: '<',
    onChange: '&'
  },
  /**
   * 3 clicks changes sorting: ASC, DESC, none
   */
  controller: function() {
    this.changeType = field => {
      const sortOptions = {};
      const isSameField = this.type === field;
      const shouldClear = this.direction === 'desc' && isSameField;

      if (!shouldClear) {
        sortOptions.field = field;
        sortOptions.direction = getDirection(this.direction, isSameField);
      }

      this.onChange({ sortOptions });
    };

    function getDirection(prevDirection, isSameField) {
      if (isSameField) {
        if (prevDirection === 'asc') return 'desc';
        if (prevDirection === 'desc') return;
      }
      return 'asc';
    }
  },
  bindToController: true
}));

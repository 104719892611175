class DepartmentsService {
  constructor(Department) {
    'ngInject';

    this.departmentsResource = Department;
  }

  getDepartments() {
    return this.departmentsResource.query().$promise;
  }

  getDepartment(id) {
    return this.departmentsResource.get({id: id}).$promise;
  }

  createDepartment(department) {
    return this.departmentsResource.save(department).$promise;
  }

  updateDepartment(department) {
    return this.departmentsResource.update(department).$promise;
  }

  deleteDepartment(did) {
    return this.departmentsResource.delete({id: did}).$promise;
  }
}

angular.module('uvsApp').service('departmentsService', DepartmentsService);

'use strict';

angular.module('uvsApp')
  .directive('items', function() {
    return {
      templateUrl: 'app/directives/items/items.html',
      restrict: 'E',
      scope: {
        items: '=',
        allowEdit: '='
      },
      controller: 'ItemsController',
      controllerAs: 'i',
      bindToController: true
    };
  })

  .controller('ItemsController', function($uibModal, $rootScope, Modal) {
    var vm = this;
    var getItemModal = function getItemModal(item) {
      var modalScope = $rootScope.$new();

      angular.extend(modalScope, {
        modal: {
          title: (item) ? 'Redaguoti prekę' : 'Nauja prekė'
        }
      });

      return $uibModal.open({
        templateUrl: 'app/directives/items/items-modal.html',
        scope: modalScope,
        controller: 'ItemsModalCtrl as ctrl',
        resolve: {
          item: function() {
            return item;
          }
        }
      });
    };

    vm.$onInit = function() {
      vm.items = vm.items || [];
    };

    vm.itemFrom = function(item, index) {
      var modal = getItemModal(angular.copy(item));

      modal.result.then(function(newItem) {
        if(!vm.items[index]) {
          vm.items.push(newItem);
        }else {
          vm.items[index] = newItem;
        }
      });
    };

    vm.removeItem = Modal.confirm.delete(function(index) {
      vm.items.splice(index, 1);
    });
  })

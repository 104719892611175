class OperationEditController {
  constructor($state, $log, toastr, operationsStore, titleService) {
    'ngInit';

    this.$state = $state;
    this.$log = $log;
    this.store = operationsStore;
    this.toastr = toastr;
    this.titleService = titleService;
  }

  $onInit() {
    this.dispose = mobx.autorun(() => {
      this.operation = this.store.operations.find(op => op._id === this.operationId);

      this.titleService.setTitle(this.operation.name);
    });
  }

  $onDestroy() {
    this.dispose();
  }

  saveOperation({ operation }) {
    return this.store
      .updateOperation(operation)
      .then(() => {
        this.toastr.success('Operacija išsaugota sėkmingai.', 'Sėkmė');
        this.$state.go('root.settings.operations');
      })
      .catch(error => {
        this.$log(error);
        this.toastr.error('Nepavyko užsaugoti operacijos. Bandykite dar  kartą.', 'Klaida');
      });
  }
}

angular.module('app.operations').controller('OperationEditController', OperationEditController);

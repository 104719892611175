class PortfolioSummaryController {
  constructor($uibModalInstance, moment, order, tasks) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.moment = moment;
    this.order = order;
    this.tasks = tasks;
  }

  ok() {
    this.$uibModalInstance.close();
  }

  isLate(task) {
    return moment().isAfter(task.startDate);
  }
}

angular
  .module('components.portfolio')
  .controller('PortfolioSummaryController', PortfolioSummaryController);

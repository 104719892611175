class ProductController {
  constructor($log, toastr, _, number, Modal, Product, productsService) {
    'ngInject';

    this.$log = $log;
    this.toastr = toastr;
    this._ = _;
    this.number = number;
    this.Modal = Modal;
    this.Product = Product;
    this.service = productsService;
  }

  $onInit() {
    this.hasRecipe = this.product.hasOwnProperty('recipe');
  }

  $onChanges(changes) {
    if (changes.isFirst) {
      this.isFirst = this.isFirst === 'true';
    }

    if (changes.product) {
      this.product = new this.Product(this.product);
    }

    if (changes.hasAdvancedPricing) {
      this.hasAdvancedPricing = this.hasAdvancedPricing === 'true';
    }

    if (changes.shouldCollapse && changes.shouldCollapse.currentValue.length) {
      this.handleCollapse(this.shouldCollapse === 'true');
    }
  }

  onProductRemove() {
    this.onRemove({
      $event: {
        product: this.product
      }
    });
  }

  onIngredientChange({ product }) {
    Object.assign(this.product, product);
    this.onChange({
      $event: {
        product: this.product
      }
    });
  }

  onAddRecipe() {
    this.hasRecipe = true;
    this.isRecipeVisible = true;
    this.product = Object.assign({}, this.product, {
      recipe: {
        ingredients: []
      }
    });
  }

  onRecipeChange(event) {
    const prices = this.product.recipe.ingredients.reduce(
      (acc, ingr) => {
        return {
          purchasePrice: this.number.roundDecimal(
            acc.purchasePrice + ingr.quantity * ingr.purchasePrice,
            3
          ),
          salesPrice: this.number.roundDecimal(acc.salesPrice + ingr.quantity * ingr.salesPrice, 3)
        };
      },
      {
        purchasePrice: 0,
        salesPrice: 0
      }
    );
    this.product = Object.assign({}, this.product, prices);

    this.onChange({
      $event: {
        product: this.product
      }
    });
  }

  // Save as draft
  onProductSave() {
    this.service
      .create(Object.assign({}, this.product, { _id: undefined }))
      .then(() =>
        this.toastr.success('Produktą peržiūrėti galite Sandelyje', 'Produktas išsaugotas!')
      )
      .catch(error => {
        if (error.status === 422) {
          return this.handleProductOverwrite();
        }
        this.$log.error(error);
        this.toastr.error('Išsaugoti nepavyko. Bandykite dar kartą.', 'Klaida');
      });
  }

  onProductUpdate() {
    this.service
      .update(this.product)
      .then(() => this.toastr.success('Produktas sėkmingai perrašytas.', 'Produktas išsaugotas!'))
      .catch(error => {
        this.$log.error(error);
        this.toastr.error('Išsaugoti nepavyko. Bandykite dar kartą.', 'Klaida');
      });
  }

  handleProductOverwrite() {
    var options = {
      html: '<p>Produktas tokiu vardu jau egzistuoja. Perrašyti?</p>',
      title: 'Produktas egzistuoja',
      actionText: 'Perrašyti',
      css: 'modal-info',
      actionCss: 'btn-info'
    };

    this.Modal.confirm.change(() => {
      this.onProductUpdate();
    })(options);
  }

  handleCollapse(shouldCollapse) {
    this.isRecipeVisible = !shouldCollapse;
    this.resetCollapse();
  }
}

angular.module('app.products').controller('ProductController', ProductController);

class DealInvoicesController {
  constructor($scope, dealsStore, invoicesService, fileDownloadService) {
    'ngInject';

    this.$scope = $scope;
    this.store = dealsStore;
    this.invoicesService = invoicesService;
    this.fileDownloadService = fileDownloadService;

    this.isEditMode = false;

    this.DEFAULT_PAY_DUE_DAYS = 14;
  }

  $onInit() {
    this.store.fetchDealInvoices(this.dealId);

    this.dispose = mobx.autorun(() => {
      this.invoices = this.store.regularInvoices;
    });

    this.invoice = this._getInvoice();
  }

  downloadInvoice(invoice) {
    this.invoicesService.getPdf(invoice).then(pdf => this.fileDownloadService.download(pdf));
  }

  removeInvoice(invoice) {
    this.store.removeInvoice(invoice);
  }

  $onChanges(changes) {
    if (changes.invoices) {
      this.invoice = this._getInvoice(this.invoices);
      this.isEditMode = !!this.invoice.id;
    }
  }

  onSubmit(isValid) {
    this.$scope.$broadcast('show-errors-check-validity');

    if (!isValid) {
      return;
    }

    const { number, createdOn, _id, type, payDelay = this.DEFAULT_PAY_DUE_DAYS } = this.invoice;

    this.onChange({
      $event: {
        invoice: { number, createdOn, _id, parentId: this.dealId, type, payDelay }
      }
    });

    this.invoice = this._getInvoice();
  }

  handleDueDateChange({ moment }) {
    this.invoice.createdOn = moment.toDate();
  }

  _getInvoice(invoices = []) {
    let invoice = {};

    if (invoices[0]) {
      invoice = invoices[0];
    }

    invoice.createdOn = invoice.createdOn ? new Date(invoice.createdOn) : new Date();
    invoice.type = 'in';
    invoice.payDelay = this.DEFAULT_PAY_DUE_DAYS;

    return invoice;
  }
}

angular.module('app.deals').controller('DealInvoicesController', DealInvoicesController);

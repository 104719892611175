'use strict';

angular.module('uvsApp').factory('ordersManager', function($q, _, Order, Task) {
  var ordersManager = {
    getOrders: function(params) {
      return Order.query(params).$promise;
    },

    getOrder: function(oid) {
      var dfr = $q.defer();

      if (!oid) {
        dfr.resolve(undefined);
      } else {
        Order.get({ id: oid }).$promise.then(function(order) {
          dfr.resolve(order);
        });
      }

      return dfr.promise;
    },

    getTask: function(order, did) {
      var task = _.find(order.tasks, { dep: did });

      if (!task) {
        task = new Task({ dep: did });
      }

      return task;
    },

    getJobIdByTaskId: function(order, tid) {
      var jobId;
      var job = _.filter(order.jobs, function(job) {
        return _.find(job.tasks, { _id: tid });
      });

      if (job) {
        jobId = job._id;
      }

      return jobId;
    },

    getByNumber: function(number) {
      return Order.getByNumber({ number: number }).$promise;
    },

    getUsers: opt => {
      const type = (opt && opt.type) || 'active';
      return Order.getUsers({ type }).$promise;
    }
  };

  return ordersManager;
});

angular.module('uvsApp').filter('departmentName', departmentsStore => {
  return depId => {
    const name = '';

    if (!depId) {
      return name;
    }

    const department = departmentsStore.getById(depId);

    return department ? department.name : name;
  };
});

class AttachmentsController {
  constructor($log, Upload, Order, toastr, Lightbox, _, API_URL) {
    'ngInject';

    this.uploader = Upload;
    this.lightbox = Lightbox;
    this.toastr = toastr;
    this.orderService = Order;
    this._ = _;
    this.$log = $log;
    this.API_URL = API_URL;
  }

  $onInit() {
    this.file = undefined;
  }

  upload(files, errors) {
    if (errors) {
      this.toastr.error('Failo (-ų) nepavyko išsaugoti. Bandykite dar kartą.');
      return;
    }

    if (!files && !files.length) {
      return;
    }

    angular.forEach(files, file => {
      this.uploader
        .upload({
          url: `${this.API_URL}/orders/${this.order._id}/attach`,
          data: { file }
        })
        .then(
          resp => {
            this.order.attachments.push(resp.data.attachment);
            this.toastr.success(`Failas įkeltas`);
          },
          resp => {
            this.toastr.error(`Failo įkelti nepavyko. Bandykite dar kartą.`);
          }
        );
    });
  }

  delete(index) {
    var [attachment] = this.order.attachments.splice(index, 1);

    if (!attachment) {
      // @TODO: log error
      return;
    }

    this.orderService.deleteAttachment(
      {
        id: this.order.id,
        aid: attachment._id
      },
      () => {
        this.toastr.success(`Failas ištrintas`);
      },
      resp => {
        this.toastr.error('Ištrinti nepavyko. Bandykite dar kartą.');
        console.log(`Error status: ${resp.status}`);
      }
    );
  }

  openLightboxModal(aid) {
    const previewImages = this.getSupportedImages(this.order.attachments);
    const index = this._.findIndex(previewImages, { _id: aid });

    this.lightbox.openModal(previewImages, index).result.then(
      () => {},
      () => {
        this.$log.info('Modal dismissed at: ' + new Date());
      }
    );
  }

  getSupportedImages(files) {
    return files.filter(this.isSupportedFormat);
  }

  isSupportedFormat(file) {
    return file.mimetype.startsWith('image');
  }
}

angular.module('uvsApp').controller('AttachmentsController', AttachmentsController);

'use strict';

angular.module('app.deals').factory('dealsResource', function($resource, API_URL) {
  const API = `${API_URL}/deals`;

  return $resource(
    `${API}/:id`,
    { id: '@_id' },
    {
      query: {
        url: `${API}/search`,
        method: 'POST',
        isArray: true,
        interceptor: {
          response: function(response) {
            response.resource.$httpHeaders = response.headers;
            return response.resource;
          }
        }
      },
      update: {
        method: 'PUT'
      },
      patch: {
        method: 'PATCH',
        hasBody: true
      },
      changeStage: {
        method: 'PUT',
        url: `${API}/:id/stage`
      },
      generateInvoice: {
        method: 'GET',
        url: `${API}/:id/generate-invoice`
      },
      createInvoice: {
        method: 'POST',
        url: `${API}/:id/invoice`
      },
      patchInvoice: {
        method: 'PATCH',
        url: `${API}/:id/invoice/:iid`
      },
      searchCustomers: {
        method: 'GET',
        isArray: true,
        url: `${API}/customers`
      }
    }
  );
});

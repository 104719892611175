'use strict';

angular
  .module('uvsApp')
  .filter('writeofftype', function() {
    return function(type) {
      type = type || 'salary';
      var types = {
        salary: 'Eur',
        time: 'h',
        unit: 'vnt.',
        category: 'kat.'
      };
      return types[type];
    };
  })
  .controller('WriteoffsCtrl', function(writeoffs, WriteOffs, toastr) {
    const vm = this;
    const PAGE_SIZE = 100;
    const totalCount = writeoffs.$httpHeaders('Total-count');

    vm.writeoffs = writeoffs;
    vm.canLoadMore = totalCount > PAGE_SIZE;

    vm.record = function(writeoff, index) {
      writeoff.status = 'recorded';
      writeoff.$record(function() {
        writeoffs.splice(index, 1);
      });
    };

    vm.loadMore = function() {
      vm.isLoading = true;
      WriteOffs.query(
        function(wrts) {
          vm.writeoffs = wrts;
          vm.canLoadMore = wrts.$httpHeaders('Total-count') > PAGE_SIZE;
          vm.isLoading = false;
        },
        function() {
          toastr.error('Parsiųsti nepavyko. Bandykite dar kartą.', 'Klaida');
          vm.isLoading = false;
        }
      );
    };
  });

'use strict';

class OperationsService {
  constructor(operationsResource) {
    'ngInject';

    this.operationsResource = operationsResource;
  }

  getOperations() {
    return this.operationsResource.query().$promise;
  }

  getOperation(id) {
    return this.operationsResource.get({id: id}).$promise;
  }

  createOperation(operation) {
    return this.operationsResource.save(operation).$promise;
  }

  updateOperation(operation) {
    return this.operationsResource.update(operation).$promise;
  }

  deleteOperation(operation) {
    return this.operationsResource.delete({id: operation._id}).$promise;
  }
}

angular
  .module('app.operations')
  .service('operationsService', OperationsService);

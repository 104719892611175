angular.module('uvsApp')
.constant('ProposalStatuses', {
  0: {
    name: 'Aktyvus',
    code: 'ACTIVE',
    css: 'warning'
  },
  1: {
    name: 'Konvertuotas į užsakymą',
    code: 'CONVERTED',
    css: 'success'
  },
  2: {
    name: 'Atmestas',
    code: 'REJECCTED',
    css: 'danger'
  }
});

'use strict';

angular.module('uvsApp').factory('Department', function($resource, _, API_URL) {
  var defaultConfig = {
    config: {
      materialForm: {
        fields: {
          length: true
        }
      }
    }
  };

  return $resource(
    API_URL + '/departments/:id',
    { id: '@_id' },
    {
      query: {
        method: 'GET',
        isArray: true,
        transformResponse: function(resp, headers) {
          var respObj;

          try {
            respObj = angular.fromJson(resp);
          } catch (error) {
            return;
          }

          return _.map(respObj, function(dep) {
            return _.defaults(dep, defaultConfig);
          });
        }
      },
      update: {
        method: 'PUT'
      },
      order: {
        method: 'POST',
        params: {
          id: 'order'
        }
      }
    }
  );
});

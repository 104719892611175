class OrderTasksController {
  constructor() {
    'ngInject';
  }

  handleClose(value) {
    this.onChange({
      $event: value
    });
  }

  handleTaskDelete(task) {
    this.onDelete({
      $event: {
        task
      }
    });
  }
}

angular
  .module('uvsApp')
  .controller('OrderTasksController', OrderTasksController);

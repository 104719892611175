'use strict';

angular
  .module('uvsApp')
  .controller('ReportsCtrl', function(
    $scope,
    $state,
    $stateParams,
    _,
    reports,
    moment,
    workers,
    departmentsStore
  ) {
    var dispose = mobx.autorun(function() {
      $scope.departments = departmentsStore.departments;
    });
    $scope.$on('$destroy', dispose);

    var START_OF_REPORTS = moment('2017-01-01');
    var now = moment();
    var monthsCount = now.diff(START_OF_REPORTS, 'months');
    var requestedYear = $stateParams.year ? $stateParams.year : now.year();
    var requestedMonth =
      '0' + (parseInt($stateParams.month ? $stateParams.month : now.month(), 10) + 1);
    var tempDate;

    $scope.months = [];

    $scope.months[now.format('YYYY-MM')] = {
      year: now.year(),
      month: now.month(),
      label: now.format('YYYY MMMM')
    };

    while (monthsCount > 0) {
      tempDate = now.subtract(1, 'months');
      $scope.months[tempDate.format('YYYY-MM')] = {
        year: tempDate.year(),
        month: tempDate.month(),
        label: tempDate.format('YYYY MMMM')
      };
      monthsCount--;
    }

    $scope.reports = _.groupBy(reports, function(report) {
      return moment(report.endedAt).format('YYYY-MM-DD');
    });

    $scope.haveReports = !!Object.keys($scope.reports).length;

    $scope.selectedMonth = requestedYear + '-' + requestedMonth.slice(-2);
    $scope.workers = workers;
    $scope.selectedWorker = _.find(workers, { _id: $stateParams.wid }) || workers[0];

    $scope.selectedDepartment = _.find($scope.departments, { _id: $stateParams.did }) || null;

    $scope.updateReports = function() {
      var requestedMonth = $scope.months[$scope.selectedMonth];

      $state.go(
        $state.current,
        {
          year: requestedMonth.year,
          month: requestedMonth.month,
          wid: $scope.selectedWorker._id,
          did: $scope.selectedDepartment ? $scope.selectedDepartment._id : null
        },
        { reload: true }
      );
    };
  });

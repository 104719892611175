class OrdersStore {
  constructor($log, ordersManager, tasksService) {
    'ngInject';

    this.$log = $log;
    this.ordersService = ordersManager;
    this.tasksService = tasksService;

    this.orders = [];
    this.order = null;
  }

  fetchOrders() {
    this.ordersService.getOrders().then(orders => orders.this.setOrders);
  }

  fetchOrder(id) {
    this.ordersService
      .getOrder(id)
      .then(order => this.setOrder(order))
      .catch(error => {
        // TODO: show error somehow
        this.$log.error(error);
      });
  }

  fetchDealOrders(dealId) {
    return this.ordersService
      .getOrders({ did: dealId })
      .then(orders => {
        // If deal has assigned orders, we load them to store
        // otherwise deal has no orders and
        // we need to clear what was added before
        if (orders.length) {
          orders.forEach(json => this.updateFromServer(json));
        } else {
          this.setOrders([]);
        }
      })
      .catch(error => {
        // TODO: show error somehow
        this.$log.error(error);
      });
  }

  updateFromServer(json) {
    const orders = this.orders.slice();
    const order = new Order(json);

    const orderIndex = orders.findIndex(o => o._id === order._id);

    if (orderIndex === -1) {
      orders.push(order);
    } else {
      orders.splice(orderIndex, 1, order);
    }

    this.setOrders(orders);
  }

  setOrders(orders) {
    this.orders = orders;
  }

  setOrder(order) {
    this.order = new Order(order);
  }

  updateTask(taskData) {
    let updating;

    if (taskData._id) {
      updating = this.tasksService.updateTask(taskData);
    } else {
      updating = this.tasksService.createTask(taskData);
    }

    return updating
      .then(savedTask => this.setTask(savedTask))
      .catch(error => this.$log.error(error));
  }

  deleteTask(task) {
    return this.tasksService
      .deleteTask(task._id)
      .then(() => this._handleTaskDeleted(task))
      .catch(error => this.$log.error(error));
  }

  setTask(taskData) {
    const orders = this.orders.slice();

    const orderIndex = orders.findIndex(order => order._id === taskData.order._id);

    if (orderIndex === -1) {
      return;
    }

    const order = Object.assign({}, orders[orderIndex]);
    const task = order.tasks.find(task => task._id === taskData._id);

    if (!task) {
      order.tasks.push(taskData);
    } else {
      Object.assign(task, taskData);
    }

    orders.splice(orderIndex, 1, order);

    this.setOrders(orders);
  }

  _handleTaskDeleted(task) {
    const orders = this.orders.slice();
    const orderIndex = this._getOrderIndexById(task.order);

    if (orderIndex === null) {
      return;
    }

    const order = Object.assign({}, orders[orderIndex]);
    const taskIndex = order.tasks.findIndex(({ _id }) => _id === task._id);

    if (taskIndex === -1) {
      return;
    }

    order.tasks.splice(taskIndex, 1);
    orders.splice(orderIndex, 1, order);

    this.setOrders(orders);
  }

  _getOrderIndexById(id) {
    const orderIndex = this.orders.findIndex(({ _id }) => _id === id);

    return orderIndex !== -1 ? orderIndex : null;
  }
}

mobx.decorate(OrdersStore, {
  orders: mobx.observable,
  order: mobx.observable,

  setOrders: mobx.action,
  setOrder: mobx.action
});

class Order {
  constructor(orderData) {
    this.tasks = null;

    Object.assign(this, orderData);
  }

  updateFromJson(json) {
    Object.assign(this, json);
  }
}

// mobx.decorate(Order, {
//   tasks: mobx.observable
// });

angular.module('uvsApp').service('ordersStore', OrdersStore);

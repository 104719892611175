class EstimatesStore {
  constructor() {
    'ngInject';

    this.items = [];
    this.downloading = false;
  }

  updateFromServer(json) {
    Object.assign(this, json);
  }

  updateTT() {
    this.tt = new Date().getTime();
  }

  setDownloading(isDownloading = false) {
    this.downloading = isDownloading;
  }
}

mobx.decorate(EstimatesStore, {
  items: mobx.observable,
  downloading: mobx.observable,

  updateTT: mobx.action,
  setDownloading: mobx.action
});

angular.module('app.products').service('estimatesStore', EstimatesStore);

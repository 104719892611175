class CompanySelectModalController {
  constructor() {
    'ngInject';
  }

  onSelect({ item }) {
    this.company = item;
  }

  onSubmit() {
    this.close({
      $value: this.company
    });
  }
}

angular
  .module('components.company-select-modal')
  .controller('CompanySelectModalController', CompanySelectModalController);

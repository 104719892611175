class OrdersSidebarController {
  constructor(_, Auth, departmentsStore) {
    'ngInject';

    this.Auth = Auth;
    this._ = _;
    this.departmentsStore = departmentsStore;
  }

  $onInit() {
    const userDeps = this.Auth.getCurrentUser().departments;

    this.dispose = mobx.autorun(() => {
      this.departments = this._.filter(
          this.departmentsStore.activeDepartments,
          (dep) => this._.includes(userDeps, dep._id)
      );
    });
  }

  $onDestroy() {
    this.dispose();
  }
}

angular
    .module('components.sidebar.orders')
    .controller('OrdersSidebarController', OrdersSidebarController);

'use strict';

angular
  .module('uvsApp')

  .controller('CustomerFormController', function($scope, $state, Customer, User) {
    var vm = this;

    vm.$onInit = function() {
      vm.customer = vm.customer || new Customer();
      vm.contact = {};
      vm.cb = vm.cb || angular.noop;

      User.getManagers().$promise.then(function(managers) {
        vm.managers = managers;

        if (vm.customer.manager && vm.customer.manager._id) {
          vm.customer.manager = _.find(vm.managers, { _id: vm.customer.manager._id });
        }
      });

      if (!vm.customer.contacts) {
        vm.customer.contacts = [];
      }

      if (!vm.customer.contacts.length) {
        vm.customer.contacts.push({});
      }

      vm.contact = vm.customer.contacts[0];
    };

    vm.submit = function(valid) {
      $scope.$broadcast('show-errors-check-validity');

      if (!valid) {
        return;
      }

      if (vm.customer._id) {
        Customer.update(vm.customer._id, vm.customer, callback);
      } else {
        Customer.save(vm.customer, callback);
      }
    };

    function callback(customer) {
      vm.cb({ customer: customer });
    }
  });

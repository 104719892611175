const dateTimePicker = {
  templateUrl: 'components/date-time-picker/date-time-picker.html',
  controller: 'DateTimePickerController',
  bindings: {
    isActive: '<',
    date: '<',
    onSelect: '&',
    label: '@',
    showTime: '<'
  }
};

angular.module('components.date-time-picker').component('dateTimePicker', dateTimePicker);

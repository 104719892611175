class PortfolioController {
  constructor($http, $state, toastr, PortfolioService, API_URL) {
    'ngInject';

    this.toastr = toastr;
    this.$http = $http;
    this.$state = $state;
    this.portfolioService = PortfolioService;
    this.API_URL = API_URL;
  }

  onCalculate() {
    this.$http
      .get(`${this.API_URL}/orders/${this.order._id}/calculate`)
      .then(response => {
        this.portfolioService.showSummary(this.order, response.data).finally(() => {
          this.$state.go(this.$state.current, {}, { reload: true });
        });
      })
      .catch(err => {
        this.toastr.error('Suskaičiuoti nepavyko. Bandykite dar kartą.');
      });
  }
}

angular.module('components.portfolio').controller('PortfolioController', PortfolioController);

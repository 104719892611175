const userBadge = {
  template: `
    <div class="user-badge">
      <img class="private-image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAACKUExURfL1+O/y9uvv9Ojt8tri687Z5MvW4uzw9dXe6Obs8c3X49ri6s7Y5Nnh6ufs8dTe6OXr8dTd5+fs8s7Y4+7y9urv9O7y9eTq8N3l7Nfg6dLc5t/l7djh6uDn7unu8+Pp8OHo7t/m7u/z98/Z5dHa5dHc5ubs8tbf6fH0+MzX493k7Orv8+Dn7////469OGAAAAABYktHRC3N2kE9AAAAB3RJTUUH4QEOABon2VRztAAAAWBJREFUWMPt11lygzAMgGECQSFkgWwsJvu+3f98oZlOm4IbScgPefB/gG9GI9sMjmOz2Ww2m81muJbrtsxpXtuHMr/tGeE6AfwUdOReN4SXwq7Yg0pCsRNWwVA2dQC1AonngSbJrns6sCcAfR3oN/daoK35nXH1oPs5oPGRnb7O6zf3nIHpYzPUgUMB6ER1L5J4jlt/HJrv+FlcBWOZV4p/H1ixV049+vVGwnm/Gw8mX9pkMDbCPZvOZlNz2seXpFkeKRXlWZoY0Ar1emxUITOTef3qzQXkIgRN4aIht1TwT2rZxFvBm1Z8bw1vW3O9ApAKnrfBPIANax+4B8DYzHZHAXdbMphTPICc6q1oHv3w7KngnualVA8gJYEHOnigeEe6B3AkgCcOeMK984UDXs4o6HE8yi/BlQdeUVDxQIV5W54HgF3oGxe8IWDMBWMEvHPBOwKiT3817FOQccGsAjwAk/UsE62UYR8AAAAASUVORK5CYII=" alt="" />
      <div class="user-badge-label">{{$ctrl.user.name}}</div>
    </div>
  `,
  controller: 'UserBadgeController',
  bindings: {
    uid: '<'
  }
};

angular.module('components.user-badge').component('userBadge', userBadge);

'use strict';

angular.module('uvsApp')
  .directive('calendarPanel', function() {
    return {
      templateUrl: 'app/employment/directives/calendar-panel.html',
      restrict: 'E',
      scope: {
        task: '='
      },
      controller: 'CalendarPanelController as ctrl',
      bindToController: true
    };
  })

  .controller('CalendarPanelController',
    function($rootScope, $timeout, employmentInfo) {
    var vm = this;

    vm.isOpen = false;
    vm.isLoading = false;

    $rootScope.$on('calendar-panel:open', function() {
      vm.isOpen = true;
      loadData();
    });

    vm.closePanel = function() {
      vm.isOpen = false;
    };

    function loadData() {
      vm.isLoading = true;

      employmentInfo
        .getInfo()
        .then(function(info) {
          vm.activeTask = info.task;
          vm.activeOrder = info.order;
        })
        .finally(function() {
          $timeout(function() {
            vm.isLoading = false;
          }, 500);
        });
    }
  });

const salesTasks = {
  templateUrl: 'app/sales-tasks/tasks/tasks.html',
  controller: 'SalesTasksController',
  bindings: {
    tasks: '<'
  }
};

angular
  .module('components.sales-tasks')
  .component('salesTasks', salesTasks)
  .config(function($stateProvider) {
    $stateProvider.state('root.sales-tasks.list', {
      url: '',
      component: 'salesTasks',
      title: 'Užduotys',
      resolve: {
        tasks: salesTasksService => salesTasksService.getTasks()
      }
    });
  });

const editableFieldComponent = {
  templateUrl: 'components/editable-field/editable-field.html',
  controller: 'EditableFieldController',
  transclude: true,
  bindings: {
    value: '<',
    label: '@',
    onSave: '&'
  }
};

angular.module('uvsApp').component('editableField', editableFieldComponent);

class OperationsSettingsController {
  constructor(operationsStore) {
    'ngInject';

    this.store = operationsStore;
  }

  $onInit() {
    this.dispose = mobx.autorun(() => {
      this.operations = this.store.operations;
    });
  }

  $onDestroy() {
    this.dispose();
  }

  onAddOperation() {
    this.store.createOperation(this.newOperation);
    this.reset();
  }

  onRemoveOperation(operation) {
    this.store.deleteOperation(operation);
  }

  reset() {
    this.newOperation = {};
  }
}

angular
  .module('components.settings')
  .controller('OperationsSettingsController', OperationsSettingsController);

class OperationDetailsController {
  constructor($scope, UNITS, departmentsStore) {
    'ngInit';

    this.$scope = $scope;
    this.units = UNITS;
    this.departmentsStore = departmentsStore;
  }

  $onInit() {
    this.operation = this.operation || {};
    this.operation.unit = this.operation.unit || this.units.h;

    this.dispose = mobx.autorun(() => {
      this.departments = this.departmentsStore.activeDepartments;
    });
  }

  $onDestroy() {
    this.dispose();
  }

  onSubmit(isValid) {
    this.$scope.$broadcast('show-errors-check-validity');

    if (!isValid) {
      return;
    }

    this.onSave({
      $event: {
        operation: this.operation
      }
    });
  }
}

angular
  .module('app.operations')
  .controller('OperationDetailsController', OperationDetailsController);

class DealsStore {
  constructor($log, $timeout, _, dealsService, usersStore, invoicesService, estimatesStore) {
    'ngInject';

    this.$log = $log;
    this.$timeout = $timeout;
    this._ = _;
    this.dealsService = dealsService;
    this.usersStore = usersStore;
    this.invoicesService = invoicesService;
    this.estimatesStore = estimatesStore;

    this.deals = []; // @observable
    this.deal = null;
    this.invoices = [];

    this.customers = [];

    this.filters = {
      showAll: true,
      onlyArchived: false,
      limit: 50,
      page: 1,
      field: 'createdAt',
      direction: 'desc'
    };

    this.isLoading = false;
    this.totalItems = 0;
    this._fetched = false;
    this.invoicesLoading = false;

    this.getDealById = mobxUtils.createTransformer(did => this.deals.find(d => d._id === did));
  }

  getDeal(id) {
    return this.deals.find(deal => deal._id === id);
  }

  // @action
  fetchDeals() {
    if (this.fetched) {
      return this.deals;
    }

    const loading = this.$timeout(() => {
      this.isLoading = true;
    }, 500);

    return this.dealsService
      .getDeals(this.filters)
      .then(deals => {
        this.setDeals(deals);

        this.$timeout.cancel(loading);

        this.totalItems = deals.$httpHeaders('Total-count');
      })
      .catch(error => this.$log.error(error))
      .finally(() => (this.isLoading = false));
  }

  fetchDealInvoices(dealId) {
    this.invoicesLoading = true;

    return this.invoicesService
      .search({ byParentIds: [dealId] })
      .then(invoices => this.setInvoices(invoices))
      .catch(error => this.$log.error(error))
      .finally(() => (this.invoicesLoading = false));
  }

  setDeals(data) {
    this.deals.replace(data);
    this._fetched = true;
  }

  loadDeal(id) {
    const deal = this.getDeal(id);
    if (deal) {
      return deal;
    }
  }

  loadDealById(did) {
    this.deal = this.deals.find(deal => deal._id === did);
    return this.deal;
  }

  fetchDeal(dealId) {
    this.isLoading = true;
    return this.dealsService
      .getDeal(dealId)
      .then(deal => {
        this.setDeal(deal);

        return deal;
      })
      .finally(() => (this.isLoading = false));
  }

  createDeal(deal) {
    return this.dealsService.createDeal(deal).then(savedDeal => {
      this.setDeal(savedDeal);
      return savedDeal;
    });
  }

  updateDeal(deal) {
    return this.dealsService.updateDeal(deal).then(updatedDeal => {
      this.setDeal(updatedDeal);
      return updatedDeal;
    });
  }

  changeDealStage(deal, currentStage) {
    return this.dealsService
      .changeStage(Object.assign(deal, { currentStage }))
      .then(updatedDeal => {
        this.setDeal(updatedDeal);
        return updatedDeal;
      });
  }

  patchDeal(dealId, fields) {
    return this.dealsService.patchDeal({ id: dealId }, fields).then(updatedDeal => {
      this.setDeal(updatedDeal);
      return updatedDeal;
    });
  }

  deleteDeal(deal) {
    return this.dealsService.deleteDeal(deal).then(() => {
      const i = this.deals.findIndex(d => d._id === deal._id);

      if (i === -1) {
        return;
      }

      const deals = [...this.deals];
      deals.splice(i, 1);
      this.setDeals(deals);
    });
  }

  setInvoices(invoices = []) {
    this.invoices = invoices;
  }

  setDeal(dealData) {
    const i = this.deals.findIndex(d => d._id === dealData._id);
    const newDeals = [...this.deals];

    // if owner is ID, populate with object
    if (dealData.owner && angular.isString(dealData.owner)) {
      dealData.owner = this.usersStore.resolveUserById(dealData.owner);
    }

    if (i === -1) {
      newDeals.push(dealData);
    } else {
      newDeals[i] = dealData;
    }

    this.setDeals(newDeals);
    this.loadDealById(dealData._id);
  }

  setInvoice(invoice) {
    const i = this.invoices.findIndex(i => i._id === invoice._id);
    const invoices = [...this.invoices];

    if (i === -1) {
      invoices.push(invoice);
    } else {
      invoices[i] = invoice;
    }
    this.setInvoices(invoices);
  }

  saveInvoice(invoice) {
    const saving = invoice._id
      ? this.invoicesService.patchInvoice(invoice)
      : this.invoicesService.createInvoice(invoice);

    return saving.then(updatedInvoice => {
      this.setInvoice(updatedInvoice);
      return updatedInvoice;
    });
  }

  removeInvoice(invoice) {
    this.invoicesService.deleteInvoice(invoice).then(() => {
      const indx = this.invoices.findIndex(i => i._id === invoice._id);

      if (indx === -1) {
        return;
      }

      const invoices = [...this.invoices];
      invoices.splice(indx, 1);

      this.setInvoices(invoices);
    });
  }

  get regularInvoices() {
    return this.invoices.filter(i => i.type !== 'prepayment');
  }

  get prepaymentInvoices() {
    return this.invoices.filter(i => i.type === 'prepayment');
  }

  fetchCustomers() {
    const loading = this.$timeout(() => {
      this.isLoading = true;
    }, 500);

    return this.dealsService
      .getCustomers()
      .then(customers => {
        this.setCustomers(customers);

        this.$timeout.cancel(loading);
      })
      .catch(error => this.$log.error(error))
      .finally(() => (this.isLoading = false));
  }

  setCustomers(data) {
    this.customers.replace(data);
  }

  setFilters(filters = {}) {
    // if stage is changing, clear search and customer filters
    if (filters.byStageId && filters.byStageId !== this.filters.byStageId) {
      filters.bySearchTerm = null;
      filters.byCustomerId = null;
    }
    this.filters = Object.assign({}, this.filters, filters);
  }
}

mobx.decorate(DealsStore, {
  deals: mobx.observable,
  deal: mobx.observable,
  isLoading: mobx.observable,
  totalItems: mobx.observable,
  customers: mobx.observable,
  filters: mobx.observable,
  invoicesLoading: mobx.observable,
  invoices: mobx.observable,

  regularInvoices: mobx.computed,
  prepaymentInvoices: mobx.computed,

  setDeals: mobx.action,
  loadDealById: mobx.action,
  setInvoices: mobx.action,
  setCustomers: mobx.action,
  setFilters: mobx.action
});

angular.module('app.deals').service('dealsStore', DealsStore);

'use strict';

angular.module('uvsApp').directive('uniqueOrdername', function($http, API_URL) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$asyncValidators.unique = function(number) {
        return $http.get(API_URL + '/orders/name/' + number);
      };
    }
  };
});

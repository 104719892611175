class CollapsiblePanelController {
  constructor(uiStateService) {
    'ngInject';

    this.uiStateService = uiStateService;
  }

  $onInit() {
    if (!this.name) {
      throw new Error('Panel needs name');
    }

    this.isCollapsed = this.uiStateService.get(this.name) || false;
  }

  onCollapseClicked() {
    this.isCollapsed = !this.isCollapsed;
    this.uiStateService.set(this.name, this.isCollapsed);
  }
}

angular
  .module('components.collapsible-panel')
  .controller('CollapsiblePanelController', CollapsiblePanelController);

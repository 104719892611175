const pipelinesComponent = {
  templateUrl: 'app/pipelines/pipelines/pipelines.html',
  bindings: {
    pipelines: '<'
  }
};

angular
  .module('components.pipelines')
  .component('pipelinesList', pipelinesComponent);

const fastCreateComponent = {
  template: `
    <div class="fast-create" uib-dropdown is-open="status.isopen">
      <button type="button" class="btn btn-success fast-create-btn" uib-dropdown-toggle ng-disabled="disabled">
        <span class="glyphicon glyphicon-plus"></span>
      </button>
      <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">
        <li role="menuitem" has-permission="dealsCreate">
          <a ui-sref="root.deals.new">Pardavimų sandoris</a>
        </li>
        <li role="menuitem" has-permission="ordersCreate">
          <a ui-sref="root.orders.new">Gamybos užsakymas</a></li>
      </ul>
    </div>
  `
};

angular.module('components.fastCreate').component('fastCreate', fastCreateComponent);

const editableFieldComponent = {
  templateUrl:
    'components/editable-date-time-picker/editable-date-time-picker.html',
  controller: 'EditableDateTimePickerdController',
  bindings: {
    name: '@',
    value: '<',
    label: '@',
    onSave: '&'
  }
};

angular
  .module('uvsApp')
  .component('editableDateTimePicker', editableFieldComponent);

'use strict';

angular.module('uvsApp')
  .factory('AppConfig', function () {
    return {
      taskReport: {
        adsystems: {
        '54e0a7702617f4000784ffdf': [{ //siuntimas
          label: 'Pavadinimas',
          type: 'text'
        }, {
          label: 'Kaina',
          type: 'number',
          unit: 'Eur'
        }],
        '54e0a7672617f4000784ffde': [{ //paslaugos
          label: 'Pavadinimas',
          type: 'text'
        }, {
          label: 'Kaina',
          type: 'number',
          unit: 'Eur'
        }],
        '54e0a7582617f4000784ffdc': [{ //montavimas

        }],
        '54e0a7512617f4000784ffdb' : [{ //gamyba

        }],
        '54e0a74b2617f4000784ffda': [{ //spauda
          label: 'Tiekėjas',
          type: 'text'
        }, {
          label: 'Kvadratūra',
          type: 'number',
          unit: 'm2'
        }, {
          label: 'Kaina',
          type: 'number',
          unit: 'Eur'
        }, {
          label: 'Sąskaita - faktūra',
          type: 'text'
        }],
        '54e0a7352617f4000784ffd8': [{ //ploteris
          label: 'Sunaudota plėvelės',
          type: 'number',
          unit: 'm2'
        }, {
          label: 'Poteriavimo kvadratūra',
          type: 'number',
          unit: 'm2'
        }, {
          label: 'Plėvelė',
          type: 'material'
        }],
        '54e0a72e2617f4000784ffd7': [{ //dažymas
          //??????
        }],
        '54e0a7262617f4000784ffd6': [{ //puta
          label: 'Kvadratūra',
          type: 'number',
          unit: 'm2'
        }, {
          label: 'Metražas',
          type: 'number',
          unit: 'm'
        }],
        '54e0a7202617f4000784ffd5': [{ //lazeris
          label: 'Kvadratūra',
          type: 'number',
          unit: 'm2'
        }, {
          label: 'Metražas',
          type: 'number',
          unit: 'm'
        }, {
          label: 'Medžiaga',
          type: 'material'
        }],
        '54e0a7162617f4000784ffd4': [{ //freza
          label: 'Kvadratūra',
          type: 'number',
          unit: 'm2'
        }, {
          label: 'Metražas',
          type: 'number',
          unit: 'm'
        }, {
          label: 'Medžiaga',
          type: 'material'
        }]
      }
      }
    };
  });

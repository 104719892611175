const salesTaskStatus = {
  templateUrl: 'components/sales-task-status/sales-task-status.html',
  controller: 'SalesTaskStatusController',
  bindings: {
    task: '<',
    onCompleted: '&'
  }
};

angular
  .module('components.sales-task-status')
  .component('salesTaskStatus', salesTaskStatus);

'use strict';

angular.module('uvsApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('materials', {
        url: '/materials',
        templateUrl: 'app/materials/materials.html',
        controller: 'MaterialsCtrl'
      });
  });
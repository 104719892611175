class CompaniesStore {
  constructor() {
    'ngInject';

    this.selectedCompany = null;
  }

  setSelectedCompany(company) {
    this.selectedCompany = company;
  }
}

mobx.decorate(CompaniesStore, {
  selectedCompany: mobx.observable,

  setSelectedCompany: mobx.action
});

angular
  .module('components.companies')
  .service('companiesStore', CompaniesStore);

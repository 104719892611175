const commentItemComponent = {
  templateUrl: 'components/comments/comment-item/comment-item.html',
  controller: 'CommentItemController',
  bindings: {
    item: '<',
    onRemove: '&'
  }
};

angular.module('components.comments').component('commentItem', commentItemComponent);

'use strict';

class EngagementService {
  constructor(engagementResource) {
    'ngInject';

    this.engagementResource = engagementResource;
  }

  getEngagements() {
    return this.engagementResource.query().$promise;
  }

  getEngagement(id) {
    return this.engagementResource.get({ id: id }).$promise;
  }

  createEngagement(engagement) {
    return this.engagementResource.save(engagement).$promise;
  }

  updateEngagement(engagement) {
    return this.engagementResource.update(engagement).$promise;
  }
}

angular
  .module('components.activity')
  .service('engagementService', EngagementService);

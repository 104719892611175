'use strict';

angular.module('uvsApp').factory('Role', function($resource, API_URL) {
  var Role = $resource(
    API_URL + '/roles/:id',
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      }
    }
  );

  return Role;
});

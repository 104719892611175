'use strict';

angular.module('uvsApp').factory('jobsService', function(_, $q, $log, $uibModal, Order, toastr) {
  var service = {};

  service.addJob = function(jobData) {
    var defer = $q.defer();

    var orderId = jobData.orderId;
    var departmentId = jobData.departmentId;
    var userId = jobData.userId;
    var timeSpent = jobData.totalTime;

    if (!orderId) {
      defer.reject('Order Id is missing.');
    } else {
      showModal({ job: { dep: departmentId, user: { _id: userId }, amount: timeSpent } }, function(
        job
      ) {
        Order.saveJob(
          { id: orderId },
          _.extend(job, { dep: departmentId }),
          function(savedJob) {
            toastr.success('Darbas sėkmingai išsaugotas', 'Išsaugota');
            defer.resolve(savedJob);
          },
          function() {
            toastr.error('Darbo išsaugoti nepavyko', 'Klaida');
            defer.reject();
          }
        );
      });
    }

    return defer.promise;
  };

  service.editJob = function(job, orderId) {
    const defer = $q.defer();

    showModal({ job: job }, function(editedJob) {
      Order.updateJob(
        { id: orderId, jid: job._id },
        editedJob,
        function(savedJob) {
          toastr.success('Darbas sėkmingai išsaugotas', 'Išsaugota');
          defer.resolve(savedJob);
        },
        function() {
          toastr.error('Darbo išsaugoti nepavyko', 'Klaida');
          defer.reject();
        }
      );
    });

    return defer.promise;
  };

  service.destroyJob = function(jobId, orderId) {
    var defer = $q.defer();

    Order.deleteJob(
      { id: orderId, jid: jobId },
      function() {
        toastr.success('Darbas sėkmingai ištrintas', 'Ištrinta');
        defer.resolve();
      },
      function() {
        toastr.error('Darbo ištrinti nepavyko', 'Klaida');
        defer.reject();
      }
    );

    return defer.promise;
  };

  function showModal(obj, callback) {
    var modalInstance;

    obj = obj || {};

    modalInstance = $uibModal.open({
      templateUrl: 'app/jobs/form-modal/form-modal.html',
      controller: 'JobFormModalCtrl as ctrl',
      size: 'l',
      resolve: {
        job: function() {
          return obj.job || {};
        }
      }
    });

    modalInstance.result.then(
      function(task) {
        if (_.isFunction(callback)) {
          callback(task);
        }
      },
      function() {
        $log.info('Modal dismissed at: ' + new Date());
      }
    );
  }

  return service;
});

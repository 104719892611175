'use strict';

angular.module('uvsApp').directive('statusLabel', function() {
  const status = {
    // Pending
    '0': {
      label: 'Laukiama',
      css: 'label-info'
    },
    // in progress
    '1': {
      label: 'Vykdoma',
      css: 'label-warning'
    },
    // done
    '2': {
      label: 'Atlikta',
      css: 'label-success'
    },
    // on hold
    '3': {
      label: 'Sustabdyta',
      css: 'label-danger'
    }
  };

  return {
    template: `
        <div
          class="label {{::statusLabel.css}}"
          title="{{::statusLabel.label}}"
        >{{::statusLabel.label}}</div>
      `,
    restrict: 'A',
    link: function(scope, element, attrs) {
      scope.statusLabel = status[attrs.statusLabel] || status[0];
    }
  };
});

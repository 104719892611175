angular.module('app.customers', []).config(function($stateProvider) {
  $stateProvider
    .state('root.customers', {
      abstract: true,
      url: '/customers',
      views: {
        content: {
          template: '<div ui-view=""></div>'
        }
      }
    })
    .state('root.customers.list', {
      url: '',
      templateUrl: 'app/customers/partials/list.html',
      controller: 'CustomersCtrl',
      title: 'Klientai',
      resolve: {
        customers: function(Customer) {
          return Customer.query().$promise;
        }
      }
    })
    .state('root.customers.new', {
      url: '/new',
      templateUrl: 'app/customers/partials/form.html',
      controller: 'CustomerCtrl',
      controllerAs: 'customerCtrl',
      title: 'Naujas klientas',
      resolve: {
        customer: function() {
          return null;
        },
        orders: function() {
          return null;
        }
      }
    })
    .state('root.customers.edit', {
      url: '/:id/edit',
      templateUrl: 'app/customers/partials/form.html',
      controller: 'CustomerCtrl',
      controllerAs: 'customerCtrl',
      resolve: {
        customer: function(Customer, $stateParams) {
          return Customer.get({ id: $stateParams.id }).$promise;
        },
        orders: function(Order, $stateParams) {
          return Order.query({ clientId: $stateParams.id, limit: 20 }).$promise;
        }
      }
    })
    .state('root.customers.view', {
      url: '/:id/view',
      component: 'customer',
      title: 'Klientai',
      resolve: {
        customer: function(Customer, $stateParams) {
          return Customer.get({ id: $stateParams.id }).$promise;
        },
        orders: function(Order, $stateParams) {
          return Order.query({ clientId: $stateParams.id, limit: 20, type: 'all' }).$promise;
        }
      }
    });
});

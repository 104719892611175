'use strict';

class PipelineController {
  constructor(pipelinesStore) {
    'ngInject';

    this.store = pipelinesStore;
  }

  onStageClicked(stage) {
    if (this.activeStage === stage) {
      return;
    }

    this.onStageChange({
      $event: {
        stage: stage
      }
    });
  }
}

angular
  .module('components.pipeline')
  .controller('PipelineController', PipelineController);

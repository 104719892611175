'use strict';

angular.module('app.users').config(function($stateProvider) {
  $stateProvider
    .state('root.users', {
      abstract: true,
      url: '/users',
      views: {
        content: {
          templateUrl: 'layouts/with-left-sidebar.html'
        },
        'sidebar@root.users': {
          templateUrl: 'components/sidebar/sidebar-settings.html'
        }
      }
    })
    .state('root.users.list', {
      url: '',
      templateUrl: 'app/users/users.html',
      controller: 'UsersCtrl',
      controllerAs: 'ctrl',
      title: 'Vartotojai',
      resolve: {
        users: function(User) {
          return User.query().$promise;
        },
        roles: function(Role) {
          return Role.query().$promise;
        }
      }
    })
    .state('root.users.new', {
      url: '/new',
      templateUrl: 'app/users/form.html',
      controller: 'UserFormCtrl',
      controllerAs: 'ctrl',
      title: 'Naujas vartotojas',
      resolve: {
        user: function(User) {
          return new User({ role: [] });
        },
        departments: function(departmentsStore) {
          return departmentsStore.activeDepartments;
        },
        roles: function(Role) {
          return Role.query().$promise;
        }
      }
    })
    .state('root.users.edit', {
      url: '/:id/edit',
      templateUrl: 'app/users/form.html',
      controller: 'UserFormCtrl',
      controllerAs: 'ctrl',
      resolve: {
        user: function(User, $stateParams) {
          return User.get({ id: $stateParams.id }).$promise;
        },
        departments: function(departmentsStore) {
          return departmentsStore.activeDepartments;
        },
        roles: function(Role) {
          return Role.query().$promise;
        }
      }
    });
});

'use strict';

angular.module('uvsApp')
  .directive('calendarInfo', function ($document) {
    return {
      templateUrl: 'app/employment/directives/calendar-info.html',
      restrict: 'A',
      scope: {
        calendarInfo: '=',
        isOpen: '=?'
      },
      replace: true,
      link: function(scope, element) {
        scope.$watch('isOpen', function(value) {
          element.toggleClass('show', !!value);
        });

        scope.$watch('calendarInfo', function () {
          positionInfoElement(scope.calendarInfo.Element, element);
        });

        var handler = function(event) {
          if (scope.calendarInfo.Element &&
              !scope.calendarInfo.Element[0].contains(event.target)) {
            scope.$apply(scope.isOpen = false);
          }
        };

        $document.on('click', handler);
        scope.$on('$destroy', function() {
          $document.off('click', handler);
        });

        function positionInfoElement(targetElement, infoElement) {
          if (!targetElement || !targetElement.offset) {
            return;
          }

          var offset = targetElement.offset();

          infoElement.offset({
            top: offset.top - infoElement.height() - 13 /* arrow's height */,
            left: offset.left + targetElement.width() / 2 - element.width() / 2
          });
        }
      }
    };
  });

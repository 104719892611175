'use strict';

angular.module('uvsApp').factory('Material', function($resource, API_URL) {
  var Material = $resource(
    API_URL + '/materials/:id/:controller/',
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      },
      search: {
        method: 'GET',
        params: {
          controller: 'search',
          query: '@query'
        },
        isArray: true
      }
    }
  );

  return Material;
});

'use strict';

class EngagementEditorController {
  constructor(engagementService, dealsStore, ASSOC_KINDS) {
    'ngInject';

    this.engagementService = engagementService;
    this.ASSOC_KINDS = ASSOC_KINDS;
    this.dealsStore = dealsStore;
  }

  $onInit() {
    this.isDeal = this.targetType === this.ASSOC_KINDS.DEAL;
  }

  $onChanges() {
    this.lastUpdate = new Date().getTime();
  }

  onSave(event) {
    this.engagementService.createEngagement(event).then(this.onCreateAny);
  }

  onDescriptionChange({ description }) {
    this.dealsStore
      .updateDeal(
        Object.assign({}, this.target, {
          description
        })
      )
      .catch(err => {
        this.$log.error(err);
        this.toastr.error('Būsenos pakeisti nepavyko. Bandykite dar kartą!');
      });
  }
}

angular
  .module('components.activity')
  .controller('EngagementEditorController', EngagementEditorController);

const notificationsListComponent = {
  templateUrl: 'components/notifications/notifications-list/notifications-list.html',
  controller: 'NotificationsListController',
  bindings: {
    notifications: '<',
    onMarkAllRead: '&',
    onMarkOne: '&'
  }
};

angular.module('uvsApp')
  .component('notificationsList', notificationsListComponent);

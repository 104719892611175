'use strict';

angular.module('uvsApp')
  .directive('copyToClipboard', function (clipboard) {
    return function(scope, element, attrs){
      if (!clipboard.supported) {
        console.log('Sorry, copy to clipboard is not supported');
      }
      
      var clickingCallback = function(e) {
        var cells = element.parents('table:first').find('td:first-child'),
            content = [];

        cells.each(function(index, cell) {
          content.push(angular.element(cell).children().text());
        });

        clipboard.copyText(content.join('\n'));
      };

      element.bind('click', clickingCallback);
    };
  });

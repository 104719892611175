class NotificationSettingsController {
  constructor() {
    'ngInject';
  }

  onChanged() {
    this.onChange({
      $event: {
        notifications: this.settings
      }
    });
  }
}

angular
  .module('components.profile')
  .controller('NotificationSettingsController', NotificationSettingsController);

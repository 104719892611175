'use strict';

angular.module('uvsApp')
  .directive('hoverDropdown', function () {
    return {
      restrict: 'A',
      link: function (scope, element) {
        var dropdown = angular.element(element[0].querySelector('.dropdown-menu'));
        element.bind('mouseenter', function () {
          dropdown.show();
        });
        element.bind('mouseleave', function () {
          dropdown.hide();
        });
      }
    };
  });

class WebNotificationService {
  constructor(webNotification, Auth) {
    'ngInject';
    this.webNotification = webNotification;
    this.user = Auth.getCurrentUser();
  }

  showNotification(notify) {
    if(!this.user.notifications.notify) {
      return;
    }

    this.webNotification.showNotification(
      `${notify.source.label}`, {
      body: `${notify.actor.name} ${notify.description}`,
      // icon: 'my-icon.ico',
      autoClose: 4000
    });
  }
}

angular
  .module('uvsNotifications')
  .service('WebNotificationService', WebNotificationService);

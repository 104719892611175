'use strict';

angular
  .module('uvsApp')
  .factory('TaskRepository', function(
    _,
    $q,
    $log,
    $uibModal,
    moment,
    Task,
    toastr
  ) {
    var factory = {};
    var taskDefaults = {
      startDate: moment()
        .hour(7)
        .minute(30),
      endDate: moment()
        .hour(16)
        .minute(30),
      assignee: null
    };
    var tasks = [];

    factory.getTasksFromOrders = function(orders) {
      tasks = _.reduce(
        orders,
        function(tasks, order) {
          return tasks.concat(order.tasks);
        },
        []
      );
      return tasks;
    };

    factory.getTasks = function() {
      return tasks;
    };

    factory.createTask = function(options) {
      options = options || {};

      var defer = $q.defer();

      factory.showModal(
        {
          orderId: options.orderId,
          task: {
            dep: options.depId
          }
        },
        function(orderTask) {
          orderTask = mapToTask(orderTask);

          Task.save(
            orderTask,
            function(task) {
              toastr.success('Užduotis sėkmingai užsaugota.', 'Išsaugota!');
              defer.resolve(task);
            },
            function() {
              toastr.error(
                'Užsaugoti nepavyko, bandykite dar kartą.',
                'Klaida!'
              );
              defer.reject('Užsaugoti nepavyko, bandykite dar kartą.');
            }
          );
        }
      );

      return defer.promise;
    };

    factory.updateTask = function(task, orderId) {
      var defer = $q.defer();

      factory.showModal(
        {
          task: task,
          orderId: orderId
        },
        function(orderTask) {
          if (!orderTask) {
            // Task was deleted, so we remove it
            defer.resolve();
            return;
          }
          orderTask = mapToTask(orderTask);

          Task.update(
            {
              id: orderTask._id,
              oid: orderTask.order._id
            },
            orderTask,
            function(task) {
              toastr.success('Užduotis sėkmingai užsaugota.', 'Išsaugota!');
              defer.resolve(task);
            },
            function() {
              toastr.error(
                'Užsaugoti nepavyko, bandykite dar kartą.',
                'Klaida!'
              );
            }
          );
        }
      );

      return defer.promise;
    };

    factory.destroyTask = function(taskId, orderId) {
      var defer = $q.defer();

      Task.delete(
        {
          id: taskId,
          oid: orderId
        },
        function() {
          toastr.success('Užduotis sėkmingai ištrinta.', 'Ištrinta!');
          defer.resolve();
        },
        function() {
          toastr.error('Ištrinti nepavyko, bandykite dar kartą.', 'Klaida!');
        }
      );

      return defer.promise;
    };

    factory.showModal = function showModal(obj, callback) {
      var modalInstance;

      obj = obj || {};

      modalInstance = $uibModal.open({
        templateUrl: 'app/tasks/form-modal/form-modal.html',
        controller: 'TaskFormModalCtrl as ctrl',
        size: 'l',
        resolve: {
          order: [
            'ordersManager',
            function(ordersManager) {
              return ordersManager.getOrder(obj.orderId);
            }
          ],
          task: function() {
            return obj.task;
          }
        }
      });

      modalInstance.result.then(
        function(task) {
          if (_.isFunction(callback)) {
            callback(task);
          }
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    };

    factory.getDefaultTask = function(taskData) {
      var task = {};
      var defaults = taskDefaults;

      if (taskData) {
        task = _.clone(taskData);
      }

      if (task.assignee && !task.assignee.hasOwnProperty('_id')) {
        task.assignee = {_id: task.assignee};
      }

      if (task.hasOwnProperty('_id')) {
        defaults.startDate = taskData.startDate || undefined;
        defaults.endDate = taskData.endDate || undefined;
      }

      return _.defaults(task, defaults);
    };

    factory.getDefaults = function() {
      return taskDefaults;
    };

    function mapToTask(orderTask) {
      var dep = orderTask.dep;
      var assignee = orderTask.assignee;

      orderTask.assignee = assignee ? assignee._id : null;
      orderTask.dep = dep._id;
      orderTask.name = dep.name;
      orderTask.order = orderTask.order._id;
      orderTask.start = new Date(orderTask.start);
      orderTask.end = new Date(orderTask.end);

      return orderTask;
    }

    return factory;
  });

const companySelectorComponent = {
  templateUrl: 'components/company-selector/company-selector.html',
  controller: 'CompanySelectorController',
  bindings: {
    model: '<',
    onSelect: '&',
    onCreate: '&',
    allowCreateNew: '<'
  }
};

angular
  .module('components.company-selector')
  .component('companySelector', companySelectorComponent);

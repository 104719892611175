'use strict';

const eventsComponent = {
  templateUrl: 'components/activity/events/events.html',
  controller: 'EventsController',
  bindings: {
    target: '<',
    lastUpdate: '<',
    limit: '<',
    historyOnly: '@'
  }
};

angular.module('components.activity').component('events', eventsComponent);

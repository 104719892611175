'use strict';

angular.module('uvsApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('roles', {
        url: '/roles',
        templateUrl: 'app/roles/roles.html',
        controller: 'RolesCtrl'
      })
      .state('root.roles', {
        abstract: true,
        url: '/roles',
        views: {
          '@': {
            template: '<div ui-view=""></div>',
            authenticate: true,
            role: 'admin'
          }
        }
      })
      .state('root.roles.list', {
        url: '',
        templateUrl: 'app/roles/roles.html',
        controller: 'RolesCtrl',
        controllerAs: 'ctrl',
        resolve: {
          roles: function (Role) {
            return Role.query().$promise;
          }
        }
      })
      .state('root.roles.new', {
        url: '/new',
        templateUrl: 'app/roles/partials/form.html',
        controller: 'RolesFormCtrl',
        controllerAs: 'ctrl'
      });
  });

'use strict';

angular.module('uvsApp').factory('WriteOffs', function($resource, API_URL) {
  var WriteOff = $resource(
    API_URL + '/writeoffs/:id',
    { id: '@_id' },
    {
      query: {
        isArray: true,
        interceptor: {
          response: function(response) {
            response.resource.$httpHeaders = response.headers;
            return response.resource;
          }
        }
      }
    },
    {
      update: {
        method: 'PUT'
      },
      record: {
        url: API_URL + '/writeoffs/:id/record',
        method: 'GET'
      }
    }
  );

  return WriteOff;
});

class ProductListExtendedController {
  constructor($scope, _, uuid, uiStateService) {
    'ngInject';

    this._ = _;
    this.uuid = uuid;
    this.uiStateService = uiStateService;
    this.$scope = $scope;

    this.$scope.sortableOptions = {};
  }

  $onInit() {
    this.products = this.products || [];
    this.resetCollapse();
  }

  addNewProduct() {
    const product = {
      name: '',
      pid: this.uuid.v4(),
      active: true
    };

    this.products.push(product);

    this.onChange({
      $event: {
        product: product
      }
    });
  }

  onProductChange({ product }) {
    this.onChange({
      $event: {
        product: product
      }
    });
  }

  onProductRemove({ product }) {
    this.onRemove({
      product: product
    });
  }

  resetCollapse() {
    this.shouldCollapse = undefined;
  }

  onCollapse(shouldCollapse) {
    this.shouldCollapse = shouldCollapse;
  }
}

angular
  .module('app.products')
  .controller('ProductListExtendedController', ProductListExtendedController);

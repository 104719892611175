'use strict';

angular.module('uvsApp')
  .controller('UsedMaterialsCtrl',
    function($rootScope, materialsService, $uibModal, _, Order,
      departmentsManager, toastr) {
    var vm = this;

    vm.$onInit = function() {
      vm.items = vm.items || [];

      departmentsManager.getById(vm.dep).then(function(dep) {
        vm.department = dep;
      });
    };


    vm.addMaterial = function addMaterial() {
      materialsService.addMaterial();
    };

    vm.removeMaterial = function(mtrl) {
      var indx = _.findIndex(vm.items, {_id: mtrl._id});

      vm.items.splice(indx, 1);

      Order.delMaterial({id: vm.oid, tid: vm.dep, mtrl: mtrl._id}, function() {
        toastr.success('Medžiaga ' + mtrl.name +
          ' sėkmingai ištrinta', 'Ištrinta');
      }, function() {
        toastr.error('Medžiagos užsaugoti nepavyko', 'Klaida');
      });
    };
  })

  .controller('OrderMaterialFormCtrl',
      function($scope, $uibModalInstance, department, Inventory) {
    var vm = this;

    vm.selectedMaterial = null;
    vm.department = department;

    vm.getMaterial = function(query) {
      return Inventory.search({query: query}).$promise;
    };

    vm.onMaterialSelect = function($item) {
      $item.units[0].amount = 0;
      if ($item.units[1]) {
        $item.units[1].amount = 0;
      }
    };

    vm.saveMaterial = function saveMaterial(form) {
      $scope.$broadcast('show-errors-check-validity');

      if (!form.$valid) {
        return;
      }

      vm.selectedMaterial.dep = vm.department._id;

      $uibModalInstance.close(vm.selectedMaterial);
    };
  });

'use strict';

angular.module('components', [
  'app.deals',
  'components.sales-tasks',
  'components.profile',
  'components.portfolio',
  'components.search',
  'components.settings',
  'components.fastCreate',
  'components.company-select-modal',
  'components.company-create-modal',
  'components.user-badge',
  'components.date-time-picker',
  'components.sidebar.orders',
  'components.comments'
]);

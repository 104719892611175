'use strict';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

var OrderFactory = (function() {
  OrderFactory.$inject = ['$q', '_', 'socket', 'orderClass', 'Order'];

  function OrderFactory($q, _, socket, orderClass, Order) {
    'ngInject';

    _classCallCheck(this, OrderFactory);

    this.$q = $q;
    this._ = _;
    this.socket = socket;
    this.orderClass = orderClass;
    this.orderResource = Order;
    this.orders = [];
    this.tasks = [];
  }

  _createClass(OrderFactory, [
    {
      key: 'getOrders',
      value: function getOrders(options) {
        var _this = this;

        if (this.orders.length) {
          return this.$q.resolve(this.orders);
        }

        return this.orderResource
            .query(options)
            .$promise.then(function(orders) {
              _this.orders = orders;
              _this.tasks = _this._.reduce(
                  _this.orders,
                  function(tasks, order) {
                    return tasks.concat(order.tasks);
                  },
                  []
              );

              _this.socket.syncUpdates('task', _this.tasks, function(
                  event,
                  item,
                  array
              ) {
              // console.log(event, item);
              });

              return _this.orders;
            });
      }
    },
    {
      key: 'getNewOrder',
      value: function getNewOrder(options) {
        return this.orderClass(options);
      }
    },
    {
      key: 'test',
      value: function test() {
        var task = this._.find(this.tasks, {_id: '5acef7fee1a0ee621264a4fe'});

        if (task) {
          task.status = 2;
        }
      }
    }
  ]);

  return OrderFactory;
})();

angular.module('uvsApp').service('orderFactory', OrderFactory);
// # sourceMappingURL=orders-factory.js.map

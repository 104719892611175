const dealsSettingsComponent = {
  templateUrl: 'app/settings/deals/deals-settings.html',
  controller: angular.noop,
  bindings: {
    pipelines: '<'
  }
};

angular
  .module('components.settings')
  .component('dealsSettings', dealsSettingsComponent)
  .config(($stateProvider) => {
    $stateProvider
      .state('root.settings.deals', {
        url: '/deals',
        component: 'dealsSettings',
        resolve: {
          pipelines: (pipelinesService) => pipelinesService.getPipelines()
        }
      });
  });

class EditableDateTimePickerdController {
  constructor(moment) {
    'ngInit';

    this.moment = moment;
    this.isActive = false;
  }

  $onChanges(changes) {
    if (changes.value && changes.value.currentValue) {
      this.value = new Date(changes.value.currentValue);
    }
  }

  handleChange({moment}) {
    this.value = moment;
  }

  handleActive() {
    this.isActive = true;
  }

  handleCancel() {
    this.isActive = false;
  }

  handleAddDate() {
    const now = this.moment();
    this.value = this.moment()
      .hours(now.hours() + 1)
      .minutes(0)
      .toDate();
    this.isActive = true;
  }

  handleSubmit() {
    this.onSave({
      $event: {[this.name]: this.value}
    });
    this.isActive = false;
  }
}

angular
  .module('uvsApp')
  .controller(
    'EditableDateTimePickerdController',
    EditableDateTimePickerdController
  );

'use strict';

class CommentsController {
  constructor() {
    'ngInject';
  }

  handleSubmit({ text }) {
    this.onAddComment({ $event: { text } });
  }

  handleRemove({ itemId }) {
    this.onRemoveComment({ $event: { itemId } });
  }
}

angular.module('components.comments').controller('CommentsController', CommentsController);

angular.module('uvsApp').directive('associationLink', () => ({
  templateUrl: 'app/directives/associationLink/associationLink.html',
  scope: {
    assoc: '<'
  },
  link: (scope, element, attrs, ctrl) => {
    const uriMap = {
      deal: 'deals'
    };
    const {assoc} = scope;
    console.log('object', assoc);
    if (!assoc) {
      return;
    }

    console.log('fuuuk', assoc);
    scope.uri = `${uriMap[assoc.kind.toLowerCase()]}/${assoc.item}`;
  }
}));

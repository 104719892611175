'use strict';

angular.module('uvsApp')
  .controller('PermissionsCtrl', function (_, permissions, roles) {
    var vm = this;

    vm.roles = roles;
    vm.permissions = _.groupBy(permissions, 'group');

    vm.setPermission = function (role, pId) {
      var idx = role.permissions.indexOf(pId);

      // is currently selected
      if (idx > -1) {
        role.permissions.splice(idx, 1);
      }

      // is newly selected
      else {
        role.permissions.push(pId);
      }

      //Role.update({ _id: role._id }, role);
      role.$update();
    };
  })

  .controller('PermissionsFormCtrl', function (Permission) {
    var vm = this;

    vm.permission = new Permission();

    vm.submit = function () {
      vm.permission.$save(function () {
        vm.permission = new Permission();
      });
    };
  });

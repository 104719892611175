'use strict';

angular
  .module('app.users')
  .controller('UsersCtrl', function(_, users, roles, Auth) {
    var vm = this;

    vm.users = users;
    vm.roles = _.keyBy(roles, 'role');

    vm.remove = function(user, idx) {
      user.$delete(function() {
        vm.users.splice(idx, 1);
      });
    };
  })

  .controller('UserFormCtrl', function(
    user,
    departments,
    roles,
    $state,
    $scope,
    toastr,
    titleService
  ) {
    var vm = this;

    vm.user = user;
    vm.departments = departments;
    vm.userDeps = user.departments || [];
    vm.isEditMode = user.hasOwnProperty('email');
    vm.roles = roles;

    if (vm.isEditMode) {
      titleService.setTitle(vm.user.name);
    }

    vm.toggleDep = function(dep) {
      var idx = vm.userDeps.indexOf(dep);

      // is currently selected
      if (idx > -1) {
        vm.user.departments.splice(idx, 1);
      }

      // is newly selected
      else {
        if (!vm.user.departments) {
          vm.user.departments = [];
        }
        vm.user.departments.push(dep);
      }
    };

    vm.toggleRole = function(role) {
      var idx = vm.user.role.indexOf(role);

      // is currently selected
      if (idx > -1) {
        vm.user.role.splice(idx, 1);
      }

      // is newly selected
      else {
        if (!vm.user.role) {
          vm.user.role = [];
        }
        vm.user.role.push(role);
      }
    };

    function toList() {
      $state.go('root.users.list');
    }

    function onFail(error) {
      toastr.error(error.data, 'Klaida');
    }

    vm.submit = function(isValid) {
      $scope.$broadcast('show-errors-check-validity');

      if (!isValid) {
        return;
      }

      if (vm.isEditMode) {
        vm.user.$update(toList, onFail);
      } else {
        vm.user.$save(toList, onFail);
      }
    };
  });

// deividas@adsystems.lt

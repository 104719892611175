class SubscriptionService {
  constructor(Subscription) {
    'ngInject';
    this.subscriptionResource = Subscription;
  }

  getOne(sourceId, subscriberId) {
    return this.subscriptionResource
      .query({sid: sourceId, uid: subscriberId}).$promise;
  }

  subscribe(sourceId, subscriberId, event) {
    return this.subscriptionResource
      .save({
        event: event,
        source: sourceId,
        subscriber: subscriberId
      }).$promise;
  }

  unsubscribe(id) {
    return this.subscriptionResource
      .delete({id: id}).$promise;
  }

  toggleSubscription(sub) {
    let promise;

    if (sub.hasOwnProperty('_id')) {
      promise = this.unsubscribe(sub._id);
    } else {
      promise = this.subscribe(sub.source, sub.subscriber, sub.event);
    }

    return promise;
  }
}

angular.module('uvsApp').service('SubscriptionService', SubscriptionService);

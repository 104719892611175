'use strict';

class TasksService {
  constructor(Task) {
    'ngInject';

    this.tasksResource = Task;
  }

  getTasks() {
    return this.tasksResource.query().$promise;
  }

  getTask(id) {
    return this.tasksResource.get({
      id: id
    }).$promise;
  }

  createTask(task) {
    return this.tasksResource.save(this._toPayload(task)).$promise;
  }

  updateTask(task) {
    return this.tasksResource.update(this._toPayload(task)).$promise;
  }

  deleteTask(id) {
    return this.tasksResource.delete({id}).$promise;
  }

  _toPayload(task) {
    return Object.assign(task, {
      order: task.order && task.order._id ? task.order._id : null,
      dep: task.dep && task.dep._id ? task.dep._id : null,
      name: task.dep && task.dep.name ? task.dep.name : '',
      assignee: task.assignee && task.assignee._id ? task.assignee._id : null,
      start: new Date(task.start),
      end: new Date(task.end)
    });
  }
}

angular.module('app.tasks').service('tasksService', TasksService);

class CompanySelectorController {
  constructor($http, $state, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$state = $state;
    this.API_URL = API_URL;
  }

  $onInit() {
    if (angular.isUndefined(this.allowCreateNew)) {
      this.allowCreateNew = true;
    }
  }

  getCompany(val) {
    return this.$http
      .get(`${this.API_URL}/customers`, {
        params: {
          name: val
        }
      })
      .then(response => response.data);
  }

  onBlur() {
    if (this.noResults && this.model && this.model.length) {
      this.onSelect({
        $event: {
          item: this.model
        }
      });
    }
  }

  onControlSelect($item) {
    this.onSelect({
      $event: {
        item: $item
      }
    });
  }

  handleCreate() {
    this.$state.go('companyCreate', {
      company: { name: this.model },
      onClose: company => {
        this.onCreate({ $value: company });
      }
    });
  }
}

angular
  .module('components.company-selector')
  .controller('CompanySelectorController', CompanySelectorController);

'use strict';

class DealsNewController {
  constructor($state, toastr, dealsStore) {
    'ngInject';

    this.$state = $state;
    this.toastr = toastr;
    this.store = dealsStore;
  }

  $onInit() {
    this.deal = {
      name: ''
    };
  }

  createNewDeal(event) {
    return this.store
      .createDeal(event.deal)
      .then((deal) => {
        this.toastr.success('Sandoris sukurtas sėkmingai.');
        this.$state.go('root.deals.view', {
          id: deal._id
        });
      })
      .catch(() => {
        this.toastr.error('Nepavyko užsaugoti sandorio. Bandykite dar  kartą.');
      });
  }
}

angular
  .module('app.deals')
  .controller('DealsNewController', DealsNewController);

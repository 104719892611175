'use strict';

angular.module('uvsApp').filter('appDate', moment => {
  return (date, format) => {
    if (!date) {
      return;
    }

    const mDate = moment(date);

    let dateFormat;

    if (format) {
      dateFormat = format;
    } else {
      dateFormat = 'YYYY-MM-DD';

      // if (mDate.hours() || mDate.minutes()) {
      //   dateFormat += ' HH:mm';
      // }
    }

    return mDate.format(dateFormat);
  };
});

class PipelinesStore {
  constructor(pipelinesService) {
    'ngInject';

    this.pipelinesService = pipelinesService;

    this.pipelines = [];
  }

  fetchPipelines() {
    return this.pipelinesService
      .getPipelines()
      .then(pipelines => this.setPipelines(pipelines))
      .catch(err => console.error(err));
  }

  setPipelines(data) {
    this.pipelines = data;
  }

  getAllPipelines() {
    return this.pipelines;
  }

  getStage(stageId) {
    return mobx
      .computed(() => {
        return this.pipelines[0].stages.filter(s => s._id === stageId)[0];
      })
      .get();
  }

  get defaultStage() {
    return this.pipelines[0].stages[0];
  }

  get activeStages() {
    return this.pipelines[0].stages.filter(stage => !stage.shouldArchive);
  }

  get archivedStages() {
    return this.pipelines[0].stages.filter(stage => stage.shouldArchive);
  }
}

mobx.decorate(PipelinesStore, {
  pipelines: mobx.observable,

  setPipelines: mobx.action,

  defaultStage: mobx.computed,
  activeStages: mobx.computed,
  archivedStages: mobx.computed
});

angular.module('components.pipelines').service('pipelinesStore', PipelinesStore);

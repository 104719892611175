let attachmentsComponent = {
  templateUrl: 'app/orders/directives/attachments/attachments.html',
  bindings: {
    order: '<',
  },
  controller: 'AttachmentsController'
};

angular.module('uvsApp')
  .component('attachments', attachmentsComponent);

'use strict';

angular.module('uvsApp').factory('printer', function($rootScope, $compile, $http, $timeout, $q) {
  var printHtml = function(html) {
    var deferred = $q.defer();

    var oHiddFrame = $('<iframe style="display: none"></iframe>').appendTo('body')[0];
    $(oHiddFrame).load(function() {
      if (!oHiddFrame.contentDocument.execCommand('print', false, null)) {
        oHiddFrame.contentWindow.focus();
        oHiddFrame.contentWindow.print();
      }
      oHiddFrame.contentWindow.onafterprint = function() {
        $(oHiddFrame).remove();
      };
    });

    var htmlContent =
      '<!doctype html>' +
      '<html><base href="/" />' +
      '<head><link rel="stylesheet" href="app/print.css"></head>' +
      '<body>' +
      html +
      '</body>' +
      '</html>';

    var doc = oHiddFrame.contentWindow.document.open('text/html', 'replace');
    doc.write(htmlContent);
    deferred.resolve();
    doc.close();
    return deferred.promise;
  };

  var print = function(templateUrl, data) {
    $http.get(templateUrl).then(function(templateData) {
      var template = templateData.data;
      var printScope = $rootScope.$new();
      angular.extend(printScope, data);
      var element = $compile($('<div>' + template + '</div>'))(printScope);
      var waitForRenderAndPrint = function() {
        if (printScope.$$phase || $http.pendingRequests.length) {
          $timeout(waitForRenderAndPrint, 1000);
        } else {
          // Replace printHtml with openNewWindow for debugging
          printHtml(element.html());
          // openNewWindow(element.html());
          printScope.$destroy();
        }
      };
      waitForRenderAndPrint();
    });
  };

  var printFromScope = function(templateUrl, scope) {
    $rootScope.isBeingPrinted = true;
    $http.get(templateUrl).then(function(templateData) {
      var template = templateData.data;
      var printScope = scope;
      var element = $compile($('<div>' + template + '</div>'))(printScope);
      var waitForRenderAndPrint = function() {
        if (printScope.$$phase || $http.pendingRequests.length) {
          $timeout(waitForRenderAndPrint, 1000);
        } else {
          printHtml(element.html()).then(function() {
            $rootScope.isBeingPrinted = false;
          });
        }
      };
      waitForRenderAndPrint();
    });
  };

  return {
    print: print,
    printFromScope: printFromScope
  };
});

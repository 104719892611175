'use strict';

angular.module('uvsApp')
  .controller('CustomerDocumentCtrl',
    function($scope, $uibModalInstance, Customer, templates) {
  var vm = this;

  vm.isLoading = false;
  vm.templates = templates;

  vm.createDocument = function(documentForm) {
    $scope.$broadcast('show-errors-check-validity');

    if (!documentForm.$valid) {
      return;
    }
    vm.isLoading = true;

    Customer.createDocument({id: $scope.customerId, tid: vm.templateId}, {})
      .$promise
      .then(function(response) {
        $uibModalInstance.close(response.documentCreated);
      })
      .finally(function() {
        vm.isLoading = false;
      });
  };
});

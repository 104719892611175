'use strict';

angular
  .module('uvsApp')
  .controller(
    'TaskFormModalCtrl',
    function (
      $scope,
      order,
      task,
      _,
      $uibModalInstance,
      Order,
      TaskRepository,
      usersStore,
      departmentsStore
    ) {
      var vm = this;
      var assigneeIsKnownAtStart;

      vm.orders = [];
      vm.workers = usersStore.workers;

      vm.task = TaskRepository.getDefaultTask(task);

      assigneeIsKnownAtStart = !!(vm.task.assignee && vm.task.assignee.hasOwnProperty('_id'));

      // TODO: filter and show only worker departments
      vm.departments = departmentsStore.activeDepartments;

      vm.task.dep = getTaskDepartment(vm.task, vm.departments);
      vm.task.assignee = getTaskAssignee(vm.task, vm.workers);

      vm.task.start = {};
      vm.task.end = {};

      if (vm.task.startDate) {
        vm.task.start = moment(vm.task.startDate);
        vm.task.start = {
          date: vm.task.start.toDate(),
          time: vm.task.start.format('HH:mm'),
        };
      }

      if (vm.task.endDate) {
        vm.task.end = moment(vm.task.endDate);
        vm.task.end = {
          date: vm.task.end.toDate(),
          time: vm.task.end.format('HH:mm'),
        };
      }

      if (order) {
        vm.task.order = {
          _id: order._id,
          number: order.number,
          name: order.name,
          label: order.number + ' ' + order.name.toLowerCase(),
        };
      }

      vm.datepickerOptions = {
        startingDay: 1,
      };

      vm.onStartDateChange = function () {
        if (vm.task.start.date > vm.task.end.date) {
          vm.task.end.date = vm.task.start.date;
        }
      };

      vm.onEndDateChange = function () {
        if (vm.task.end.date < vm.task.start.date) {
          vm.task.start.date = vm.task.end.date;
        }
      };

      vm.onWorkerChange = function () {
        vm.task.assignee[0] = {
          _id: vm.task.worker._id,
          name: vm.task.worker.name,
        };
      };

      vm.onDepChange = function () {
        if (assigneeIsKnownAtStart) {
          return;
        }

        vm.task.assignee = getTaskAssignee(vm.task, vm.workers, true);
      };

      vm.isEditMode = angular.isDefined(vm.task._id);

      vm.submit = function submit(valid) {
        var startTime;
        var endTime;

        $scope.$broadcast('show-errors-check-validity');

        if (!valid) {
          return;
        }

        if (vm.task.start.hasOwnProperty('date')) {
          startTime = vm.task.start.time.split(':');
          vm.task.startDate = moment(vm.task.start.date)
            .hours(0)
            .hours(startTime[0])
            .minutes(0)
            .minutes(startTime[1])
            .toDate();
        } else {
          vm.task.startDate = null;
        }

        if (vm.task.end.hasOwnProperty('date')) {
          endTime = vm.task.end.time.split(':');

          vm.task.endDate = moment(vm.task.end.date)
            .hours(0)
            .hours(endTime[0])
            .minutes(0)
            .minutes(endTime[1])
            .toDate();
        } else {
          vm.task.endDate = null;
        }

        $uibModalInstance.close(vm.task);
      };

      vm.getOrders = function (val) {
        var searchingOrders = Order.search({ q: val, type: 'active', simple: 'true' }).$promise;

        return searchingOrders.then(function (response) {
          vm.orders = response;
        });
      };

      vm.delete = function deleteTask(tid) {
        if (!tid) {
          return;
        }

        TaskRepository.destroyTask(tid, vm.task.order._id).then(function () {
          $uibModalInstance.close();
        });
      };

      vm.clearFromEmployment = function () {
        vm.task.startDate = undefined;
        vm.task.start = {};

        vm.task.endDate = undefined;
        vm.task.end = {};
      };

      function getTaskAssignee(task, workers, forceDepUser) {
        var taskAssignee = task.assignee;
        var depAssignee = _.find(workers, { _id: task.dep.defaultUser });

        // if not a full user object
        if (taskAssignee && taskAssignee.hasOwnProperty('_id')) {
          taskAssignee = _.find(workers, { _id: task.assignee._id });
        }

        if (!taskAssignee || (depAssignee && forceDepUser)) {
          taskAssignee = depAssignee;
        }

        return taskAssignee;
      }

      function getTaskDepartment(task, departments) {
        var department;

        if (task.dep) {
          department = _.find(departments, { _id: task.dep });
        }

        if (!department && task.assignee && task.assignee.hasOwnProperty('_id')) {
          department = _.find(departments, { defaultUser: task.assignee._id });
        }

        if (!department) {
          department = departments[0];
        }

        return department;
      }
    }
  );

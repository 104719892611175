angular
  .module('components.settings', [
    'ui.router',
    'app.operations',
    'components.pipelines'
  ])
  .config(function($stateProvider) {
    $stateProvider.state('root.settings', {
      abstract: true,
      url: '/settings',
      views: {
        "content": {
          templateUrl: 'layouts/with-left-sidebar.html'
        },
        'sidebar@root.settings': {
          templateUrl: 'components/sidebar/sidebar-settings.html'
        }
      }
    });
  });

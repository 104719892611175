'use strict';

class EventsService {
  constructor(auditService) {
    'ngInject';

    this.auditService = auditService;
  }

  getEventsByTargetId(tid, from, limit) {
    return this.auditService.getAuditlogs({tid, from, limit});
  }
}

angular
  .module('components.activity')
  .service('eventsService', EventsService);

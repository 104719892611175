'use strict';

angular.module('uvsApp')
  .directive('proposalStatus', function() {
    return {
      templateUrl: 'app/directives/proposal-status/proposal-status.html',
      restrict: 'E',
      controller: 'ProposalStatusController',
      controllerAs: 'ctrl',
      bindToController: {
        status: '='
      }
    };
  })
  .controller('ProposalStatusController', function(ProposalStatuses) {
    var vm = this;

    vm.$onInit = function() {
      vm.css = 'progress-bar-default';
      vm.name = 'N/A';

      if (ProposalStatuses.hasOwnProperty(vm.status)) {
        vm.name = ProposalStatuses[vm.status].name;
        vm.css = 'progress-bar-' + ProposalStatuses[vm.status].css;
      }
    };
  });

class EventsController {
  constructor($timeout, _, eventsStore) {
    'ngInject';

    this.$timeout = $timeout;
    this._ = _;
    this.store = eventsStore;
  }

  $onInit() {
    this.historyOnly = false;
    this.type = 'TASK';
    this.store.target = Object.assign({}, this.target);
    this.store.limit = this.limit;

    this.dispose = mobx.autorun(() => {
      this.events = this.store.events;
      this.tasks = this.store.tasks;
      this.notes = this.store.notes;
      this.history = this.store.history;
      this.hasMore = this.store.hasMore;
    });
  }

  $onDestroy() {
    this.dispose();
    this.store.clearEvents();
  }

  $onChanges(changes) {
    if (changes.hasOwnProperty('lastUpdate')) {
      const from = changes.lastUpdate.isFirstChange() ? null : changes.lastUpdate.previousValue;

      // Wait a little bit for new events to be saved
      this.$timeout(() => {
        this.store.fetchEvents(from);
      }, 500);
    }

    if (changes.historyOnly) {
      this.historyOnly = this.historyOnly === 'true';
    }
  }

  loadMore() {
    const lastLog = this._.last(this.store.events);

    if (!lastLog) {
      return;
    }

    // From last log sending negative timestamp
    this.store.fetchEvents(-new Date(lastLog.createdAt).getTime());
  }
}

angular.module('components.activity').controller('EventsController', EventsController);

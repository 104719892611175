'use strict';

angular.module('uvsApp').directive('hasOwnerPermission', function(Auth) {
  return {
    link: function(scope, element, attrs) {
      if (!_.isString(attrs.hasOwnerPermission)) {
        throw 'hasOwnerPermission value must be a string';
      }

      var permission = attrs.hasOwnerPermission.trim();
      var owner = attrs.owner || Auth.getCurrentUser()._id;
      var isOwner = owner.trim() === Auth.getCurrentUser()._id;

      function toggleVisibilityBasedOnPermission() {
        var hasPermission = Auth.hasPermission(permission + 'All');

        if (hasPermission) {
          element.show();
          return;
        }

        hasPermission = Auth.hasPermission(permission + 'Own');

        if (hasPermission && isOwner) {
          element.show();
        } else {
          element.hide();
        }
      }

      toggleVisibilityBasedOnPermission();
      scope.$on('permissionsChanged', toggleVisibilityBasedOnPermission);
    }
  };
});

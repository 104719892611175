class ProductRecipeController {
  constructor(_) {
    'ngInject';

    this._ = _;
  }

  $onChanges(changes) {
    if (changes.recipe) {
      this.recipe = Object.assign({}, this.recipe);
      // this.addNewIngredient();
    }
  }

  addNewIngredient() {
    this.recipe.ingredients.push({
      name: '',
      id: new Date().getTime()
    });
  }

  onIngredientChange({ product }) {
    const index = this.recipe.ingredients.findIndex(prod => prod.id === product.id);

    if (index !== -1) {
      this.recipe.ingredients[index] = Object.assign({}, this.recipe.ingredients[index], product);
    }

    this.onChange({
      $event: {
        recipe: this.recipe
      }
    });
  }

  removeIngredient({ product }) {
    const index = this.recipe.ingredients.findIndex(prod => prod.id === product.id);

    this.recipe.ingredients.splice(index, 1);

    this.onChange({
      $event: {
        recipe: this.recipe
      }
    });
  }
}

angular.module('app.products').controller('ProductRecipeController', ProductRecipeController);

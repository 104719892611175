class SalesSidebarController {
  constructor(pipelinesStore) {
    'ngInject';

    this.store = pipelinesStore;

    this.activeStages = [];
    this.archivedStages = [];
  }

  $onInit() {
    this.dispose = mobx.autorun(() => {
      this.activeStages = this.store.activeStages;
      this.archivedStages = this.store.archivedStages;
    });
  }

  $onDestroy() {
    this.dispose();
  }
}

angular
  .module('components.sidebar.sales')
  .controller('SalesSidebarController', SalesSidebarController);

class EventsStore {
  constructor($log, eventsService) {
    'ngInject';

    this.$log = $log;
    this.eventsService = eventsService;

    this.events = []; // @observable
    this.type = 'TASK'; // @observable
    this.hasMore = false;
    this.target = null;
    this.limit = 100;
  }

  fetchEvents(from) {
    return this.eventsService
      .getEventsByTargetId(this.target._id, from, this.limit)
      .then(data => this.setEvents(data))
      .catch(err => this.$log.error(err));
  }

  setEvents({ meta: { total = 0, loadingMore = false } = {}, logs: events = [] }) {
    this.events = this._getCombinedEvents(events, loadingMore);
    this.setHasMore(total);
  }

  clearEvents() {
    this.events.clear();
  }

  // @computed
  get filteredEvents() {
    if (!this.type) {
      return this.events;
    }
    return this.events.filter(event => {
      return event.source && event.source.type === this.type;
    });
  }

  get tasks() {
    return this.events.filter(event => {
      return event.source && event.source.type === 'TASK';
    });
  }

  get history() {
    return this.events.filter(event => {
      return !event.source;
    });
  }

  get notes() {
    return this.events.filter(event => {
      return event.source && event.source.type === 'NOTE';
    });
  }

  setType(type) {
    this.type = type;
  }

  setHasMore(total) {
    this.hasMore = total > this.limit;
  }

  /**
   * If user is loading more events, we prepend current events.
   * If we are loading newst events, we append current events.
   *
   * @param events Array of events coming from back-end
   * @param loadingMore Is user loadingMore or we are loading newest events
   */
  _getCombinedEvents(events = [], loadingMore) {
    let bucket = [];

    if (loadingMore) {
      bucket = [...this.events, ...events];
    } else {
      bucket = [...events, ...this.events];
    }

    return bucket;
  }
}

mobx.decorate(EventsStore, {
  events: mobx.observable,
  filter: mobx.observable,
  target: mobx.observable,
  limit: mobx.observable,
  hasMore: mobx.observable,

  filteredEvents: mobx.computed,
  tasks: mobx.computed,
  notes: mobx.computed,

  setEvents: mobx.action,
  setType: mobx.action,
  setHasMore: mobx.action,
  clearEvents: mobx.action
});

angular.module('components.activity').service('eventsStore', EventsStore);

class EstimateController {
  constructor($state, estimatesService, toastr, uuid, dealsStore) {
    'nInject';

    this.$state = $state;
    this.estimatesService = estimatesService;
    this.toastr = toastr;
    this.uuid = uuid;
    this.dealsStore = dealsStore;

    this.defaultEstimate = {
      items: [],
      markup: 0,
      discount: 0
    };

    this.isDisabled = false;
  }

  $onInit() {
    this.isNew = !this.estimate;

    this.estimate = Object.assign(
      {},
      this.defaultEstimate,
      {
        deal: this.deal ? this.deal._id : null
      },
      this._normalizeProducts(this.estimate)
    );

    this._setLastUpdated();
    this.dealsStore.fetchDeal(this.$transition$.params().did);

    this.dispose = mobx.autorun(() => {
      this.isLoading = this.dealsStore.isLoading;
      this.deal = this.dealsStore.deal;
      this.isDisabled = this.dealsStore.invoices && this.dealsStore.invoices.length > 0;
    });
  }

  $onDestroy() {
    this.dispose();
  }

  onProductChange({ product }) {
    const index = this._getIndexByProductId(product.pid);

    if (index == -1) {
      this.estimate.items.push(product);
    } else {
      this.estimate.items[index] = product;
    }

    this._setLastUpdated();
  }

  onProductRemove(product) {
    const index = this._getIndexByProductId(product.pid);
    this.estimate.items.splice(index, 1);
  }

  onChange(event) {
    Object.assign(this.estimate, event);
  }

  onSave() {
    this.estimatesService
      .save(Object.assign({}, this.estimate, { deal: this.deal._id }))
      .then(savedEstimate => {
        // redirect if new estimate was created
        if (!this.estimate._id) {
          this.$state.transitionTo('root.estimates.details', {
            id: savedEstimate._id,
            did: savedEstimate.deal
          });
        }
        this.toastr.success('Išsaugota sėkmingai.');
      })
      .catch(() => {
        this.toastr.error('Išsaugoti nepavyko. Bandykite dar kartą.', 'Klaida!');
      });
  }

  _setLastUpdated() {
    this.lastUpdated = new Date().getTime();
  }

  _getIndexByProductId(pid) {
    return this.estimate.items.findIndex(prod => prod.pid === pid);
  }

  _normalizeProducts(estimate = {}) {
    if (!estimate.items) {
      return estimate;
    }

    return Object.assign({}, estimate, {
      items: estimate.items.map(p => {
        p.pid = this.uuid.v4();
        return p;
      })
    });
  }
}

angular.module('app.estimates').controller('EstimateController', EstimateController);

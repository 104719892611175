'use strict';

angular.module('app.customers').factory('Customer', function($resource, API_URL) {
  var url = API_URL + '/customers';
  return $resource(
    url + '/:id',
    { id: '@_id' },
    {
      update: {
        method: 'PUT'
      },
      getInvoices: {
        method: 'GET',
        url: url + '/:id/invoices',
        isArray: true
      },
      // Deprecated; do not use
      getDocuments: {
        method: 'GET',
        url: url + '/:id/documents',
        isArray: true
      },
      createDocument: {
        method: 'GET',
        url: url + '/:id/documents/create?tid=:tid'
      },
      deleteDocument: {
        method: 'DELETE',
        url: url + '/:id/documents/:name'
      }
    }
  );
});

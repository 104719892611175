'use strict';

const dealEdit = {
  templateUrl: 'app/deals/deal-edit/deal-edit.html',
  controller: 'DealEditController',
  bindings: {
    deal: '<',
    pipelines: '<'
  }
};

angular
  .module('app.deals')
  .component('dealEdit', dealEdit)
  .config($stateProvider => {
    $stateProvider.state('root.deals.edit', {
      url: '/:id/edit',
      component: 'dealEdit',
      resolve: {
        deal: ($transition$, dealsStore) => {
          return dealsStore.loadDealById($transition$.params().id);
        }
      }
    });
  });

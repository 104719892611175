class TasksStore {
  constructor(tasksService, $log) {
    'ngInject';

    this.tasksService = tasksService;
    this.$log = $log;

    this.tasks = []; // @observable
  }

  // @action
  fetchTasks() {
    return this.tasksService
      .getTasks()
      .then((tasks) => this.setTasks(tasks))
      .catch((error) => this.$log.error(error));
  }

  setTasks(data) {
    this.tasks = data;
  }

  deleteTask(id) {
    const taskIndex = this.tasks.findIndex(({tid}) => tid === id);

    if (taskIndex === -1) {
      this.$log.error(`Trying to delete non existing task. Task ID: ${id}`);
      return;
    }

    return this.tasksService
      .deleteTask(id)
      .then(() => {
        const tasks = [...this.tasks];
        tasks.splice(taskIndex, 1);
        this.setTasks(tasks);
      })
      .catch((error) => this.$log.error(error));
  }
}

mobx.decorate(TasksStore, {
  users: mobx.observable,

  setTasks: mobx.action
});

angular.module('app.tasks').service('tasksStore', TasksStore);

class DepartmentListController {
  constructor(_, Task, departmentsStore) {
    'ngInject';

    this.clsStatus = {
      0: 'info',
      1: 'warning',
      2: 'success',
      3: 'danger'
    };
    this._ = _;
    this.taskService = Task;
    this.departmentsStore = departmentsStore;
  }

  $onInit() {
    this.deps = this.deps || [];
    this.orderStatus = {};
    this.tasks = this.order.tasks;

    this.dispose = mobx.autorun(() => {
      this.deps = this.departmentsStore.activeDepartments;
      this.departments = this.getDepartmentsMap();
      this.progressWidth = 100 / this.deps.length + '%';
    });
  }

  $onDestroy() {
    this.dispose();
  }

  progressClass(did) {
    let cls = 'default';

    const depTasks = this._.filter(this.tasks, {dep: did});

    if (depTasks.length) {
      var tasksStatus = this._(depTasks)
        .map((dt) => dt.status)
        .uniq()
        .valueOf();
      var status = 1;

      if (tasksStatus.length === 1) {
        status = tasksStatus[0];
      } else {
        if (this._.includes(tasksStatus, 3)) {
          status = 3;
        }
      }
    }

    if (this.clsStatus.hasOwnProperty(status)) {
      cls = this.clsStatus[status];
    }

    return 'progress-bar-' + cls;
  }

  // progressClass(status) {
  //   let cls = 'default';

  //   if (this.clsStatus.hasOwnProperty(status)) {
  //     cls = this.clsStatus[status];
  //   }

  //   return 'progress-bar-' + cls;
  // };

  getDepartmentsMap() {
    return this._.map(angular.copy(this.deps), (dep) => {
      let status = -1;

      if (this.orderStatus && this.orderStatus.hasOwnProperty(dep._id)) {
        status = this.orderStatus[dep._id];
      }

      return {
        dep: dep._id,
        name: dep.name,
        status: status
      };
    });
  }

  getTaskStatuses(tasks) {
    const taskByDepartment = this._.groupBy(tasks, 'dep');

    return this._.mapValues(taskByDepartment, (depTasks) => {
      const tasksStatus = this._(depTasks)
        .map((dt) => dt.status)
        .uniq()
        .valueOf();
      let status = 1;

      if (tasksStatus.length === 1) {
        status = tasksStatus[0];
      } else {
        if (this._.includes(tasksStatus, 3)) {
          status = 3;
        }
      }

      return status;
    });
  }
}

angular
  .module('uvsApp')
  .controller('DepartmentListController', DepartmentListController);

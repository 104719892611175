const departmentInfoComponent = {
  templateUrl: 'app/orders/directives/departmentInfo/department-info.html',
  bindings: {
    order: '<',
    department: '<',
    onStatusChange: '&'
  },
  controller: 'DepartmentInfoController'
};

angular.module('uvsApp').component('departmentInfo', departmentInfoComponent);

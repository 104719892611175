class EngagementsStore {
  constructor(_, engagementsService) {
    'ngInject';

    this._ = _;
    this.engagementsService = engagementsService;

    this.engagements = [];
    this.targetId = undefined;

    this.getByAssignee = mobxUtils.createTransformer(uid =>
      this.engagements.filter(
        engagement => engagement.metadata && engagement.metadata.assigneeId === uid
      )
    );
  }

  fetchEngagements() {
    return this.engagementsService
      .getEngagements()
      .then(engagements => this.setEngagements(engagements))
      .catch(err => console.error(err));
  }

  setEngagements(data) {
    this.engagements = data;
  }

  setTarget(id) {
    this.targetId = id;
  }

  get tasks() {
    return this.engagements.filter(
      eng =>
        eng.type === 'TASK' &&
        this._.some(eng.associations, {
          item: this.targetId
        })
    );
  }

  addComment(engagementId, comment) {
    return this.engagementsService
      .createComment(engagementId, comment)
      .then(savedComment => this.setComment(engagementId, savedComment));
  }

  setComment(engagementId, comment) {
    const engagement = this.engagements.find(e => e._id === engagementId);
    engagement.comments = [...engagement.comments, comment];
  }
}

mobx.decorate(EngagementsStore, {
  engagements: mobx.observable,
  target: mobx.observable,

  setEngagements: mobx.action,
  setTarget: mobx.action,
  setComment: mobx.action
});

angular.module('app.engagements').service('engagementsStore', EngagementsStore);

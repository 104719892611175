const inventoryItemSelectorComponent = {
  templateUrl:
    'app/inventory/inventory-item-selector/inventory-item-selector.html',
  controller: 'InventoryItemSelectorController',
  bindings: {
    item: '<',
    onSelect: '&'
  }
};

angular
  .module('app.inventoryItemSelector')
  .component('inventoryItemSelector', inventoryItemSelectorComponent);

'use strict';

angular.module('uvsApp').directive('uniqueInvoice', function(_) {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      invoices: '=uniqueInvoice',
      uniqueInvoiceId: '=?'
    },
    link: function($scope, $element, $attrs, ngModel) {
      ngModel.$validators.uniqueInvoice = function(value) {
        var isValid = !_.find($scope.invoices, function(invoice) {
          return (
            invoice.number === value && invoice._id !== $scope.uniqueInvoiceId
          );
        });
        return isValid;
      };
    }
  };
});

'use strict';

angular.module('uvsApp').factory('employmentHelpers', function(moment) {
  var factory = {};

  factory.taskToEmployment = function(task) {
    var employment = {
      id: task._id,
      taskName: task.name,
      dep: task.dep,
      sectionID: task.assignee && task.assignee._id ? task.assignee._id : null,
      start: moment(task.startDate),
      end: moment(task.endDate),
      name: '<div>' + task.order.name + '</div><div>' + task.name + '</div>',
      orderId: undefined,
      order: undefined,
      summary: task.summary
    };

    return employment;
  };

  factory.employmentToTask = function(employment) {
    var task = {
      _id: employment.id,
      name: employment.taskName,
      dep: employment.dep,
      assignee: employment.sectionID,
      startDate: employment.start.toDate(),
      endDate: employment.end.toDate(),
      summary: employment.summary
    };

    return task;
  };

  return factory;
});
